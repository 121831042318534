/*
 * @Author: liyxt
 * @Date: 2020-04-07 11:41:26
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-01 14:35:20
 * @Description: file content
 */
import { getGlobalStorage } from './storage';

const entries = ['api', 'base', 'form', 'search', 'table-core', 'card-table', 'edit-table', 'simple-table', 'transfer-table', 'tree-table', 'components']

function getTheme() {
    return new Promise(function (resolve) {
        // ... some code
        let themeType = (window && window.nccColor) || (window && window.top && window.top.nccColor) || getGlobalStorage('localStorage', 'nccColor');
        if (themeType) {
            resolve(themeType);
        } else {
            console.log('未能获取到数据 暂时没有加ajax操作！');
            resolve('');
            // reject(new Error('error'));
        }
    });
}

let DEFAULT_TYPES = ['default', 'red', 'green', 'black', 'blue', 'yellow'];

/**
 * 根据fileName获取匹配CSS文件地址列表
 * @param {Array} cssList CSS文件列表
 * @param {String} name 文件名
 */
function getFileList(cssList, name) {
    let matchFileList = [];
    for (let i = 0; i < cssList.length; i++) {
        let url = cssList[i];
        let fileName = url.substring(url.lastIndexOf('/') + 1);
        // 可匹配[fileName].css 
        // [fileName]-[themeType].css 
        // [fileName]-[themeType].[hash]css 等
        let reg = new RegExp(name + '(\\-\\w+)*(\\.\\w+)*\\.css$');
        if (reg.test(fileName)) {
            matchFileList.push(url);
        }
    }
    return matchFileList;
}

/**
 * 
 * @param {*} baseName 
 */
function getBodyNoThemeClass(baseName) {
    if (document && document.body) {
        document.body.className += ' nc-no-theme';
        let reg = new RegExp('' + `${baseName}-(\\w+)*`, 'g'),
            str = String(document.body.className);
        str = str.replace(reg, '');
        document.body.className = str.replace('beautiful-g-transforms', '');
    }
}

/**
 * 设置主题文件
 * @param {String} themeType 主题类型
 */
function setTheme({ themeType = '', themeTypes, baseName = 'nc-lightapp-front', baseUrl = '/' }) {
    themeTypes = themeTypes || (window ? window.themeTypes : []);
    // 清理和去重
    themeTypes = Array.from(new Set(DEFAULT_TYPES.concat(themeTypes)));
    // 处理下 避免失败的加载错误出现
    if (themeType === '""' || themeTypes.indexOf(themeType) === -1 || !themeType) {
        getBodyNoThemeClass(baseName);
        return null;
    }
    // 直接写入 themeType  会导致 默认覆盖的 的.green样式触发
    document.body.className = document.body.className + ` ${baseName}-${themeType} beautiful-g-transforms`;
    if (themeType === 'default') {
        document.body.className = String(document.body.className).replace('beautiful-g-transforms', ''); // 为了保证样式清除下
    }
    // 获取head中的外链样式表组
    let domHead = document.getElementsByTagName('HEAD').item(0),
        styleList = domHead.querySelectorAll('link[rel="stylesheet"]'),
        urlList = [],
        themeList = [],
        matchFileURL;

    for (let i = 0; i < styleList.length; i++) {
        urlList.push(styleList[i].getAttribute('href'));
    }

    // 获取已加载的主题文件列表
    themeList = getFileList(urlList, baseName);
    let reg = new RegExp(baseName + '(\\-\\w+)*(\\.\\w+)*\\.css$');
    baseUrl = themeList[0] ? themeList[0].replace(reg, '') : baseUrl;

    // 如果head中的样式表中没有对应的文件则向head中写入file
    if (themeTypes.length > 0) {
        // 删除其他主题文件
        themeList.forEach(el => {
            let arr = reg.exec(el);
            let currentTheme = arr && arr[1] && arr[1].replace('-', '');
            if (currentTheme && el.indexOf(`${baseName}-${currentTheme}`) >= 0 && themeTypes.indexOf(currentTheme) >= 0) {
                let item = document.querySelectorAll(`link[href='${el}']`)[0];
                item && item.parentNode.removeChild(item);
            }
        });
        // let matchFileURL = getFileList(themeTypes, `${baseName}-${themeType}.css`);
        matchFileURL = themeTypes.find(item => {
            return item == themeType;
        }) || themeType;

        if (!matchFileURL) {
            getBodyNoThemeClass(baseName);
            console.warn('主题文件的文件名格式错误，出现多个或没有样式文件！', matchFileURL, themeTypes, themeType);
            return;
        }
        // 插入新style
        let style = document.createElement('link');
        // 这里暂时没有考虑hash的情况 TODO
        style.href = `${baseUrl}${baseName}-${matchFileURL}.css`;
        style.rel = 'stylesheet';
        // style.type = 'text/css';
        style.onerror = function () {
            getBodyNoThemeClass(baseName);
            console.log('未能加载到样式', `${baseUrl}${baseName}-${matchFileURL}.css`);

        };

        domHead.appendChild(style);

        entries.forEach(item => {
            let style = document.createElement('link');
            // 这里暂时没有考虑hash的情况 TODO
            style.href = `${baseUrl}${item}/index-${matchFileURL}.css`;
            style.rel = 'stylesheet';
            // style.type = 'text/css';
            style.onerror = function () {
                // getBodyNoThemeClass(baseName);
                console.log('未能加载到样式', `${baseUrl}${item}/index-${matchFileURL}.css`);

            };
            domHead.appendChild(style);
        })

    }
}
/**
 *给body添加字体样式
 */
function addFontClass() {
    let fontClass = (window && window.nccFontSize) || (window && window.top && window.top.nccFontSize) || getGlobalStorage('localStorage', 'nccFontSize') || '';
    if (fontClass) {
        let body = document.body;
        //先全部移除
        body.classList.remove('bodyLarge', 'bodyMedium');
        switch (fontClass) {
            // case 'small':
            //     // body.classList.add("bodySmall");
            //     break;
            case 'large':
                body.classList.add("bodyLarge");
                break;
            case 'medium':
                body.classList.add("bodyMedium");
                break;
            default:
                break;
        }
    }
}

window && (window.addFontClass = addFontClass)
window && (window.getTheme = getTheme);
window && (window.setTheme = setTheme);

export { setTheme, getTheme, addFontClass };