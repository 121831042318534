/*
 * @Author: liyxt
 * @Date: 2019-07-24 10:47:01
 * @LastEditors  : liyxt
 * @LastEditTime : 2020-03-06 13:43:58
 * @Description: file content
 */
/* eslint-disable implicit-arrow-linebreak */
import { combineReducers } from 'redux';
import { Map } from 'immutable';
import { set, isObject, isFunction } from '../utils';

const SEP = '.';
const actions = {},
	listeners = {};

export function initReducer(store) {
	const reducers = {};
	let actionStack = [];

	// 注册reducer
	function registerNameSpace(reducerName = '', initialValue = Map(), reducerWapper) {
		// 大写字母开头的命名空间不可枚举
		/^[A-Z_]/.test(reducerName) &&
			Object.defineProperty(actions, reducerName, {
				enumerable: false,
				configurable: true,
				value: actions[reducerName],
				writable: true
			});
			
		
		// 生成reducer
		if (!reducers[reducerName]) {
			reducers[reducerName] = function(state = initialValue, action) {
				// reducerName为了确保每个method是注册到单一reducer上的，而不是全局的
				let { type, newState } = action;
				if (type === reducerName) {
					return newState;
				} else {
					// console.error(`this.props.${type} don't match any function`);
					return state;
				}
			};
			if (isFunction(reducerWapper)) {
				reducers[reducerName] = reducerWapper(reducers[reducerName]);
			}
			store.replaceReducer(combineReducers(reducers));
		}

		// 向reducer中注册事件
		const registerAction = methodsMap => {
			Object.entries(methodsMap).forEach(([actionName, method]) => {
				// get方法
				let namespace = getNameSpace(reducerName, actionName);
				// 业务组实际调用的方法
				register(actions, namespace, (...rest) => {
					// 记录最后一次action的调用，subscribe用到
					actionStack.push({
						namespace,
						args: rest
					});
					let state = store.getState()[reducerName],
						result = method(state, ...rest);
					actionStack.pop();
					return result;
				});
			});
		};

		function myDispatch(type, newState) {
			// console.log(lastAction);
			const { dispatch } = store;
			// // 添加监听函数
			// if (lastAction) {
			// 	let { namespace, args } = lastAction;
			// 	if (Array.isArray(listeners[namespace])) {
			// 		newState = listeners[namespace].reduce(function(prevState, listener) {
			// 			return typeof listener === 'function' ? listener(prevState, ...args) : prevState;
			// 		}, newState);
			// 	}
			// }
			dispatch({
				type,
				newState,
				...actionStack.slice(-1)[0]
			});
		}
		//暴露原始dispath
		function realDispath(action) {
			const { dispatch } = store;
			dispatch(action);
		}

		return {
			registerAction,
			dispatch: myDispatch.bind(undefined, reducerName),
			getState: () => store.getState()[reducerName],
			realDispath
		};
	}
	function removeNameSpace(reducerName) {
		[actions, reducers].forEach(scope => {
			delete scope[reducerName];
		});
	}
	return {
		registerNameSpace,
		removeNameSpace
	};
}

export { actions, subscribe };

function register(target, nameSpace, method) {
	set(target, nameSpace, method);
}

function getNameSpace(...args) {
	args = args.filter(e => e);
	let firstArg = args.shift() || '';
	return args.reduce(function(prev, next) {
		if (prev) {
			return prev.concat(SEP).concat(next);
		} else {
			return next;
		}
	}, firstArg);
}

function subscribe(listenersMap) {
	isObject(listenersMap) &&
		Object.entries(listenersMap).forEach(([namespace, listener]) => {
			!Array.isArray(listener) && (listener = [listener]);
			Array.isArray(listeners[namespace])
				? listeners[namespace].push(...listener)
				: (listeners[namespace] = [...listener]);
		});
	return function() {};
}
