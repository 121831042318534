/*
 * @Author: liyxt
 * @Date: 2020-05-29 09:53:24
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-02 14:37:06
 * @Description: file content
 */ 
import { toast } from './toast';

export default function handelExitPage(headers, flag) {
    if (headers) {
        if (headers.contentpath && headers.redirect === 'REDIRECT') {
            //SpecialTip(headers.redirectstatus, exitPage, headers.contentpath);
            if(typeof window.top.SpecialTip === 'function'){
                window.top.SpecialTip(headers.redirectstatus, exitPage, headers.contentpath);
            }
            return false;
        } else if (headers.authorize === 'FALSE') {
            toast({ 
                color: 'danger', 
                content: headers.authorizemsg ? decodeURIComponent(headers.authorizemsg) : '该请求未配置鉴权信息', 
            });
            return false;
        } else {
            return flag;
        }
    }
    /**
         * 退出页面 
         * */
    function exitPage(hrefString) {
        window.top.location.href = hrefString;
    }

}

export { handelExitPage };