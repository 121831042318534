/**
 * form下载
 * 参数：
 * {
 *     params: {             // 操作需要的参数
 *          billId: '',
 *          ...
 *     },
 *     url: '',             // 操作的url
 *     enctype: 1/2/3       // form提交的方式  1和默认、multipart/form-data 2、application/x-www-form-urlencoded 3、text/plain
 * }
 */
import React, {Component} from "react";
import { getAppReqParam } from './appUrlUtils';
import { getGlobalStorage } from './storage';
import Cipher, { opaqueDecrypt } from './cipher';
import NCProgressBar from "../base/ProgressBar";
import Gzip from './gzip';
import ajax from "./ajax";
import { toast, clearToast } from "./toast";


export function getCipherData(data) {
    //压缩开关
    let gzip = getGlobalStorage('localStorage', 'gzip');
    let gziptools = new Gzip();

    let gData = data;

    //加密开关
    let cipherFlag = getGlobalStorage('localStorage', 'rockin');
    Cipher.CipherFlag = cipherFlag === 'true';

    //加密密钥
    let aeskey = opaqueDecrypt(getGlobalStorage('localStorage', 'cowboy'));

    if (gzip == '1') {
        if (typeof gData !== 'string') {
            gData = JSON.stringify(gData)
        }
        gData = gziptools.zip(gData);
    }
    return Cipher.encrypt(gData, aeskey);
}

export function formDownload({
    params,
    url,
    enctype,
    target,
    isCipher = false,
}) {
    // 从ajax哪里取得，目的获取busiaction
    let { app, appcode, custom } = getAppReqParam();

    let busiaction = `${app || null}-${window.actionName || null}`,
        sysTs = new Date().getTime();
    const sysParamJson = {
        sys_busiaction: busiaction,
        sys_appcode: appcode,
        sys_ts: sysTs,
        sys_custom: custom,
        // busiaction: busiaction ,
        // appcode: appcode,
        // ts: new Date().getTime()
    }
    enctype = (type => {
        switch (type) {
            case 1:
                return 'multipart/form-data';
            case 2:
                return 'application/x-www-form-urlencoded';
            case 3:
                return 'text/plain';
            default:
                return 'multipart/form-data';
        }
    })(enctype)

    if(params.outputSetting) {
        params.outputSetting = JSON.stringify(Object.assign({}, JSON.parse(params.outputSetting), {taskID: sysTs}));
    }
    const config = { ...params, ...sysParamJson };
    let isOutput = config.output || false,
        queryUrl = config.queryUrl || "";

    const [attrs, el_form] = [
        {
            target: target ? target : isOutput ? "_self" : '_blank',
            method: 'POST',
            enctype,
            type: 'hidden',
            action: url || '',
        },
        document.createElement('form'),
    ];
    Object.assign(el_form, attrs);

    for (let key in config) {
        if (config[key] instanceof Array) {
            config[key].forEach((item, index) => {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                // 不包含业务参数
                input.value = isCipher && typeof sysParamJson[key] === 'undefined' ? getCipherData(item) : item;
                //el_form.append(input);
                el_form.appendChild(input);
            })
        } else {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            // 不包含业务参数
            input.value = isCipher && typeof sysParamJson[key] === 'undefined' ? getCipherData(config[key]) : config[key];
            //el_form.append(input);
            el_form.appendChild(input);
        }
    }
    document.body.appendChild(el_form);
    el_form.submit();
    document.body.removeChild(el_form);
    isOutput && params.outputSetting && toast({title: "输出进度",content: <OutProcess sysTs={sysTs} queryUrl={queryUrl} mark={sysTs}/>, mark: sysTs, duration: 999999, className: "export-procress-toast"})
    return false;
}

function checkType(param, type) {
    return Object.prototype.toString.call(param).slice(8, -1).toLowerCase() === type.toLowerCase();
  }

class OutProcess extends Component{
    constructor(props) {
    super(props);
      this.state = {
        outProNum: 0
      }
  }
  
    componentDidMount() {
      this.begProcess(this.props.sysTs, this.props.queryUrl, this.props.mark);
    }
  
    begProcess = (sysTs, queryUrl, mark) => {
        ajax({
            url: this.props.queryUrl || "/nccloud/riart/out/outtask.do",
            data: {
                taskID: sysTs
            },
            loading: false,
            success: (res) => {
                let {success, data} = res;
                if(!success || !data) return ;
                if(checkType(data.rate, "Null")) {
                    data.rate = 0;
                }
                data.rate < 100 && this.begProcess(sysTs, queryUrl, mark);
                data.rate == 100 && setTimeout( () => {clearToast(mark)}, 500);
                this.setState({outProNum: data.rate});
            },
            error: (res) => {
              clearToast(mark);
              toast({color: "error", content: res.message});
              console.log(res.message);
            }
        })
    }
  
    render() {
        const {outProNum} = this.state;
        return <NCProgressBar size="xs" now ={outProNum} label={`${outProNum}%`}/>
    }
}
