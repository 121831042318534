import React, { Component } from 'react';
import { loadJS } from './loadJS';

let config = {};

function setConfig(nextConfig) {
	config = {
		...config,
		...nextConfig
	};
}

export const useJS = function(useList, func) {
	return class Wrapper extends Component {
		constructor(props) {
			super(props);
			this.state = {
				loaded: false,
				args: []
			};
		}

		componentDidMount() {
			if (Array.isArray(useList)) {
				Promise.all(
					useList.map(dep => {
						let path, component;
						if (config[dep]) {
							path = config[dep];
							component = dep;
						} else if (/\.js$/.test(dep)) {
							path = dep;
							component = dep.substr(0, dep.length - 3);
						} else {
							path = dep + '.js';
							component = dep;
						}
						return new Promise(resolve => {
							loadJS(
								path,
								() => {
									let res = null;
									try {
										res = window[component].default;
									} catch (e) {}
									resolve(res);
								},
								() => {
									resolve(null);
								}
							);
						});
					})
				).then(args => {
					this.setState({
						loaded: true,
						App: func(...args)
					});
				});
			} else {
				this.setState({
					loaded: true,
					App: func()
				});
			}
		}

		render() {
			let { loaded, App } = this.state;
			if (loaded) {
				return <App {...this.props} />;
			} else {
				return null;
			}
		}
	};
};

useJS.setConfig = setConfig;
