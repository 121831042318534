/*
 * @Author: liyxt
 * @Date: 2020-05-19 15:47:39
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-15 16:29:41
 * @Description: file content
 */ 

import { getLangCode } from './currentLocale';
import { setGlobalStorage, getGlobalStorage, removeGlobalStorage } from './storage';
import Gzip from './gzip';

export function setMetaCache(appcode, pagecode, meta) {
    let key = getMetaKey(appcode, pagecode);
    let storeCipher = localStorage.getItem('storeCipher') != '0';
    if(storeCipher){
        let gzip = new Gzip();
        meta = meta && gzip.zip(meta);
    }
    
    setGlobalStorage('localStorage', key, meta);
}

export function getMetaCache(appcode, pagecode) {
    let key = getMetaKey(appcode, pagecode);
    let storeCipher = localStorage.getItem('storeCipher') != '0';
    let meta = getGlobalStorage('localStorage', key);
    if(storeCipher){
        let gzip = new Gzip();
        meta = meta && gzip.unzip(meta);
    }
    meta = typeof meta === 'string' ? JSON.parse(meta) : meta;
    return meta;
}

export function removeMetaCache(appcode, pagecode) {
    let key = getMetaKey(appcode, pagecode);
    removeGlobalStorage('localStorage', key);
}

export function getMetaKey(appcode, pagecode) {
    let userid = '';
    let userData = JSON.parse(getGlobalStorage('localStorage', "STOREFORINFODATA"));
    userid = userData && userData.userId;
    let langCode = getLangCode();
    //let key = 'appTempletStorage_' + appcode + '_' + pagecode;
    let key = 'appTempletStorage_' + appcode + '_' + pagecode + '_' + userid + '_' + langCode;
    return key;
}