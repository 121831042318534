// import { localeLang } from './currentLocale';
import { setGlobalStorage, getGlobalStorage } from './storage';
import ajax from './ajax';
import moment from 'moment';
import { getBusinessInfo } from './getBusinessInfo';
import ViewModel from './viewmodel';

const DATEFORMAT = 'dataformat'; // 数据格式数据的key

//开发环境测试用的默认数据
const LocalDefaultDataFormat = {
    "fm": {
        "nfm": {                           // 数字格式
            "decimalSymbol": ".",          // 小数点分割符
            "negativeFormat": {            // 5种  1:"-n"; 2:"- n"; 3:"(n)"; 4:"n-"; 5:"n -"
                "red": false,
                "code": "-n"
            },
            "useMil": true,                // 是否使用千分位
            "milSymbol": ","               // 千分位符号
        },
        "afm": {                           // 地址格式 P: 邮编, C: 国家, S: 省, T: 城市, D: 区县, R: 地址详址
            "addrUnit1": {                 // 地址格式显示顺序，第一个
                "code": "P",
                "name": "邮编",
                "delimit": " ",            // 分隔符
            },
            "addrUnit2": {                 // 地址格式显示顺序，第二个
                "code": "C",
                "name": "国家",
                "delimit": " ",           // 分隔符
            },
            "addrUnit3": {                 // 地址格式显示顺序，第三个
                "code": "S",
                "name": "省",
                "delimit": " ",           // 分隔符
            },
            "addrUnit4": {                 // 地址格式显示顺序，第四个
                "code": "T",
                "name": "城市",
                "delimit": " ",           // 分隔符
            },
            "addrUnit45": {                // 地址格式显示顺序，第五个
                "code": "D",
                "name": "区县",
                "delimit": " ",           // 分隔符
            },
            "addrUnit5": {                 // 地址格式显示顺序，第六个
                "code": "R",
                "name": "地址详址",
                "delimit": " ",           // 分隔符
            }
        },
        "dfm": "YYYY[-]MM[-]DD[]",
        "tfm": "HH:mm:ss"
    }
};

const NormalDefaultDataFormat = {
    "fm": {
        "nfm": {                           // 数字格式
            "decimalSymbol": ".",          // 小数点分割符
            "negativeFormat": {            // 5种  1:"-n"; 2:"- n"; 3:"(n)"; 4:"n-"; 5:"n -"
                "red": true,
                "code": "-n"
            },
            "useMil": true,                // 是否使用千分位
            "milSymbol": ","               // 千分位符号
        },
        "afm": {                           // 地址格式 P: 邮编, C: 国家, S: 省, T: 城市, D: 区县, R: 地址详址
            "addrUnit1": {                 // 地址格式显示顺序，第一个
                "code": "P",
                "name": "邮编",
                "delimit": " ",            // 分隔符
            },
            "addrUnit2": {                 // 地址格式显示顺序，第二个
                "code": "C",
                "name": "国家",
                "delimit": " ",           // 分隔符
            },
            "addrUnit3": {                 // 地址格式显示顺序，第三个
                "code": "S",
                "name": "省",
                "delimit": " ",           // 分隔符
            },
            "addrUnit4": {                 // 地址格式显示顺序，第四个
                "code": "T",
                "name": "城市",
                "delimit": " ",           // 分隔符
            },
            "addrUnit45": {                // 地址格式显示顺序，第五个
                "code": "D",
                "name": "区县",
                "delimit": " ",           // 分隔符
            },
            "addrUnit5": {                 // 地址格式显示顺序，第六个
                "code": "R",
                "name": "地址详址",
                "delimit": " ",           // 分隔符
            }
        },
        "dfm": "YYYY-MM-DD",
        "tfm": "hh:mm:ss"
    }
};

// 存一个全局的备份，避免表格里频繁调用是出现效率问题
// 这里要注意下，数据格式系统中是可以随时改的，如果改了必须要刷新页面才能生效，如果没有刷新，就需要换一种方式，最好能传递进来
// 可以考虑防止window上让其他地方可以操作
let __dateFormat = null;

/**
 * 请求数据格式数据
 */
function getDataFormat() {
    ajax({
        url: '/nccloud/uapbd/formatdoc/queryFormatdocByUserId.do',
        async: false,//暂时采用同步请求
        success: function (res) {
            let data = res.data.fm;

            // 处理日期格式
            let dataUnitMonthCode, dateUnitDayCode, dateUnitYearCode;
            let { dateUnit1, dateUnit2, dateUnit3, delimit1, delimit2, delimit3, monthType, yearType, dayType } = data.dfm;
            let monthTypeCode = monthType.code;
            let yearTypeCode = yearType.code;
            let dayTypeCode = dayType.code;

            switch (yearTypeCode) {
                case "long year":
                    dateUnitYearCode = 'YYYY';
                    break;
                case "short year":
                    dateUnitYearCode = 'YY';
                    break;
                default:
                    dateUnitYearCode = 'YYYY';
            }
            switch (dayTypeCode) {
                case "long day":
                    dateUnitDayCode = 'DD';
                    break;
                case "short day":
                    dateUnitDayCode = 'D';
                    break;
                default:
                    dateUnitDayCode = 'DD';
            }
            switch (monthTypeCode) {
                case "long month":
                    dataUnitMonthCode = 'MM';
                    break;
                case "short month":
                    dataUnitMonthCode = 'M';
                    break;
                case "long English month":
                    dataUnitMonthCode = 'MMMM';
                    break;
                case "short English month":
                    dataUnitMonthCode = 'MMM';
                    break;
                default:
                    dataUnitMonthCode = 'MM';
            }
            // 找到哪个是月
            switch ("MM") {
                case dateUnit1.code:
                    dateUnit1.code = dataUnitMonthCode;
                    break;
                case dateUnit2.code:
                    dateUnit2.code = dataUnitMonthCode;
                    break;
                case dateUnit3.code:
                    dateUnit3.code = dataUnitMonthCode;
                    break;
                default:
                    break;
            }
            // 找到哪个是日
            switch ("DD") {
                case dateUnit1.code:
                    dateUnit1.code = dateUnitDayCode;
                    break;
                case dateUnit2.code:
                    dateUnit2.code = dateUnitDayCode;
                    break;
                case dateUnit3.code:
                    dateUnit3.code = dateUnitDayCode;
                    break;
                default:
                    break;
                // dateUnit3.code = dateUnitDayCode;
            }
            // 找到哪个是年
            switch ("YY") {
                case dateUnit1.code:
                    dateUnit1.code = dateUnitYearCode;
                    break;
                case dateUnit2.code:
                    dateUnit2.code = dateUnitYearCode;
                    break;
                case dateUnit3.code:
                    dateUnit3.code = dateUnitYearCode;
                    break;
                default:
                    break;
                // dateUnit1.code = dateUnitYearCode;
            }
            //如果不是要求的就设为空
            if (!/[DD|MM|YY]/.test(dateUnit1.code)) {
                dateUnit1.code = '';
                delimit1 = '';
            }
            if (!/[DD|MM|YY]/.test(dateUnit2.code)) {
                dateUnit2.code = '';
                delimit2 = '';
            }
            if (!/[DD|MM|YY]/.test(dateUnit3.code)) {
                dateUnit3.code = '';
                delimit3 = '';
            }
            let formatdate = dateUnit1.code + '[' + delimit1 + ']' + dateUnit2.code + '[' + delimit2 + ']' + dateUnit3.code + '[' + delimit3 + ']';
            // let formatdate = dateUnit1.code + delimit1 + dateUnit2.code + delimit2 + dateUnit3.code + delimit3;

            // 处理时间格式
            let formattimeCode = data.tfm.timeFormat.code;
            let formattime; // 转换大小写，后台给的大小写h的意思与moment API的意思相反
            if (formattimeCode[0] === 'h') {
                formattime = formattimeCode.replace(/"/g, '').replace(/h/g, 'H').replace('t', 'A');
            } else {
                formattime = formattimeCode.replace(/"/g, '').replace(/H/g, 'h').replace('t', 'A');
            }

            let FormatDocVO = {
                "fm": {
                    "nfm": {                           // 数字格式
                        "decimalSymbol": data.nfm.decimalSymbol,          // 小数点分割符
                        "negativeFormat": {            // 5种  1:"-n"; 2:"- n"; 3:"(n)"; 4:"n-"; 5:"n -"
                            "red": data.nfm.negativeFormat.red,
                            "code": data.nfm.negativeFormat.code
                        },
                        "useMil": data.nfm.useMil,                // 是否使用千分位
                        "milSymbol": data.nfm.milSymbol            // 千分位符号
                    },
                    "afm": {                           // 地址格式 P: 邮编, C: 国家, S: 省, T: 城市, D: 区县, R: 地址详址
                        "addrUnit1": {                 // 地址格式显示顺序，第一个
                            "code": data.afm.addrUnit1.code,
                            "delimit": data.afm.delimit1,            // 分隔符
                        },
                        "addrUnit2": {                 // 地址格式显示顺序，第二个
                            "code": data.afm.addrUnit2.code,
                            "delimit": data.afm.delimit2
                        },
                        "addrUnit3": {                 // 地址格式显示顺序，第三个
                            "code": data.afm.addrUnit3.code,
                            "delimit": data.afm.delimit3
                        },
                        "addrUnit4": {                 // 地址格式显示顺序，第四个
                            "code": data.afm.addrUnit4.code,
                            "delimit": data.afm.delimit4
                        },
                        "addrUnit45": {                // 地址格式显示顺序，第五个
                            "code": data.afm.addrUnit45.code,
                            "delimit": data.afm.delimit45
                        },
                        "addrUnit5": {                 // 地址格式显示顺序，第六个
                            "code": data.afm.addrUnit5.code,
                            "delimit": data.afm.delimit5
                        }
                    },
                    "dfm": formatdate, // 日期格式
                    "tfm": formattime // 时间格式
                }
            }

            //将获取到的和处理过的数据存在localstorage中，当localStorage容量满了之后报错
            try {
                setGlobalStorage('localStorage', DATEFORMAT, JSON.stringify(FormatDocVO));
            } catch (e) {
                console.log('浏览器localStorage容量不足');
                //可以定一种localStorage内存的清除策略
            }
            ViewModel.setData(DATEFORMAT, FormatDocVO);
        },
        error: function () {
            console.log('数据格式接口错误，请求失败');//控制台提示接口请求错误
            ViewModel.setData(DATEFORMAT, NormalDefaultDataFormat);//存默认的数据格式
        }
    })
}

/**
 * number控件用
 * 从缓存中获取数据格式数据：日期、时间、地址、数字
 */
export function getDataFormatFromCache() {
    if (__dateFormat) {
        return __dateFormat;
    }
    //先从localStorage中取
    let dataformat = getGlobalStorage('localStorage', DATEFORMAT);
    if (dataformat) {
        __dateFormat = JSON.parse(dataformat);
        return __dateFormat;
    } else {
        //从页面级缓存中拿
        __dateFormat = ViewModel.getData(DATEFORMAT);
        if (__dateFormat) {
            return __dateFormat;
        }
    }

    //都没有的情况
    let userInfo = getBusinessInfo();//判断是否登录，接口只有在登录之后才有权限请求
    if (userInfo && userInfo.userId) {
        getDataFormat(); //请求数据
        __dateFormat = ViewModel.getData(DATEFORMAT);//从页面缓存中取
        if (__dateFormat) {
            return __dateFormat;
        }
    }
    // 如果连请求都没有请求到就走默认的
    __dateFormat = LocalDefaultDataFormat;
    return LocalDefaultDataFormat;
}

/**
 * 根据全局配置的时间格式处理需要的时间格式   
 * @param {String} format 需要的时间格式：三种类型
 */
export function getDatetimeFormat(format) {
    //默认的几种需要转数据格式的format
    let defaultDate = ['YYYY-MM-DD', 'YYYYMMDD', 'YYYY.MM.DD', 'YYYY/MM/DD'];
    let defaultDatetime = ['YYYY-MM-DD HH:mm:ss', 'YYYYMMDD HH:mm:ss', 'YYYY.MM.DD HH:mm:ss', 'YYYY/MM/DD HH:mm:ss'];
    let defaultTime = ['HH:mm:ss'];
    let timeFlag = defaultTime.includes(format);
    let dateFlag = defaultDate.includes(format);
    let datetimeFlag = defaultDatetime.includes(format);
    // 如果传的format不是默认的，按自定义处理，直接返回
    if (!timeFlag && !dateFlag && !datetimeFlag) {
        return format;
    }
    let dataFormat = getDataFormatFromCache();//从缓存中取数据格式
    // if (!dataFormat || !dataFormat?.fm?.dfm) {
    //     console.warn('not find dataFormat');
    //     return format;
    // }
    let dateFormat = dataFormat.fm.dfm;// 日期格式
    let timeFormat = dataFormat.fm.tfm;// 时间格式
    let isNulldelimitEnd = dateFormat.substring(dateFormat.length - 2) === '[]' ? true : false;// 日期格式的最后一项是否有分隔符
    if (dateFlag) {
        return dateFormat;
    } else if (datetimeFlag) {
        return isNulldelimitEnd ? dateFormat + ' ' + timeFormat : dateFormat + timeFormat;
    } else {
        return timeFormat;
    }
}

//==========================================下面两个方法公用的API==========================================
/**
 * 格式化数字 
 * @param {String} value 需要格式化的number字符串
 * return JSX   例如：<span style={{ color: 'red' }}>{formatNumber}</span>或者<span style={{ color: 'red' }}>{formatNumber}</span>
 */
export function formatNumber(value, notStyle = false) {
    if (!value) return '';
    let dataFormat = getDataFormatFromCache();
    // if (!dataFormat || !dataFormat?.fm?.nfm) {
    //     console.warn('not find dataFormat');
    //     return value;
    // }
    let formatNumber = dataFormat.fm.nfm;//数字格式
    let red = formatNumber.negativeFormat.red ? true : false;
    let useMil = formatNumber.useMil ? true : false;
    let milSymbol = formatNumber.milSymbol || ',';
    let decimalSymbol = formatNumber.decimalSymbol || '.';
    let code = formatNumber.negativeFormat.code || '-n';
    let originalValue = String(value).replace(' ', '');//确保是字符串没空格
    let flag = false;//负数标志
    // 首先判断是不是负数
    if (originalValue.indexOf('-') !== -1) {
        originalValue = originalValue.replace('-', '');//先去掉负号
        flag = true;
    }
    let valueArray = originalValue.split('.');
    let integral = valueArray[0];//整数部分
    let decimals = '';//小数部分  默认没有
    if (valueArray.length > 1) {
        decimals = valueArray[1];
    }
    //如果不显示千分位
    if (!useMil) {
        integral = integral.replace(/,/g, '');
    } else {
        if (milSymbol && milSymbol.length < 500) {
            integral = integral.replace(/,/g, '').replace(/\B(?=(?:\d{3})+(?!\d))/g, milSymbol);
        }
    }
    let combination = integral;
    if (decimals !== '') { combination = combination + decimalSymbol + decimals; }
    //如果是负数按照负数显示格式设置
    if (flag) {
        combination = code.replace(/n/, combination);
    }
    if (notStyle) {
        return combination;
    }
    if (red && flag) {
        return <span style={{ color: 'red' }}>{combination}</span>
    } else {
        return <span>{combination}</span>;
    }
}

/**
 * 根据全局设置的数据格式格式化日期和时间
 * @param {String} datetime 需要格式化的日期时间字符串 '2020-7-7 15:30"30'
 * @param {String} flag 显示格式标志  三种：1.'datetime'  2.'date'   3.'time' ；分别表示显示日期和时间、只显示日期、只显示时间
 * return 格式化过的日期时间字符串
 */
export function formatDatetime(datetime, flag) {
    if (!datetime) return '';
    let dateReg = /^\d{4}[-\/\s]\d{1,2}[-\/\s]\d{1,2}$/;// 日期正则
    let timeReg = /^\d{1,2}(:\d{1,2})?(:\d{1,2})?$/;// 时间正则
    //对于不传flag的情况，根据正则判断flag的类型
    if (moment(datetime).isValid()) {
        if (datetime.length < 100 && dateReg.test(datetime)) {
            flag = flag || 'date';
        } else {
            flag = flag || 'datetime';
        }
    } else if (datetime.length < 100 && timeReg.test(datetime)) {
        flag = flag || 'time';
    } else {
        return datetime;
    }
    let localLocale = flag === 'time' ? moment('2020-08-25 ' + datetime) : moment(datetime);
    localLocale.locale('en');//设置局部moment对象，将语种设置为英文
    let dataFormat = getDataFormatFromCache();//从缓存中取数据格式
    // if (!dataFormat || !dataFormat?.fm?.dfm) {
    //     console.warn('not find dataFormat');
    //     return datetime;
    // }
    let dateFormat = dataFormat.fm.dfm;// 日期格式
    let timeFormat = dataFormat.fm.tfm;// 时间格式
    let isNulldelimitEnd = dateFormat.substring(dateFormat.length - 2) === '[]' ? true : false;// 日期格式的最后一项是否有分隔符
    let finalDate;
    switch (flag) {
        case 'datetime':
            finalDate = isNulldelimitEnd ? dateFormat + ' ' + timeFormat : dateFormat + timeFormat;
            break;
        case 'date':
            finalDate = dateFormat;
            break;
        case 'time':
            finalDate = timeFormat;
            break;
        default:
            finalDate = dateFormat;
    }
    return localLocale.format(finalDate);
}