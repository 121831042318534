import React, { Component } from 'react';
import ViewModel from './viewmodel';
import ajax from './ajax'
import * as pageTo from './pageTo'
require('./shell/clientMonitor');

// TODO: shell

export function myBrowser() {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    var isOpera = userAgent.indexOf('Opera') > -1;
    if (isOpera) {
        //判断是否Opera浏览器
        return 'Opera';
    }
    if (userAgent.indexOf('Edge') > -1) {
        //判断是否Edge浏览器
        return 'Edge';
    }
    if (userAgent.indexOf('Firefox') > -1) {
        //判断是否Firefox浏览器
        return 'Firefox';
    }
    if (userAgent.indexOf('Chrome') > -1) {
        return 'Chrome';
    }
    if (userAgent.indexOf('Safari') > -1) {
        //判断是否Safari浏览器
        return 'Safari';
    }
    if (!!window.ActiveXObject || 'ActiveXObject' in window) {
        return 'IE';
    }
}

export function linkTo(templetId, areaCode, code, dataId) {
    ajax({
        url: '/nccloud/platform/pub/gethyperlink.do',
        method: 'post',
        data:
        {
            templetId,
            areaCode,
            code,
            dataId,
        }
        ,
        success: res => {
            if (res.data && res.data.pageurl) {
                pageTo.openTo(res.data.pageurl, {
                    appcode: res.data.parentcode,
                    id: dataId,
                    pagecode: res.data.pagecode,
                })
            }
        },
    })
}

export function withViewModel(ImportComponent) {
    class WithViewModelComponent extends Component {
        constructor(props) {
            super(props);
            //this.props.ViewModel = ViewModel;
        }
        render() {
            return <ImportComponent ViewModel={ViewModel} {...this.props} />;
        }
    }
    return WithViewModelComponent;
}

 /**
 * 小部件定时刷新HOC
 *
 * @param {String|Function} appcode 小部件appcode
 * @param {Function} widgetAutoRefresh 定时刷新执行的回调
 */
export const widgetAutoRefresh = (appcode, widgetAutoRefresh) => WidgetComponent => {
    return class AutoRefreshWidget extends React.Component {
        componentDidMount() {
            this.widgetAutoRefresh(); //小部件自动刷新
            document.addEventListener("visibilitychange", this.handleVisibilityChange);
        }

        componentWillUnmount() {
            this.clearWidgetTimer();
            document.removeEventListener("visibilitychange", this.handleVisibilityChange);
        }

        handleVisibilityChange = () => {
            
            if (document.visibilityState === "visible") {
                //页签激活重新触发计时器
                this.widgetAutoRefresh();
            } else {
                //页签隐藏清空定时器
                this.clearWidgetTimer();
            }
        }

        clearWidgetTimer = () => {
            this.widgetTimer && clearInterval(this.widgetTimer);
        }

        getAppcode = () => {
            if (typeof appcode === 'function') {
                return appcode(this.widgetRef);
            } else if (typeof appcode === 'string') {
                return appcode;
            }
        }

        getRefreshInterval = () => {
            const appcode = this.getAppcode();
            if (window.top.widgetRefreshTimeMap && appcode) {
                return window.top.widgetRefreshTimeMap[appcode];
            }

        }

        //小部件定时刷新定时器
        widgetAutoRefresh = () => {
            const refresh_interval = this.getRefreshInterval();
            const timer = parseInt(refresh_interval);
            if (!isNaN(timer) && typeof widgetAutoRefresh === 'function') {
                this.widgetTimer = window.setInterval(() => {
                    widgetAutoRefresh(this.widgetRef);
                }, timer * 1000);
            }
        };

        render() {
            return <WidgetComponent ref={ref => (this.widgetRef = ref)} {...this.props} />;
        }
    };
};

export * as appUrlUtils from './appUrlUtils';
export * as cipherTextTools from './cipherTextTools';
export * as dateUtils from './date';
export * as metaCache from './metaCache';
export * as simplifyData from './simplifyData';
export { default as config } from './config';
export { default as PubSub } from 'pubsub-js';
export { default as moment } from 'moment';
export { ViewModel as viewModel }
export { ViewModel }

export * from './multiLangUtils';