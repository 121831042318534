import IntlMessageFormat from "intl-messageformat";

class NCCloudIntl {
    constructor() {
        this.options = {
            currentLocale: null, // Current locale such as 'en-US'
            locales: {}, // app locale data like {"en-US":{"key1":"value1"},"zh-CN":{"key1":"值1"}}
            formats: null,
            warningHandler: console.warn,
        };
    }
    getDescendantProp(locale, key) {
        if (locale[key]) {
            return locale[key];
        }
    }
    get(key, variables) {
        const {locales, currentLocale, formats} = this.options;

        let msg = this.getDescendantProp(locales[currentLocale], key);

        try {
            const msgFormatter = new IntlMessageFormat(msg, currentLocale);
            return msgFormatter.format(variables);
        } catch (err) {
            this.options.warningHandler(
                `react-intl-universal format message failed for key='${key}'.`,
                err.message
            );
            return msg;
        }
    }
    init(options = {}) {
        let {currentLocale, locales} = options
        this.options.currentLocale = currentLocale
        this.options.locales = locales
    }

}
export default NCCloudIntl