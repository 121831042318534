/*
 * @Author: liyxt
 * @Date: 2020-06-01 14:08:06
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-02 09:45:36
 * @Description: file content
 */ 
import { encrypt, decrypt } from './cipher';
 
const aeskey = '4fa8959db7b4423a99f056e299914128';

/**
 * 设置本地缓存
 * @param {*string} store
 * @param {*string} key 
 * @param {*} value 
 */
export function setGlobalStorage (store, key, value) {
    if (!store) {
        return;
    }

    let storage = getStorage(store),
        storeCipher = localStorage.getItem('storeCipher') != '0';

    if (storeCipher) {
        value = encrypt(String(value), aeskey);
    }
    storage.setItem(key, value);
}

/**
 * 获取本地缓存
 * @param {*string} store
 * @param {*string} key 
 */
export function getGlobalStorage (store, key) {
    let storage = getStorage(store);
    if (key && storage) {
        if (!store) {
            return;
        }
        let value = storage.getItem(key),
            storeCipher = localStorage.getItem('storeCipher') != '0';
        if (storeCipher && value !== null) {
            value = decrypt(value, aeskey);
        }
        return value;
    }
}

/**
 * 删除本地缓存
 * @param {*string} store
 * @param {*string} key 
 */
export function removeGlobalStorage (store, key) {
    if (!store) {
        return;
    }
    let storage = getStorage(store);
    if (key) {
        storage.removeItem(key);
    }
}

function getStorage(store) {
    let storage = localStorage;
    if (store === 'sessionStorage') {
        storage = sessionStorage;
    }
    return storage;
}