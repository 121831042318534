import React from 'react';
import ReactDom from 'react-dom';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

import ExtendRouters from '../FastExtend/ExtendRouters';

const RouteWithSubRoutes = route => (
    <Route
        path={route.path}
        exact={route.exact}
        render={props => (
            <route.component {...props} routes={route.routes} />
        )}
    />
);

export function RenderRouter(rootElement, htmlTagid) {
    // 客开一下
    rootElement = (rootElement || []).concat([{
        path: '/nccextend/:extend',
        component: ExtendRouters
    }]);

    ReactDom.render(
        <Router>
            <Switch>
                {rootElement.map((route, i) => {
                    return <RouteWithSubRoutes key={i} {...route} />;
                })}
            </Switch>
        </Router>,
        document.getElementById(htmlTagid),
    );
}
