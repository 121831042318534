import { is } from 'immutable';

export const createSelector = function(selector, equalityCheck = defaultEqualityCheck) {
	let lastArgs = [],
		lastResult = null;
	return function(...args) {
		if (!areArgumentsShallowlyEqual(lastArgs, args, equalityCheck)) {
			lastArgs = args;
			lastResult = selector(...args);
		}
		return lastResult;
	};
};

function areArgumentsShallowlyEqual(prev, next, equalityCheck) {
	if (prev.length !== next.length) {
		return false;
	}

	const length = prev.length;
	for (let i = 0; i < length; i++) {
		if (!equalityCheck(prev[i], next[i])) {
			return false;
		}
	}

	return true;
}

function defaultEqualityCheck(a, b) {
	return is(a, b);
}
