/*
 * @Author: liyxt
 * @Date: 2019-10-21 14:16:00
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-11 11:14:27
 * @Description: file content
 */ 
import React, { Component } from 'react';

export function asyncComponent(importComponent) {
    class AsyncComponent extends Component {
        constructor(props) {
            super(props);
            this.state = { component: null };
        }
        componentDidMount() {
            this.asyncGetComponent();
        }
    asyncGetComponent = () => {
        const self = this;
        new Promise(resolve => {
            const asyncCom = importComponent();
            resolve(asyncCom);
        }).then(asyncCom => {
            const { default: component } = asyncCom;
            self.setState({ component });
        });
    }
    render() {
        const C = this.state.component;
        return C ? <C {...this.props} /> : null;
    }
    }
    return AsyncComponent;
}

export function loadjs(url, callback) {
    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onload = script.onreadystatechange = function() {
        if ((!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete')) {
            callback && callback();
            // Handle memory leak in IE?
            script.onload = script.onreadystatechange = null;
            if (head && script.parentNode) {
                head.removeChild(script);
            }
        }
    };
    head.insertBefore(script, head.firstChild);
}

