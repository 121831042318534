/*
 * @Author: liyxt
 * @Date: 2020-04-07 11:41:26
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-02 14:40:46
 * @Description: file content
 */ 
let Notification = window.Notification;
/**
 * @desc 获取通知权限
 */
function getAuthorityOfNotification() {
    if (Notification !== undefined && Notification.permission !== 'granted') {
        Notification.requestPermission().then(result => {
            if (result === 'granted') {
                noticeToUser({
                    title: '推送通知已启用!',
                    body: 'NC Cloud 现在有权限在本设备上发送本地消息',
                });
            }
        });
    }

}

/**
 *
 * @param params {object} {
 *   title: 'string'
 *   dir: 'string' 文字的方向；它的值可以是 auto（自动）, ltr（从左到右）, or rtl（从右到左）
 *   body: 'string' 通知中额外显示的字符串
 *   icon: 'string'  一个图片的URL，将被用于显示通知的图标
 *   onClose 'function' 关闭时回调
 * }
 */
function noticeToUser(params) {
    if (Notification === undefined) return;

    if (params.title === undefined) {
        console.error('通知标题不能为空');
        return;
    }

    let notification = new Notification(params.title, {
        dir: params.dir || 'auto',
        body: params.body,
        icon: params.icon,
    });

    notification.onclose = function () {
        params.onClose();
    };

}

export {
    getAuthorityOfNotification,
    noticeToUser,
};
