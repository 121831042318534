import { is } from 'immutable';

export function pureUpdate(nextProps, nextState) {
	let thisProps = this.props || {},
		thisState = this.state || {};

	if (
		Object.keys(thisProps).length !== Object.keys(nextProps).length ||
		Object.keys(thisState).length !== Object.keys(nextState).length
	) {
		return true;
	}

	for (let key in nextProps) {
		if (!is(thisProps[key], nextProps[key])) {
			return true;
		}
	}

	for (let key in nextState) {
		if (!is(thisState[key], nextState[key])) {
			return true;
		}
	}

	return false;
}
