import warning from 'warning';
import CONFIG from '../api/config';
import moment from 'moment';
import { DongbaToLocalTime, LocalToDongbaTime } from '../api/timer.js';
import { } from "../api/currentLocale";

/*
 * @method   错误警告，只警告一次
 * @author   add by yangguoqiang @18/03/01
 * @params
 *     condition     {Boolean.false}    生效条件，条件为false才执行
 *     format        {String}           提示语句
 *     arg           {[any]}            可选
 * @return   {undefined}       执行语句，无返回
 * @demo     warningOnce(false, '此处错误')
 */
const warned = {};

export function warningOnce(condition, format, arg) {
    if (!warned[format]) {
        warning(condition, format, arg);
        warned[format] = true;
    }
}

/*
 * @method   检测是否是常规的 Object  {} 这种形式
 * @author   add by yangguoqiang @18/03/05
 * @params
 *     condition     {any}
 * @return   {boolean}       返回ture/false
 * @demo     isObj()
 */
export function isObj(param) {
    return Object.prototype.toString.call(param).slice(8, -1) === 'Object';
}

/*
 * @method   if条件下为false   除去NaN、0、-0、false   剩余undefined、null、""
 * @author   add by yangguoqiang @18/03/19
 * @params
 *     condition     {any}
 * @return   {boolean}       返回ture/false
 * @demo     isWrong('')    因为后台返回数据不规范
 */
export function isWrong(param) {
    return typeof param === 'undefined' || param === null || param === '';
}

export function isNullOrVoid(param) {
    return typeof param === 'undefined' || param === null;
}

/*
 * @method   if条件下为false   除去NaN、0、-0、false   剩余undefined、null、""
 * @author   add by yangguoqiang @18/03/19
 * @params
 *     condition     {any}
 * @return   {boolean}       返回ture/false
 * @demo     isWrong('')    因为后台返回数据不规范
 */
export function isWrongFalse(param) {
    return typeof param === 'undefined' || param === null || param === '' || param === false;
}

/*
 * @method   检测是否是需要显示display的itemtype类型
 * @author   add by yangguoqiang @18/03/19
 * @params
 *     condition     {any}
 * @return   {boolean}       返回ture/false
 * @demo     isWrong('')    因为后台返回数据不规范
 */
export function isDisplay(param) {
    return CONFIG.displayTypes.includes(param);
}

/*
 * @method   检测是否是需要显示isValue的itemtype类型
 */

export function isValue(param) {
    return CONFIG.valueTypes.includes(param);
}

/*
 * @method   检测是否是是除了number的value类型
 */
export function isValueExceptNumber(param) {
    return CONFIG.valueTypesExceptNumber.includes(param);
}

/*
 * @method   检测浏览器是否是IE浏览器
 */
export function isIE() {
    if (!!window.ActiveXObject || "ActiveXObject" in window)
        return true;
    else
        return false;
}

/*
 * @method   转化东八区的时间值到具体的地区
 * @author   add by sunlei @18/05/5
 */

// 转换东八区为当地时间
export function changeTime(time, format = 'YYYY-MM-DD') {
    if (time) {
        let newTime = DongbaToLocalTime(moment(time));
        return newTime.format(format);
    }

    // let BeijinVal = time && moment(time).toDate();
    // let beijingZoneHours = time && moment(time).toDate().getHours();
    // let offsetZone = new Date().getTimezoneOffset() / 60;
    // offsetZone += 8;
    // BeijinVal && BeijinVal.setHours(beijingZoneHours - offsetZone);
    //return time && moment(BeijinVal).format('YYYY-MM-DD');
}

/*
 * @method   检测转换时区类型的控件
 * @author   add by sunlei @18/05/5
 */

export function isTimeType(param) {
    return CONFIG.timeTypes.includes(param);
}

/*
 * @method   检测是否是需要是boolean类型
 * @author   add by yangguoqiang @18/04/11
 * @params
 *     condition     {any}
 * @return   {boolean}       返回ture/false
 * @demo     isWrong('')    因为后台返回数据不规范
 */
export function isBoolean(param) {
    return CONFIG.boolean.includes(param);
}

/*
 * @method   检测是否是boolean类型
 * @author   add by zhanghengh @18/06/22
 * @params
 *     condition     {any}
 * @return   {boolean}       返回ture/false
 */
export function isBooleanType(param) {
    return param === true || param === false;
}

/*
 * @method   测试不存在或者值为true 同等效力
 * @author   add by yangguoqiang @18/03/19
 * @params
 *     one     {any}
 * @return   {boolean}       返回ture/false
 * @demo     undefinedOrTrue('')
 */
export function undefinedOrTrue(one) {
    return typeof one === 'undefined' || one === true;
}

/*
 * @method   测试 不存在或者值为false 同等效力
 * @author   add by yangguoqiang @18/03/19
 * @params
 *     one     {any}
 * @return   {boolean}       返回ture/false
 * @demo     undefinedOrfalse('')
 */
export function undefinedOrfalse(one) {
    return typeof one === 'undefined' || one === false;
}

/*
 * @method   根据不同类型初始化 null 输出后台可用的数据格式。
 * @author   add by yangguoqiang @18/03/19
 * @params
 *     origin    {any}      数据来源
 *     type      {string}   数据类型
 * @return   {any}          返回
 * @demo     typeFormat('', 'string')
 */
export function typeFormat(origin, type) {
    let isVoid = isWrong.call(null, origin);
    switch (true) {
        // 'input', 'textarea', 'datepicker', 'select', 'checkbox', 'radio', 'refer', 'label' 和 number的空value处理
        case [...CONFIG.string, ...CONFIG.number].includes(type) && isVoid:
            return '';
        // switch 的空value处理为boolean值
        case CONFIG.boolean.includes(type) && isVoid:
            return !!origin;
        default:
            return origin;
    }
}

/*
 * @method   检测是否是个数字(包括数字字符串  但是不包括 344. 这种)
 * @author   add by yangguoqiang @18/05/31
 * @params
 *     val        {Any}      元数据
 * @return   {Boolean}       true/false
 * @demo     isRealNum(234533)
 */
// export function isRealNum(val) {
//     var patrn = /^(-)?\d+(\.\d+)?$/;
//     if (patrn.exec(val) == null || val === '') {
//         return false;
//     } else {
//         return true;
//     }
// }

/*
 * @method   把B字节的size转化为特定容量
 * @author   add by yangguoqiang @18/05/31
 * @params
 *     bytes      {Number}      元数据
 *     fixed      {Number}      小数位数  默认为2位
 * @return   {String}           返回相应的数量
 * @demo     bytesToSize(234533, 2)
 */
// export function bytesToSize(bytes, fixed, unit) {
//     fixed = fixed || 2;
//     if (bytes == 0 || typeof bytes === 'undefined' || !isRealNum(bytes))
//         return fixed > 0 ? `0.${Array(fixed + 1).join('0')}B` : '0B';
//     var k = 1024,
//         sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
//         i = isWrong(unit) ? Math.floor(Math.log(bytes) / Math.log(k)) : unit;
//     return (bytes / Math.pow(k, i)).toFixed(fixed) + sizes[i];
// }

/*
 * @method   是否是undefined
 * @author   add by yangguoqiang @18/05/31
 * @params
 *     origin     {Any}      元数据
 * @return   {Boolean}          true/false
 * @demo     isUndefined(any)
 */
export function isUndefined(origin) {
    return typeof origin === 'undefined' || origin === void 0;
}

// 补0
export const addZero = (num, scale) => {
    if (num === '' || num === undefined || num === null) {
        return '';
    }

    if (scale > 0) {
        let start = String(num).split('.')[0];
        let end = String(num).split('.')[1];
        if (!end) {
            end = '';
        }
        let len = end.length;
        if (len < scale) {
            end = end.padEnd(scale, '0');
        }
        return start + '.' + end;
    } else {
        return num;
    }
};
// 将科学计数法转成数字字符串
export function convertNum(num_str) {
    //参数必须为 字符串
    //科学计数法字符 转换 为数字字符， 突破正数21位和负数7位的Number自动转换
    // 兼容 小数点左边有多位数的情况，即 a×10^b（aEb），a非标准范围（1≤|a|<10）下的情况。如 3453.54E-6 or 3453.54E6
    let resValue = '',
        power = '',
        result = null,
        dotIndex = 0,
        resArr = [],
        sym = '';
    let numStr = String(num_str);
    if (!/e/i.test(numStr)) {
        return numStr;
    }
    if (numStr[0] == '-') {
        // 如果为负数，转成正数处理，先去掉‘-’号，并保存‘-’.
        numStr = numStr.substr(1);
        sym = '-';
    }
    let regExp = new RegExp('^(((\\d+.?\\d+)|(\\d+))[Ee]{1}((-(\\d+))|(\\d+)))$', 'ig');
    if (numStr.length < 500) {
        result = regExp.exec(numStr);
    }
    if (result != null) {
        resValue = result[2];
        power = result[5];
        result = null;
    }
    if (!resValue && !power) {
        return false;
    }
    dotIndex = resValue.indexOf('.');
    resValue = resValue.replace('.', '');
    resArr = resValue.split('');
    if (Number(power) >= 0) {
        let subres = resValue.substr(dotIndex);
        power = Number(power);
        //幂数大于小数点后面的数字位数时，后面加0
        for (let i = 0; i < power - subres.length; i++) {
            resArr.push('0');
        }
        if (power - subres.length < 0) {
            resArr.splice(dotIndex + power, 0, '.');
        }
    } else {
        power = power.replace('-', '');
        power = Number(power);
        //幂数大于等于 小数点的index位置, 前面加0
        for (let i = 0; i <= power - 1; i++) {
            resArr.unshift('0');
        }
        let n = power - dotIndex >= 0 ? 1 : -(power - dotIndex);
        resArr.splice(n, 0, '.');
    }
    resValue = resArr.join('');
    return sym + resValue;
}

//精度 + 补0 + 千分位综合处理
export function formatAcuracy(value, len = 0, falg = false) {
    if (value === null || value === undefined || String(value).endsWith('必输项')) {
        return value;
    }
    // 将科学计数法转成数字字符串
    value = convertNum(value);
    // 千分位、补零、截取
    if (falg) {
        return addZero(formatDot(value, len), len);
    }
    return commafy(addZero(formatDot(value, len), len));
}

//移除千分位
export function removeThousands(val) {
    // 这里要区分 0 ‘’ null
    return val ? val.toString().replace(/\,/gi, '') : val;
}

//数字转换成千分位 格式
export function commafy(num) {
    let pointIndex, intPart, pointPart;
    if (num === '-') {
        return '-';
    }

    if (Number.isNaN(+(num + '').split(',').join(''))) {
        //这里暂时就处理一下千分位的逗号
        return '';
    }

    num = num + '';
    if (num.length < 500 && /^.*\..*$/.test(num)) {
        pointIndex = num.lastIndexOf('.');
        intPart = num.substring(0, pointIndex);
        pointPart = num.substring(pointIndex + 1, num.length);
        intPart = intPart + '';
        let re = /(-?\d+)(\d{3})/;
        while (intPart.length < 500 && re.test(intPart)) {
            intPart = intPart.replace(re, '$1,$2');
        }
        num = intPart + '.' + pointPart;
    } else {
        num = num + '';
        let re = /(-?\d+)(\d{3})/;
        while (num.length < 500 && re.test(num)) {
            num = num.replace(re, '$1,$2');
        }
    }
    return num;
}

// 精度处理
export function formatDot(value, len = 8) {
    let formatVal, dotSplit, val;

    val = (value || 0).toString();

    dotSplit = val.split('.');

    if (dotSplit.length > 2 || !value) {
        return value;
    }

    if (val.indexOf('.') > -1) {
        if (len == 0) {
            formatVal = dotSplit[0];
        } else {
            formatVal = val.substring(0, val.indexOf('.') + len + 1);
        }
    } else {
        formatVal = val;
    }

    return formatVal;
}

export function formatNumberByScale(value, len = 8) {
    return formatDot(removeThousands(value), len);
}
//判断数组,对象,字符串是否为空
export function isEmpty(param) {
    let result = false;

    if (typeof param === 'object') {
        let hasvalue = false;
        if (Array.isArray(param)) {
            hasvalue = param.length !== 0;
        } else {
            for (var pro in param) {
                hasvalue = true;
                break;
            }
        }

        result = !hasvalue;
    } else if (typeof param === 'string') {
        if (param === '') result = true;
    } else if (param === undefined) {
        result = true;
    }
    return result;
}
/*
 * @method   防抖方法
 * @author   add by xuyangt @19/03/08
 * @params
 *     callback     {fun}      回调函数
 *     interval     {number}   触发间隔时间
 * @return   {fun}
 * @demo
 */

export function debounds(callback, delay = 0) {
    let timeout = null;
    return function () {
        let that = this;
        let param = arguments;
        if (time) {
            clearTimeout(time);
        }
        timeout = setTimeout(function () {
            typeof callback === 'function' && callback.apply(that, param);
        }, delay);
    }
}

/* 检测类型是否为数组 */
export function isArray(param) {
    return Object.prototype.toString.call(param).slice(8, -1) === 'Array';
}

/* 检测类型是否为数组 */
export function isString(param) {
    return Object.prototype.toString.call(param).slice(8, -1) === 'String';
}

/* 检测类型是否为数组 */
export function isFunction(param) {
    return Object.prototype.toString.call(param).slice(8, -1) === 'Function';
}

/* 参照用 */
export function handleLoad(moduletype, moduleId, attrcode, refcode) {
    try {
        let Item = window[refcode].default;
        this.renderItem(moduletype, moduleId, attrcode, typeof Item === 'function' ? Item() : <Item />);
    } catch (e) {
        console.error(e.message);
        console.error(`请检查引用的${refcode}这个文件是源码还是编译好的。源码需要在config.json/buildEntryPath配相应的路径，编译好的则不用`);
    }
}

/* refcode */
export function createScript(moduletype, moduleId, attrcode, src) {
    var that = this,
        scripts = Array.from(document.getElementsByTagName('script')),
        s = src.split('/'),
        flag,
        refKey;
    refKey = s.slice(s.length - 5).join('/');
    refKey.includes('.js') && (refKey = refKey.substring(0, refKey.length - 3));
    flag = scripts.find(e => {
        return e.src.includes(refKey);
    });
    if (window[refKey]) {
        // 已经加载过script标签
        handleLoad.call(that, moduletype, moduleId, attrcode, refKey);
    } else {
        let script;
        if (flag) {
            script = flag;
        } else {
            script = document.createElement('script');
            script.src = '../../../../' + refKey + '.js';
            script.type = 'text/javascript';
            document.body.appendChild(script);
        }

        script.onload = script.onload || handleLoad.bind(that, moduletype, moduleId, attrcode, refKey);
        script.onerror =
            script.onerror ||
            function () {
                console.error(`找不到${src}这个文件，请检查引用路径`);
            };
    }
}

export function createScripts(moduletype, moduleId, scriptList) {
    let that = this;
    // 多个script都加载完之后，再setState
    let scripts = Array.from(document.getElementsByTagName('script'));

    if (!Array.isArray(scriptList)) {
        return;
    }

    // 整理参照列表
    let refKeyList = scriptList.filter(e => e.refcode).map(e => {
        // window下全局变量的key的列表
        let refKey = e.refcode.split('/'),
            { attrcode } = e;
        refKey = refKey.slice(refKey.length - 5).join('/');
        refKey.includes('.js') && (refKey = refKey.substring(0, refKey.length - 3));

        return { refKey, attrcode };
    });

    Promise.all(refKeyList.map(({ refKey, attrcode }) => loadJs(refKey, attrcode)))
        .then(res => {
            let prev = that.state.meta[moduleId].items.filter(e => e.itemtype === 'refer' && e.refcode).map(e => {
                // window下全局变量的key的列表
                let refKey = e.refcode.split('/'),
                    { attrcode } = e;
                refKey = refKey.slice(refKey.length - 5).join('/');
                refKey.includes('.js') && (refKey = refKey.substring(0, refKey.length - 3));

                return refKey;
            }),
                next = refKeyList.map(e => e.refKey);
            if (prev.length === next.length && prev.every((e, i) => e === next[i])) {
                finish.call(that, res);
            }
        })
        .catch(e => {
            console.error(e);
        });

    function loadJs(refKey, attrcode) {
        // resolve的是js加载结果
        return new Promise(resolve => {
            let flag = scripts.find(e => e.src.includes(refKey));

            let script;
            if (flag) {
                if (window[refKey]) {
                    resolve({ refKey, attrcode, success: true });
                } else {
                    script = flag;
                }
            } else {
                script = document.createElement('script');
                script.src = '../../../../' + refKey + '.js';
                script.type = 'text/javascript';
                document.body.appendChild(script);
            }

            script.onload =
                script.onload ||
                function () {
                    // 如果所有传进来的script都加载完（window里有对应的变量）
                    if (window[refKey]) {
                        resolve({ refKey, attrcode, success: true });
                    } else {
                        resolve({ refKey, attrcode, success: false });
                        console.error(`请检查引用的${refKey}这个文件是源码还是编译好的。源码需要在config.json/buildEntryPath配相应的路径，编译好的则不用`);
                    }
                };
            script.onerror =
                script.onerror ||
                function () {
                    document.body.removeChild(script);
                    resolve({ refKey, attrcode, success: false });
                    console.error(`找不到${refKey}这个文件，请检查引用路径`);
                };
        });
    }

    function finish(refKeyList) {
        // 如果所有参照都加载完毕，则setState
        let { renderItem } = this.state,
            obj = {};
        renderItem = renderItem || {};
        renderItem[moduletype] = renderItem[moduletype] || {};
        renderItem[moduletype][moduleId] = renderItem[moduletype][moduleId] || {};

        refKeyList.forEach(({ refKey, attrcode, success }) => {
            if (success) {
                // 加载js成功
                if (window[refKey] && typeof window[refKey].default === 'function') {
                    let Item = window[refKey].default;
                    obj[attrcode] = renderItem[moduletype][moduleId][attrcode] || Item();
                } else {
                    obj[attrcode] = <span />;
                    console.error(`${refKey}这个组件导出方式不对，请检查`);
                }
            }
        });
        if (
            refKeyList
                .filter(({ success }) => success)
                .some(({ attrcode }) => !renderItem[moduletype][moduleId][attrcode])
        ) {
            renderItem[moduletype][moduleId] = obj;
            this.setState({ renderItem });
        }
    }
}

//判断数组A是否包含数组b
export function isContained(a, b) {
    if (!(a instanceof Array) || !(b instanceof Array)) return false;
    if (a.length < b.length) return false;
    var aStr = a.toString();
    for (var i = 0, len = b.length; i < len; i++) {
        if (aStr.indexOf(b[i]) == -1) return false;
    }
    return true;
}

/* 简单克隆对象 */
export function cloneObj(data) {
    let res = {};
    let clone = data => {
        for (let key in data) {
            if (isObj(data[key])) {
                res[key] = { ...data[key] };
                clone(data[key]);
            } else if (isArray(data[key])) {
                res[key] = data[key].concat();
            } else {
                res[key] = { ...data[key] };
            }
        }
    };
    clone(data);
    return res;
}

/**
 * 判断两个对象是否内容相等
 */
export function isObjContEqual(a, b) {
    let aProps = Object.keys(a);
    let bProps = Object.keys(b);

    if (aProps.length !== bProps.length) {
        return false;
    }

    for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i];
        if (a[propName] !== b[propName]) {
            return false;
        }
    }
    return true;
}

/* 检测类型是否为数字 */
export function isNumber(param) {
    return Object.prototype.toString.call(param).slice(8, -1) === 'Number' && !Number.isNaN(param);
}

/* 获取参数 */
export function getParamByLocation(queryString, pop) {
    let result = '';
    queryString = queryString.substring(1);
    if (queryString) {
        let paramsArr = queryString.split('&');
        if (paramsArr && paramsArr instanceof Array) {
            paramsArr.forEach(item => {
                if (item.indexOf('=') != -1 && item.split('=') && item.split('=') instanceof Array) {
                    if (item.split('=')[0] === pop) {
                        if (item.split('=')[1]) {
                            result = decodeURIComponent(item.split('=')[1]);
                        }
                    }
                }
            });
        }
    }
    return result;
}

// 四舍五入 by wangyang
export function ncRounding(value, scale) {
    // 如果没有精度，不需要处理四舍五入
    if (!scale) return value;

    let [_value, _scale] = [value, scale];

    if (
        !Object.prototype.toString.call(scale) !== "[object Number]" &&
        !isNaN(Number(scale))
    )
        _scale = Number(scale);

    // 校验参数
    if (Object.prototype.toString.call(value) !== "[object String]")
        _value = String(value);

    const re = /^(\-|\+)?(\d+)?\.?(\d+)?$/;

    if (_value && _value.length < 500 && !re.test(_value)) {
        //console.warn("处理参数异常");
        return value;
    }

    // 分割value
    let [beforePoint, afterPoint] = _value.split(".");

    // 有小数位数
    if (afterPoint && afterPoint !== "") {
        // 判断小数位数与精度的关系
        if (afterPoint.length > _scale) {
            _value = Number(_value);
            // 进行四舍五入操作
            _value = Number(_value.toFixed(_scale + 1));

            _value = _value * Math.pow(10, _scale);

            _value = Math.round(_value);

            _value = _value / Math.pow(10, _scale);
        }
    }

    return _value;
}
// 减法方法
export function Subtr(arg1, arg2) {
    if (!arg1) {
        arg1 = '0';
    }
    if (!arg2) {
        arg2 = '0';
    }
    var r1, r2, m, n;
    try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
    try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
    m = Math.pow(10, Math.max(r1, r2));
    //last modify by deeka
    //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

// 产生随机key值
export function getRandom() {
    return String(new Date().getTime()).slice(-5) + getSafeRandom().toString(12);
}

/**
 * issue: https://stackoverflow.com/questions/2600186/focus-doesnt-work-in-ie, For IE you need to use a setTimeout function due to it being lazy
 */
export function setElementFocusInIE(dom) {
    let timer = setTimeout(() => {
        dom && dom.focus();
        clearTimeout(timer);
    }, 100);
}

/**
 * @desc 判断是否按下了方向键
 * @param e {obj} 事件源
 * @param wantedKeys {array} 想要监听的按钮
 */
export function isArrowKeyDown(e, wantedKeys) {
    if (e.ctrlKey || e.shiftKey || e.altKey || e.metaKey) return false;
    let keys = wantedKeys || [37, 38, 39, 40];
    return keys.includes(e.keyCode);
}

/**
 * @desc 判断 control + 上下 方向键
 * @param e {object}
 */
export function ctrlKeyWithUpDown(e) {
    return (e.ctrlKey || e.metaKey) && [38, 40].includes(e.keyCode);
}

/**
 * @desc 判断 enter 事件
 * @param e {object} 事件源
 * @return {BooleanConstructor}
 */
export function isEnterKeyDown(e) {
    //中文输入法下按的enter keyCode 都返回 229
    return e.key === 'Enter' && e.keyCode !== 229 && e.ctrlKey === false && e.altKey === false && e.metaKey === false;
}

function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(getSafeRandom() * (max - min + 1)) + min;
}

/**
 * @description: 获取随机6-12位 *******
 * @param {type} 
 * @return: 
 */
export function getRandomPassword() {
    return Array(getRandomIntInclusive(6, 12)).fill('*').join('');
}

/**
 * @description: 防抖函数
 * @param {type} 
 * @return: 
 */
export function debounce(func, delay) {
    var timeout;
    return function () {
        clearTimeout(timeout);
        var context = this, args = arguments
        timeout = setTimeout(function () {
            func.apply(context, args);
        }, delay)
    };
}

/**
 * @description: 节流函数
 * @param {type} 
 * @return: 
 */
export function throttle(fn, delay) {
    var timeout
    var start = new Date;
    var threshHold = delay || 160
    return function () {
        var context = this, args = arguments, curr = new Date() - 0
        clearTimeout(timeout)
        if (curr - start >= threshHold) {
            fn.apply(context, args)
            start = curr
        } else {
            timeout = setTimeout(function () {
                fn.apply(context, args)
            }, threshHold);
        }
    }
}

export function getSafeRandom() {
    const crypto = window.crypto || window.msCrypto;
    var array = new Uint32Array(1);
    let random = crypto.getRandomValues(array)[0];
    return random * Math.pow(10, -10)
}
