/**
 * 
 * 共享单独拿了我们的文件用
 * 
 * crypto-js 方式实现的加密解密
 */
import CryptoJS from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import Hex from 'crypto-js/enc-hex';
import { getSafeRandom } from './utils'
function download(filename, text) {
    // let upp = prompt('解密解压错误，点击确定下载前端日志文件，并联系开发');
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

/**
 * 增强加密
 */
const KEY_ENHANCE = Base64.parse('ZGIyMTM5NTYxYzlmZTA2OA==');
/**
 * 关键字
 */
const PRIVATE_KEY = generateKey(uuidv4());
/**
 * 是否进行加密解密
 * 可变数据
 */
let CipherFlag = false;

/**
 * 采用hex方式处理
 */
const isHex = false;

/**
 * 加密解密参数
 */
const cfg3 = {
    iv: KEY_ENHANCE,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
};

const cfg = {
    iv: KEY_ENHANCE,
    mode: CryptoJS.mode.CTR,
    padding: CryptoJS.pad.NoPadding,
};
/**
 * 生成唯一标识
 */
function uuidv4() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (getSafeRandom() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
/**
 * 生成 秘钥的方法
 * @param {*} text
 * @param {*} bytes
 */
function generateKey(text = '') {
    return Base64.stringify(Utf8.parse(text)) || 'ZGIyMTM5NTYxYzlmZTA2OA==';
}
/**
 * 加密
 * @param {*} message  明文信息
 * @param {*} key 秘钥
 * @param {*} standard 标准
 */
function encrypt(message = '', key = PRIVATE_KEY, standard = 'AES') {
    let keyDigest = Base64.parse(key);
    let encrypted = CryptoJS[standard].encrypt(message, keyDigest, cfg);
    // 这里需要注意  先拿结果  然后再做base64 加密  encrypted.ciphertext 是个16进制字符串
    let ciphertext = Base64.stringify(
        (isHex ? Hex : Utf8).parse(encrypted.ciphertext.toString()),
    );
    // let ciphertext = Base64.stringify(Hex.parse(encrypted.ciphertext.toString()));
    return ciphertext;
}
/**
 * 解密
 * @param {*} message  密文信息
 * @param {*} key 秘钥
 * @param {*} standard 标准
 */
function decrypt(message = '', key = PRIVATE_KEY, standard = 'AES') {
    try {
        let keyDigest = Base64.parse(key);
        //  base64 解密  plaintext 只能是Hex编码的base64字节数组
        let plaintext = isHex
            ? message
            : Base64.stringify(Hex.parse(Base64.parse(message).toString(Utf8)));
        let decrypted = CryptoJS[standard].decrypt(plaintext, keyDigest, cfg);
        return decrypted.toString(Utf8);
    } catch (error) {
        let rockin = localStorage.getItem('rockin');
        let cowboy = localStorage.getItem('cowboy');
        let storeCipher = localStorage.getItem('storeCipher');
        let gzip = localStorage.getItem('gzip');
        let estr = `解密错误，请找平台开发看下,或者记录当前数据, \r\nrockin: ${rockin},\r\ncowboy: ${cowboy},\r\ngzip: ${gzip},\r\nstoreCipher: ${storeCipher},\r\n cookie: ${document.cookie}\r\n`;
        estr += `原始字符：${message}\r\n\r\n错误日志：`;

        // download('前端日志文件' + new Date().getTime(), estr + error);
        console.error ? console.error(error) : console.log(error);
        console.log(message);
        throw new Error(error);
    }
}

// JSON 工具类二次封装
const Json = {
    toJSON(o) {
        return JSON.stringify(o);
    },
    parseJSON(s) {
        return JSON.parse(s);
    },
};
/**
 * 对json进行加密
 * @param {*} json
 * @param {*} decryptOpt
 */
function jsonToEncrypt(json, decryptOpt = {}) {
    let ciphertext;
    try {
        ciphertext = encrypt(Json.toJSON(json), ...decryptOpt);
    } catch (error) {
        ciphertext = json;
        console.log('encrypt error: %s', error);
    }
    return ciphertext;
}

let methods = {
    encrypt,
    decrypt,
    jsonToEncrypt,
    // opaqueEncrypt, 
    // opaqueDecrypt,
};

const Cipher = {
    Json,
    PRIVATE_KEY,
    CipherFlag,
    ...(function () {
        // 对当前组件中的方法进行处理  主要是到处添加 开关太麻烦
        let defaultFunction = data => data;
        for (let k in methods) {
            //methods[k] = !CipherFlag ? defaultFunction : methods[k];
            let f = methods[k];
            methods[k] = function () {
                //console.time(`${k}`);
                // 包装一次传递参数
                let res = (!Cipher.CipherFlag ? defaultFunction : f).apply(
                    this,
                    arguments,
                );
                //console.timeEnd(`${k}`);
                return res;
            };
        }
        return methods;
    })(),
};

// console.log(Cipher);

export default Cipher;

/**
 * 不透明的加密
 * @param {*} msg 
 */
const opaqueEncrypt = msg => {
    if (!msg) {
        console.log('加密没有原文');
        return '';
    }
    return encrypt(msg, '4fa8959db7b4423a99f056e299914128');
};
/**
 * 不透明的解密
 * @param {*} msg 
 */
const opaqueDecrypt = msg => {
    if (!msg) {
        console.log('解密没有原文');
        return '';
    }
    return decrypt(msg, '4fa8959db7b4423a99f056e299914128');
};

// 抛出一系列方法
export { encrypt, decrypt, jsonToEncrypt, opaqueEncrypt, opaqueDecrypt };
