import React, { Component } from 'react';

const map = {
	form: {
		src: 'Form',
		dep: []
	},
	list: 'List'
};

export const use = function(useList) {
	return function(App) {
		return class Wrapper extends Component {
			constructor(props) {
				super(props);
				this.state = {
					loaded: false
				};
			}

			componentDidMount() {
				if (Array.isArray(useList)) {
					// Promise.all(useList.map(dep => import(`../../mobile/page/${map[dep]}/index.js`))).then(res => {
					// 	console.log(res);
					// 	this.setState({
					// 		loaded: true
					// 	});
					// });
				} else {
					this.setState({
						loaded: true
					});
				}
			}

			render() {
				let { loaded } = this.state;
				return loaded && <App />;
			}
		};
	};
};
