import { getMultiLang } from './getMultiLang';
import viewModel from './viewmodel';

/**
 *
 * @param {*} moduleIds ：Object  传入需要合并的多语文件名称
 * @param {*} callback ：Function  回调函数
 *
 */
export function initMultiLangByModule(moduleIds = {}, callback) {
    // let keysArr = Object.keys(moduleIds);
    // recursion(moduleIds, keysArr, 0, callback);
    let deal = (json, status,intl) => {
        if (status) {
            let jsonObj = viewModel.getData('Platform_MultiLang') || {};
            viewModel.setData('Platform_MultiLang', { ...jsonObj, ...json });
            viewModel.setData('Platform_MultiLang_Intl', intl );
        }
        if (typeof callback == 'function') {
            callback();
        }
    };
    getMultiLang({
        moduleId: moduleIds,
        // currentLocale: 'simpchn',
        // domainName: arr[index],
        callback: deal
    });
}

//根据应用编码获取当前所有的多语文件
export function getAllMultiLangByModule() {
    let jsonObj = viewModel.getData('Platform_MultiLang') || {};
    let intlObj = viewModel.getData('Platform_MultiLang_Intl') || null;
    return {json:jsonObj, intl: intlObj};
}

/**
 * 根据应用编码和ID获取具体的多语字段
 * @param {*} resId 多语编码字段
 * @param {*} varValues 变量对象
 */
/*export function getMultiLangByID(resId = '', varValues) {
    let json = viewModel.getData('AM_MultiLang') || {};
    let jsonStr = json[resId] || resId;
    if (varValues && jsonStr) {
        for (let key in varValues) {
            jsonStr = jsonStr.replace('{' + key + '}', varValues[key]);
        }
    }
    return jsonStr;
}*/
export function getMultiLangByID(key, variables) {
    let json = viewModel.getData('Platform_MultiLang') || {};
    let str = json[key] || key
    if (variables&&Object.keys(variables).length) {
        for (let [item, value] of Object.entries(variables)) {
            let reg = new RegExp(item,"gm")
            str=str.replace(reg,value);
        }
        str=str.replace(/{/g,'');
        str=str.replace(/}/g,'');
        return str
    } else {
        return str
    }

}