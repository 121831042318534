import axios from 'axios';
import Gzip from './gzip';
import { setGlobalStorage, getGlobalStorage } from './storage';
import Cipher from './cipher';
let gziptools = new Gzip();

export default function forwardUrl({ url, crux, busiaction, userId, gzipFlag, cipherFlag, aeskey, pagecs, userCode, cookiets }, callBack) {

    let connectSwitch = getGlobalStorage('localStorage', '_ncc_websocket_switch_');
    // 分盘环境不支持
    if (connectSwitch === '1') {
        return callBack(url);
    }

    let confirmShuntApi = '/nccloud/platform/pub/clientdispatch.do';
    let ShuntServerInfo = getGlobalStorage('localStorage', 'ShuntServerInfo');
    if (ShuntServerInfo) {
        ShuntServerInfo = JSON.parse(ShuntServerInfo);
    }
    let newUrl = url;

    //登录页不会发请求: 登录页使用的是workbench的ajax

    //登录以后 发起请求 应用页面内有效
    let dataParams = {
        sysParamJson: {
            pagecs,
            busiaction: '分流',
            ts: new Date().getTime(),
        },
    };

    let header = {
        'Content-Type': 'application/json;charset=UTF-8',
        'crux': crux,
        'cookiets': cookiets,
    };
    dataParams = JSON.stringify(addDataSign(dataParams, '', { allin: true, headers: header }));

    // 用户存在时再发请求
    if (!ShuntServerInfo && userCode && userId) {
        axios({
            method: 'post',
            url: confirmShuntApi,
            headers: header,
            data: dataParams,
            transformRequest: [
                function (data) {
                    let gData = gzipFlag ? gziptools.zip(data) : data;
                    return cipherFlag ? Cipher.encrypt(gData, aeskey) : gData;
                },
            ],
            transformResponse: [
                function (data) {
                    //let resData = gzipFlag ? gziptools.unzip(data) : data;
                    //return resData;
                    data = typeof data === 'string' && cipherFlag ? Cipher.decrypt(data, aeskey) : data;
                    return gzipFlag ? gziptools.unzip(data) : isJSON(data) ? JSON.parse(data) : data;
                },
            ],
            withCredentials: true,
        }).then(res => {
            if (res.data.success && res.data.data && res.data.data.rule) {
                let rule = res.data.data.rule;
                if (rule) {
                    let userRule = res.data.data.userRule;
                    if (userRule) {
                        rule = Object.assign(rule, userRule);
                    }
                    setGlobalStorage('localStorage', 'ShuntServerInfo', JSON.stringify(rule));
                    newUrl = getNewUrl(rule, url, busiaction, userId);
                    callBack(newUrl);
                } else {
                    //请求失败 直接执行原来请求
                    callBack(newUrl);
                }
            } else {
                //请求失败 直接执行原来请求
                callBack(newUrl);
            }
        }).catch(() => {
            //请求失败 直接执行原来请求
            callBack(newUrl);
        });
    } else {
        newUrl = getNewUrl(ShuntServerInfo, url, busiaction, userId);
        callBack(newUrl);
    }
}

//NCCloud前端分流使用URI的方式
function getNewUrl(rule, url, busiaction, userId) {
    let urlArr = url.split('/');
    if (urlArr.length >= 2 && urlArr[1] === 'nccloud' && rule) {
        //根据业务名称 + url 转发
        let key1 = busiaction + '###' + url.split('/').join('.').slice(1);
        if (rule[key1]) {
            urlArr.splice(2, 0, rule[key1]);
            return urlArr.join('/');
        }

        //根据业务名称
        let key2 = busiaction + '###';
        if (rule[key2]) {
            urlArr.splice(2, 0, rule[key2]);
            return urlArr.join('/');
        }

        //根据 url 转发
        let key3 = '###' + url.split('/').join('.').slice(1);
        if (rule[key3]) {
            urlArr.splice(2, 0, rule[key3]);
            return urlArr.join('/');
        }

        //根据用户转发
        let key4 = userId;
        if (rule[key4]) {
            urlArr.splice(2, 0, rule[key4]);
            return urlArr.join('/');
        }
    }

    return url;
}

// URL的分流方式是直接修改 ip/port
// 直接修改 port 后台通过配置 Access-Control-Allow-Origin 指定可访问域名 可行 但安全过不去
// 若要修改 ip 后台肯定是取不到cookie的，因此前端转发到不同的ip  
// URL通过在前端转发的方案基本上是不可行的

function addDataSign(data, userCode, {
    allin = false,
    headers = {},
}) {
    // console.log(data, userCode);
    if (
        typeof transSn === 'undefined' ||
        typeof transSign === 'undefined' ||
        // !userCode ||
        (headers && headers['Content-Type'] == 'multipart/form-data') // newData.append(  这种模式 先确定下 需不需要加签操作
    ) {
        return data;
    }
    // 先加签全部数据 或者部分数据

    // 这里会影响原字符串 （编码、位数？）导致后台解析不了字符串
    if (!allin) {
        let strDataSign = data.busiParamJson + '';
        // 加签 -- bbqin
        data.sysParamJson.sn = transSn(userCode);
        data.sysParamJson.signdata = transSign(strDataSign, userCode);
    } else {
        // 加签 -- bbqin
        // data.sysParamJson.sn = transSn(userCode);
        let dataStr = JSON.stringify(data) + '';
        headers['Identifications'] = transSign(dataStr, '');
    }
    // 这里是通过对象方式注入  headers  不符合设计原则 TODO 
    return data;
}

function isJSON(str) {
    if (typeof str == 'string') {
        try {
            var obj = JSON.parse(str);
            if (typeof obj == 'object' && obj) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.log('error：' + str + '!!!' + e);
            return false;
        }
    }
    console.log('It is not a string!');
}

