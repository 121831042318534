/*
 * @Descripttion: 
 * @version: 
 * @Author: bbq
 * @Date: 2019-10-21 14:42:57
 * @LastEditors: bbq
 * @LastEditTime: 2020-08-28 10:45:08
 */
import { PubSub } from './_unstable';

/**
 * @desc 业务组控制自动聚焦，
 * @param control {boolean} true -> 业务组控制 false-> 平台控制
 */
function controlAutoFocus(control) {
    this.autoFocusDeferred = control;
}

function executeAutoFocus() {
    PubSub.publish('autoFocus-IoC');
}

export {
    executeAutoFocus,
    controlAutoFocus,
};