/*
 * @Author: liyxt
 * @Date: 2020-05-19 15:47:39
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-01 14:25:36
 * @Description: file content
 */ 
import { setGlobalStorage, getGlobalStorage, removeGlobalStorage } from './storage';
import { setCookie, getCookie } from './cookie';

function set(key, val) {
    try {
        let session = getGlobalStorage('sessionStorage', 'NCCSessionStorage');
        if (session) {
            session = JSON.parse(session);
            session[key] = val;
        } else {
            session = { [key]: val };
        }
        setGlobalStorage('sessionStorage', 'NCCSessionStorage', JSON.stringify(session));
    } catch (e) {
        console.error('sessionStorage容量溢出');
        //remove(key);
        // set(key, val);
    }
}

function get(key) {
    let session = getGlobalStorage('sessionStorage', 'NCCSessionStorage');
    if (session) {
        session = JSON.parse(session);
        return session[key];
    }

    return null;
}

function remove(key) {
    let session = getGlobalStorage('sessionStorage', 'NCCSessionStorage');
    if (session) {
        session = JSON.parse(session);
        delete session[key];
        setGlobalStorage('sessionStorage', 'NCCSessionStorage', JSON.stringify(session));
    }
}

/**
 * 清模板缓存，登录页切换语种时使用 by renyjk
 */
function clearTemplet() {
    Object.keys(localStorage).forEach(e => {
        if (String(e).includes('appTempletStorage_')) {
            delete localStorage[e];
        }
    });
}

function clear(keysArr, isClearTemplet = false) {
    removeGlobalStorage('sessionStorage', 'NCCAPPURL');
    removeGlobalStorage('sessionStorage', 'NCCSessionStorage');
    let exceptLocalKeys = ['ColumnConfig', 'referTreeConfig', 'referHistory', 'rockin', '_ncc_automated_', '_ncc_pagesize_store', 'fold_flag', 'userloginid', 'langCode', 'busiCenterCode'];
    Object.keys(localStorage)
        .filter(e => {
            let res =
				// local中的所有例外(不被清除)标识  如 cowboy-*
				String(e).indexOf('cowboy') !== -1 ||
				// local中的所有例外(不被清除)标识  如 retain-*
				String(e).indexOf('retain') !== -1 ||
				exceptLocalKeys.includes(e) ||
				// local中不清除模板缓存
				(!isClearTemplet && String(e).includes('appTempletStorage_'));
            return !res;
        })
        .forEach(e => delete localStorage[e]);
    let allCookies = getCookie();
    let exceptKeys = ['userloginid', 'langCode', 'busiCenterCode'];
    if (keysArr && keysArr instanceof Array) {
        exceptKeys = keysArr;
    }
    for (let cookieKey in allCookies) {
        if (!exceptKeys.includes(cookieKey)) {
            setCookie(cookieKey, null, null, null, -1);
        }
    }
}

export {
    set,
    get,
    remove,
    clear,
    clearTemplet,
};
