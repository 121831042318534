import { Component } from 'react';
import ViewModel from '../../viewmodel';
// import { AnimationFrameScheduler } from 'rxjs/internal/scheduler/AnimationFrameScheduler';
/*
* autor:张建新  zjx@yonyou.com   
* date: 2018/07/17
* ClientMonitor for App,render count etc.
* useage: 

* modify 陈道诚 chendch@yonyou.com 
* date 2020/6/8
*/
const _monitorDataPath = 'monitro.ui.data';
class ClientMonitor extends Component {
    constructor(props) {
        super(props);
        this.ViewModel = ViewModel;
        this._page = null;
        this.pageName = '';
        this.firstStartTime = null;
        this.flag_Monitor = 'sys_flag_bmonitor';
    }

	reset = () => {
		window.top.monitorDataTotal = {};
		window.top.monitorDataTotal.countNumber = [];
		window.top.monitorDataTotal.count = 0;
		window.top.monitorDataTotal.pageComponent = [];
		window.top.monitorDataTotal.startRenderTime = new Date();
	};
	getMonitorData = () => {
		if(this._page&&this._page.refs&&this._page.refs.monitorpage){
				return this._page.refs.monitorpage.props.ViewModel.getData(this.getCurrentNamespace(this.pageName));
		}else{
			return {WARINING:'监控异常，请刷新页面重试'};
		}
	};
	getbMonitor = () => {
		let v = false;
		try{
			v = JSON.parse(sessionStorage.getItem(this.flag_Monitor));
			if (v == null) {
				v = false;
			}
		}catch(ex){
			v = false;
		}
		return v;
	};
	endMonitor = () => {
		if(!this.getbMonitor()){
			
			return {WARINING:'监控没有开启，请开启 window.sys_monitor.startMonitor()'};
		}
		sessionStorage.setItem(this.flag_Monitor, false);
		let result = !!window.top.monitorDataTotal ? JSON.stringify(window.top.monitorDataTotal) : 
			JSON.stringify({count: 0,countNumber: [],pageComponent: []});
		this.reset();
		return result;
	};
	getCurrentNamespace = (name) => {
		return _monitorDataPath + '.' + name;
	};
	startMonitor = () => {
		sessionStorage.setItem(this.flag_Monitor, true);
		debugger
		return true;
	};
	
	initMonitor = (page, init = true) => {
		this._page = page;
		this.reset();
		console.log("clientMontor---");
		if(page && page.refs && page.refs.monitorpage){
			let mp = page.refs.monitorpage;
			this.mpCopy = page.refs.monitorpage;
			this.pageName = mp.constructor.name;
			//
			if(init) this.reset();
		
			//
			let cwcC = mp.componentWillUpdate;
			mp.componentWillUpdate = this.componentWillUpdateC;
			window.top.monitorDataTotal._cwcC = cwcC;
			//
			let cwmC = mp.componentWillMount;
			mp.componentWillMount = this.componentWillMountC;
			window.top.monitorDataTotal._cwmC = cwmC;
			//
			let cdmC = mp.componentDidMount;
			mp.componentDidMount = this.componentDidMountC;
			window.top.monitorDataTotal._cdmC = cdmC;
			//
			let cduC = mp.componentDidUpdate;
			mp.componentDidUpdate = this.componentDidUpdateC;
			window.top.monitorDataTotal._cudC = cduC;
			//
			console.log(this.pageName, window.top.monitorDataTotal)
			
				//
				let d = this.getMonitorData();
				if(d&&d._rC) return ;
			//
				let rC = mp.render;
				mp.render = this.renderC;
				window.top.monitorDataTotal._rC = rC;
				mp.props.ViewModel.setData(this.getCurrentNamespace(this.pageName), window.top.monitorDataTotal);
		}
	};

	componentWillMountC = function() {
		let currenNamespace = _monitorDataPath + '.' + this.constructor.name;
		let monitorData = this.props.ViewModel.getData(currenNamespace);
		window.top.monitorDataTotal.startRenderTime = new Date();
		if(monitorData._cwmC){
			return monitorData.cwmC.bind(this)();
		}
	}

	componentDidMountC = function() {
		let currenNamespace = _monitorDataPath + '.' + this.constructor.name;
		let monitorData = this.props.ViewModel.getData(currenNamespace);
		let endRenderTime = new Date();

		let cost = (endRenderTime.getTime() - window.top.monitorDataTotal.startRenderTime.getTime()) / 1000;
		window.top.monitorDataTotal.countNumber[window.top.monitorDataTotal.count] = '第' + window.top.monitorDataTotal.count + '次渲染耗时：' + cost + '秒';
		window.top.monitorDataTotal.count++;
		window.top.monitorDataTotal.endRenderTime = endRenderTime;
		if(monitorData._cdmC){
			return monitorData.cdmC.bind(this)();
		}
	}

	componentWillUpdateC = function() {
		console.log('---monitor:' + this.constructor.name + 'react脏数据已对比完成，准备开始更新---')
		let currenNamespace = _monitorDataPath + '.' + this.constructor.name;
		let monitorData = this.props.ViewModel.getData(currenNamespace);
		window.top.monitorDataTotal.startRenderTime = new Date();
		if(monitorData._cwdC){
			return monitorData.cwcC.bind(this)();
		}
	}

	componentDidUpdateC = function() {
		console.log('---monitor:' + this.constructor.name + '组件更新及dom节点更新完毕---');
		let currenNamespace = _monitorDataPath + '.' + this.constructor.name;
		let monitorData = this.props.ViewModel.getData(currenNamespace);
		
		let endRenderTime = new Date();
		let cost = (endRenderTime.getTime() - window.top.monitorDataTotal.startRenderTime.getTime()) / 1000;
		window.top.monitorDataTotal.countNumber[window.top.monitorDataTotal.count] = '第' + window.top.monitorDataTotal.count + '次渲染耗时：' + cost + '秒';
		window.top.monitorDataTotal.count++;
		window.top.monitorDataTotal.endRenderTime = endRenderTime;
		//
		if(monitorData._cudC){
			return monitorData._cudC.bind(this)();
		}
	};
	renderC = function() {
		let currenNamespace = _monitorDataPath + '.' + this.constructor.name;
		let monitorData = this.props.ViewModel.getData(currenNamespace);
		if(!window.top.monitorDataTotal)window.top.monitorDataTotal = {};
		console.log('---monitor:组件' + this.constructor.name + '开始render---');
		if(monitorData._rC&&monitorData._rC.bind){
			return monitorData._rC.bind(this)();
		}
	};
}
window.top.sys_monitor= new ClientMonitor();
export default ClientMonitor;
