/*
 * @Author: liyxt
 * @Date: 2020-05-19 15:47:39
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-01 14:31:09
 * @Description: file content
 */ 
import React from 'react';
import * as pageTo from './pageTo';
import { getGlobalStorage } from './storage';

export const createPageIcon = () => {
    let Appcode = pageTo && pageTo.getAppCode ? pageTo.getAppCode() : '';
    let pageIconInfo = getGlobalStorage('localStorage', 'APPICONSSTORE');
    pageIconInfo = pageIconInfo && JSON.parse(pageIconInfo);
    // 区分不同风格的图标
    //let nccIcon = localStorage.getItem('nccIcon');
    let nccIcon = getGlobalStorage('localStorage', 'nccIcon');
    nccIcon = nccIcon ? `${nccIcon}_` : '';
    let name = Appcode && pageIconInfo ? pageIconInfo[Appcode] : '';
    if (name == 'default') {
        name = nccIcon == '' ? name : nccIcon;
        return <img src={require(`../static/images/pageMainIcon/${name}.svg`)} className='page-title-Icon' />;
    } else {
        let fileName = name ? name.slice(0, name.length - 1) : '';
        return name && fileName ? (
            <img
                src={require(`../static/images/pageMainIcon/${fileName}/${nccIcon}${name}.svg`)}
                className='page-title-Icon'
            />
        ) : null;
    }
};
