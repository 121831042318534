import React, { Component } from 'react';
import OriginProgressBar from 'bee-progress-bar';

require('bee-progress-bar/build/ProgressBar.css');
require('./index.less');

export default class ProgressBar extends Component {
	render() {
		return <OriginProgressBar {...this.props} className={`${this.props.className || ''} nc-progress`} />;
	}
}
 