// 判断第一个参数是否为空，后面可以传其他【认为是空值】的参数
function isEmpty(val, ...rest) {
	if (val === null || val === undefined || rest.find((e) => e == val)) {
		return true;
	}
	return false;
}

/**
 * 
 * @param {*Object} data 
 */
// 删除全部为空的字段-----资产用
export function deleteEmptyData(data, deepChoneFlag = true) {
	if (data && Array.isArray(data.rows) && data.rows.length) {
		deepChoneFlag && (data = JSON.parse(JSON.stringify(data)));
		data.rows.map((item) => {
			if (item.values) {
				for (let pop in item.values) {
					if (item.values[pop]) {
						// if (item.values[pop].hasOwnProperty('display')) {
						//     delete item.values[pop].display;
						// }
						// if (item.values[pop].hasOwnProperty('scale')) {
						//     delete item.values[pop].scale;
						// }
						// if (item.values[pop].value === null) {
						//     delete item.values[pop].value;
						// }
						if (
							isEmpty(item.values[pop].display) &&
							isEmpty(item.values[pop].scale, -1) &&
							isEmpty(item.values[pop].value)
						) {
							item.values[pop] = {};
						}
					}
					// if(Object.keys(item.values[pop]).length === 0){
					//     delete item.values[pop];
					// }
				}
			}
			return item;
		});
	}
	return data;
}

// 项目内部用
export function delEmptyData(data, useType, isDeleteEmptyItem) {
	if (useType == 'inner_use'){
		if (data && Array.isArray(data.rows) && data.rows.length) {
			let newData = {
				...data,
				rows: []
			}
			data.rows.forEach((item) => {
				if (item.values) {
					let newValues = {};
					for (let pop in item.values) {
						if (!isEmpty(item.values[pop].value)) {
							// 后台转化可能增加效率
							newValues[pop] = { value: item.values[pop].value }
						} else if(isDeleteEmptyItem === false) {
							newValues[pop] = {};
						}
					}
					newData.rows.push({
						...item,
						values: newValues
					});
				}
			});
			return newData;
		}
		return data;
	}else{
		deleteEmptyData(data);
	}
		
}

/**
 * 
 * @param {*Object} data 
 */
// 将为空的字段补全display,value.scale
export function addEmptyData(data) {
	if (data && Array.isArray(data.rows) && data.rows.length) {
		data.rows.map((item) => {
			if (item.values) {
				for (let pop in item.values) {
					if (!item.values[pop].hasOwnProperty('display')) {
						item.values[pop].display = null;
					}
					if (!item.values[pop].hasOwnProperty('value')) {
						item.values[pop].value = null;
					}
					if (!item.values[pop].hasOwnProperty('scale')) {
						item.values[pop].scale = -1;
					}
				}
			}
			return item;
		});
	}
	return data;
}

// 供应链用删除display、scale
/**
 * @param {*Object} data 
 * {
 * 		rows:[{
 * 			values:{}
 * 		},
 * 		{
 * 			values:{}
 * 		}]
 * }
 */
export function deleteDataByValue(data) {
	if (data && Array.isArray(data.rows) && data.rows.length) {
		let newData = {
			...data,
			rows: []
		}
		data.rows.forEach((item) => {
			if (item.values) {
				let newValues = {};
				for (let pop in item.values) {
					if (!isEmpty(item.values[pop].value)) {
						// 后台转化可能增加效率
						newValues[pop] = { value: item.values[pop].value }
					} else {
						newValues[pop] = {};
					}
				}
				newData.rows.push({
					...item,
					values: newValues
				});
			}
		});
		return newData;
	}
	return data;	
}
