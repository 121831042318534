import './init';
import store, { registerNameSpace } from './store';
import { fromJS } from 'immutable';
const { registerAction, dispatch } = registerNameSpace('meta');

registerAction({
    getMeta(state) {
        return state.toJS();
    },
    setMeta(state, meta) {
        dispatch(fromJS(meta));
    },
});

export * from './api';
export * from './decorator';
export * from './store';
export * from './component';
export { connect } from 'react-redux';
export { store };
export { default as immutable } from 'immutable';
