/*
 * @Author: liyxt
 * @Date: 2020-05-28 09:45:19
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-01 14:27:47
 * @Description: file content
 */ 
import Gzip from './gzip';
import Cipher, { opaqueDecrypt } from './cipher';

import { getGlobalStorage } from './storage';
import { getCookie } from './cookie';

function isJSON(str) {
    if (typeof str == 'string') {
        try {
            var obj = JSON.parse(str);
            if (typeof obj == 'object' && obj) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.log('error：' + str + '!!!' + e);
            return false;
        }
    }
    console.log('It is not a string!');
}

export function unZipAndDecrypt(result, cookiets) {
    const gzip = new Gzip();
    // 此时的cookie是当前界面唯一 如果放到ajax内部  
    // 多个请求的情况下 会导致前面的cookie被覆盖  所有请求用的是第二次的cookie  而加密用的cookie是第一次的
    let cckk = cookiets || (top && top.cookiets) || getCookie('cookiets') || Date.now(); //双cookie验证 liuxis
    // 做一次 数据转型
    cckk = isNaN(cckk) ? cckk : Number(cckk);
    // 加密 bbqin
    let cipherFlag = getGlobalStorage('localStorage', 'rockin');
    let aeskey = '';
    let cckks = cckk + '';
    aeskey = opaqueDecrypt(getGlobalStorage('localStorage', 'cowboy'));
    aeskey = cckks + aeskey.substring(0, aeskey.length - cckks.length);
    // 将是否加密缓存到组件单例上
    Cipher.CipherFlag = cipherFlag === 'true';
    if (result) {
        console.log(cckk, aeskey, result, Cipher);
        result = (typeof result === 'string') ? Cipher.decrypt(result, aeskey) : result;
        let gzipFlag = getGlobalStorage('localStorage', 'gzip');
        //判断数据是否启用压缩
        if (Number(gzipFlag) === 1) {
            result = gzip.unzip(result);
        }
        return isJSON(result) ? JSON.parse(result) : result;
    } else {
        return null;
    }
}
