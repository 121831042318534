import ajax from './ajax';
import { toast } from './toast';
import { clearToast } from './toast';
import { getMultiLang } from './getMultiLang';
import CONFIG from './config';
import { getAppUrl, setAppUrl } from './appUrlUtils';
import { getCookie, setCookie } from './cookie';
import { getSafeRandom } from './utils'

// // 判断浏览器是否为IE
// const isIE = (
//     navigator.userAgent.indexOf("MSIE")!=-1 || !!window.ActiveXObject || "ActiveXObject" in window
// )

// self===top true 说明是顶层

/**
 * 跳转检查 （ 占用 license ）
 * 调用此方法去修改URL地址时需要encodeURIComponent两次
 * 获取URL参数时需要decodeURIComponent两次
 * @param {String|undefined|null} appcode - 应用编码 值为""/undefined/null 则不会校验权限  需要工作台容器
 * @param {String|undefined|null} pagecode - 页面编码编码 值为""/undefined/null 则返回应用默认页面
 * @param {Function} callback - 检查之后的回调
 * @param {String} tab - 新页签
 */
function topOpenCheck(appcode, pagecode, callback, type) {
    let win = window;
    let href = window.location.href;
    let cckk = (top && top.cookiets) || getCookie('cookiets') || Date.now(); //双cookie验证 liuxis
    // 做一次 数据转型
    cckk = isNaN(cckk) ? cckk : Number(cckk);
    setCookie('cookiets', cckk);
    top && (top.cookiets = cckk);
    // 需要校验权限
    if (appcode && appcode.length) {
        window.actionName = '权限校验';
        ajax({
            url: `/nccloud/platform/appregister/openapp.do`,
            // sysParameter: {
            //     name: '工作桌面', //'工作桌面',
            //     action: '权限校验', //'权限校验'
            //     appcode: appcode,
            //     ts: new Date().getTime(),
            //     pagecs: cckk
            // },
            data: {
                appcode,
                pagecode,
            },
            sysAppcode: 10228888,
            async: false,
            success: ({ data }) => {
                callback(win, { data: data }, newUrl => {
                    if (type === "tab" && newUrl) {
                        openNewTabs(newUrl)
                    }
                });
                win = null;
            },
        });
    } else {
        // 不需要校验跳转权限且需要工作台容器
        /**
         * 校验回调
         * @param {Object} win - 窗口对象
         */
        callback(win);
        win = null;
    }
}

/**
 * 跳转检查 （不占用 license）
 * 参数及注意 同 window.openCheck 方法
 */
function topSpecialOpenCheck(appcode, pagecode, callback, type) {
    let win = window;
    // 需要校验权限
    if (appcode && appcode.length) {
        window.actionName = '权限校验';
        ajax({
            url: `/nccloud/platform/appregister/openapp2.do`,
            // sysParameter: {
            //     name: '工作桌面', //工作桌面
            //     action: '权限校验' //权限校验
            // },
            data: {
                appcode,
                pagecode,
            },
            sysAppcode: 10228888,
            async: false,
            success: ({ data }) => {
                // if (data && !data.is_haspower) {
                //     // Notice({
                //     //     status: "error",
                //     //     msg: data.data.hint_message
                //     // });
                //     return;
                // }
                // 设置当前打开小应用的图标 供业务单据使用
                // appIconSet(appcode,data.data.image_src);
                // 应用菜单名
                // window.peData.nodeName = data.data.menu;
                // // 应用编码
                // window.peData.nodeCode = appcode;
                // // 用户id 由于需要知道具体哪个人所以将userid字段改成userCode 此处需要注意
                // window.peData.userID = this.props.userCode;
                // // 项目编码
                // window.peData.projectCode = this.props.projectCode;
                // // pe 地址
                // window.peData.nlinkUrl = this.props.nlinkUrl;
                /**
                 * 校验回调
                 * @param {Object} win - 窗口对象
                 * @param {Object} data - 检验之后的参数
                 */
                // window.proxyAction(
                //     callback,
                //     this,
                //     "0000PUB-000088"//langCheck("0000PUB-000088")
                // )(win, data); //打开应用
                callback(win, { data: data }, newUrl => {
                    if (type === "tab" && newUrl) {
                        //window.open(newUrl, "_blank");
                        openNewTabs(newUrl);
                    }
                })
                win = null;
            },
        });
    } else {
        // 不需要校验跳转权限且需要工作台容器
        /**
         * 校验回调
         * @param {Object} win - 窗口对象
         */
        callback(win);
        win = null;
    }
}


function openNewTabs(newUrl) {
    if (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
        let a = document.createElement('a');
        a.setAttribute('href', newUrl);
        a.setAttribute('target', '_blank');
        a.setAttribute('id', 'hiddenOpenToElement');
        a.setAttribute('hidden', true);
        // 防止反复添加
        if (document.getElementById('hiddenOpenToElement')) {
            document.body.removeChild(document.getElementById('hiddenOpenToElement'));
        }
        document.body.appendChild(a);
        a.click();
    } else {
        window.open(newUrl, "_blank");
    }
}



//校验回调
/**
 * 
 * @param {*object} win 打开页签window
 * @param {*} res 后台返回应用信息
 * @param {*} path 业务传递url
 * @param {*} appcode 
 * @param {*} pagecode 
 * @param {*} type  打开方式
 * @param {*} oldWinHash  原来的参数
 * @param {*} params 业务传新参数
 * @param {*} callback 
 * @param {*} parentParam 业务传给父window参数  作为上下文参数，传给ajax系统参数使用，如 共享的 custom
 */
function checkCallBack(win, res, path, appcode, pagecode, type, oldWinHash, params = {}, callback, parentParam, openWinCallBack) {
    let resParam = res.data;
    let appTitleName = '';
    if (resParam) {
        //修改win的url参数
        let param = '';
        let openNoParamFlag = !oldWinHash.includes('ifr=') && (type === "open" || type === "link");

        // openTo 场景下，若无 ifr 参数，直接根据返回参数打开
        if (oldWinHash.includes('?') && !openNoParamFlag) {
            param = oldWinHash.split('?')[1];
        }

        if (param) {
            //有参数
            let paramArr = param.split('&');
            let metacode = pagecode || resParam.pagecode;
            if (paramArr && paramArr instanceof Array && paramArr.length > 0) {
                let newParamArr = paramArr.map(item => {
                    if (item.indexOf('=') != -1 && item.split('=') && item.split('=') instanceof Array) {
                        if (item.split('=')[0] === 'ifr') {
                            if (path) {
                                item = `ifr=${encodeURIComponent(encodeURIComponent(path))}`;
                            } else if (resParam.pageurl) {
                                let newPath = getResPageUrl(resParam.pageurl, params);
                                item = `ifr=${encodeURIComponent(encodeURIComponent(newPath))}`;
                            }
                        } else if (item.split('=')[0] === 'p' && metacode) {
                            item = `p=${encodeURIComponent(encodeURIComponent(metacode))}`;
                        } else if (item.split('=')[0] === 'n') {
                            //修改面包屑数据
                            let menu = resParam.menu;
                            if (params.nccloud_menu_name_self) {
                                menu = params.nccloud_menu_name_self;
                            }
                            if (menu) {
                                appTitleName = menu;
                                item = `n=${encodeURIComponent(encodeURIComponent(menu))}`;
                            }
                        } else if (item.split('=')[0] === 'b1' && resParam.field) {
                            item = `b1=${encodeURIComponent(encodeURIComponent(resParam.field))}`;
                        } else if (item.split('=')[0] === 'b2' && resParam.module) {
                            item = `b2=${encodeURIComponent(encodeURIComponent(resParam.module))}`;
                        } else if (item.split('=')[0] === 'b3' && resParam.menuclass) {
                            item = `b3=${encodeURIComponent(encodeURIComponent(resParam.menuclass))}`;
                        } else if (item.split('=')[0] === 'c' && appcode) {
                            item = `c=${encodeURIComponent(encodeURIComponent(appcode))}`;
                        } else if (item.split('=')[0] === 'ar' && resParam.appid) {
                            // ar  待定key名
                            item = `ar=${encodeURIComponent(encodeURIComponent(resParam.appid))}`;
                        }
                        return item;
                    }
                });

                let custom = getSearchParam("custom");
                if (parentParam && Object.prototype.toString.call(parentParam) === "[object Object]") {
                    custom = JSON.stringify(parentParam);
                }
                if (custom) {
                    newParamArr.push(`custom=${encodeURIComponent(encodeURIComponent(custom))}`);
                }
                let newParam = newParamArr.join('&');

                //修改工作台路径
                if (
                    (appcode === '102202APP' ||
                        appcode === '102202MENU' ||
                        appcode === '1022PREGI' ||
                        appcode === '10180TM' ||
                        appcode === '10181TM' ||
                        appcode === '101818AM') &&
                    resParam.pageurl
                ) {
                    let winParamObj = {
                        p: metacode,
                        n: resParam.menu,
                        b1: resParam.field,
                        b2: resParam.module,
                        b3: resParam.menuclass,
                        c: appcode,
                        ar: resParam.appid,
                        ...params,
                    };
                    appTitleName = resParam.menu;
                    let winParamStr = getHashValue(winParamObj, true);
                    if (win) {
                        if ((win === top || type === "open") && CONFIG.isPageModal) {
                            let windowTabId = randomNumber();
                            setAppUrl(windowTabId, winParamStr);
                            openWinCallBack(`#/${resParam.pageurl}?page=${windowTabId}`);
                        }
                        // 参数放url
                        else {
                            win.location = `#/${resParam.pageurl}?${winParamStr}`;
                        }
                    } else {
                        let getJsonCallback = json => {
                            toast({
                                color: 'warning',
                                title: json["api-app-pageTo-0001"],
                                content: json["api-app-pageTo-0002"],
                            });
                        }
                        getMultiLang({ moduleId: "api_app_pageTo", callback: getJsonCallback });
                    }
                } else {
                    if (win) {
                        let windowTabId = randomNumber();
                        if (type === 'link' || type === 'router') {
                            let topUrl = getAppUrl().pageurl;
                            let currentUrl = window.location.href;

                            if(type === 'router'){
                                windowTabId = getPageId(true);
                            }
                            if (win === top && CONFIG.isPageModal) {
                                top.location.hash = `#/ifr?page=${windowTabId}`;
                                setAppUrl(windowTabId, newParam);
                                // if (type === 'router') {
                                //     //isIE && window.location.reload();
                                //     //top.document.title = appTitleName;
                                // }
                            }
                            else {
                                win.location.hash = `#/ifr?${newParam}`
                            }
                            //处理浏览器回退以后，linkTo修改地址后，页面未刷新问题
                            if (type === 'link') {
                                backLink(topUrl, currentUrl, path || resParam.pageurl);
                            }
                        } else {
                            if (CONFIG.isPageModal) {
                                let newPage = `#/ifr?page=${windowTabId}`;
                                if (CONFIG.isRemoveWorkbench) {
                                    newParam = resParam.pageurl + '#/?' + newParam;
                                    newPage = `${resParam.pageurl}#/?page=${windowTabId}`
                                }
                                setAppUrl(windowTabId, newParam);
                                openWinCallBack(newPage);
                            } else {
                                let newPage = `#/ifr?${newParam}`;
                                if (CONFIG.isRemoveWorkbench) {
                                    newPage = `${resParam.pageurl}#/?${newParam}`;
                                }
                                openWinCallBack(newPage);
                            }
                        }
                        if (type === 'router' && typeof callback === 'function') {
                            callback();
                        }
                    } else {
                        let getJsonCallback = json => {
                            toast({
                                color: 'warning',
                                title: json["api-app-pageTo-0001"],
                                content: json["api-app-pageTo-0002"],
                            });
                        }
                        getMultiLang({ moduleId: "api_app_pageTo", callback: getJsonCallback });
                        //toast({ color: 'warning', title: '请注意', content: '您阻止了打开新页签，请检查设置' });
                    }
                }
            }
        } else {
            //无参数
            let metacode = pagecode || resParam.pagecode;
            let newParamsObj = {
                ifr: path || getResPageUrl(resParam.pageurl, params),
                p: metacode,
                n: resParam.menu,
                b1: resParam.field,
                b2: resParam.module,
                b3: resParam.menuclass,
                c: appcode,
                ar: resParam.appid,

            };
            appTitleName = resParam.menu;
            let urlParams = getHashValue(newParamsObj, true);
            //修改工作台路径
            if (
                (appcode === '102202APP' ||
                    appcode === '102202MENU' ||
                    appcode === '1022PREGI' ||
                    appcode === '10180TM' ||
                    appcode === '10181TM' ||
                    appcode === '101818AM') &&
                resParam.pageurl
            ) {
                let paramObj = {
                    p: metacode,
                    n: resParam.menu,
                    b1: resParam.field,
                    b2: resParam.module,
                    b3: resParam.menuclass,
                    c: appcode,
                    ar: resParam.appid,
                    ...params,
                };
                appTitleName = resParam.menu;
                let paramStr = getHashValue(paramObj, true);
                if (win) {
                    if ((win === top || type === "open") && CONFIG.isPageModal) {
                        let windowTabId = randomNumber();
                        setAppUrl(windowTabId, paramStr);
                        openWinCallBack(`#/${resParam.pageurl}?page=${windowTabId}`);
                    }
                    else {
                        win.location = `#/${resParam.pageurl}?${paramStr}`;
                    }
                } else {
                    let getJsonCallback = json => {
                        toast({
                            color: 'warning',
                            title: json["api-app-pageTo-0001"],
                            content: json["api-app-pageTo-0002"],
                        });
                    }
                    getMultiLang({ moduleId: "api_app_pageTo", callback: getJsonCallback });
                    //toast({ color: 'warning', title: '请注意', content: '您阻止了打开新页签，请检查设置' });
                }
            } else {
                if (win) {
                    let windowTabId = randomNumber();
                    if (type === 'link' || type === 'router') {
                        let topUrl = getAppUrl().pageurl;
                        let currentUrl = window.location.href;
                        // url参数放sessionStorage
                        if (win === top && CONFIG.isPageModal) {
                            if(type === 'router'){
                                windowTabId = getPageId(true);
                            }
                            if (CONFIG.isRemoveWorkbench) {
                                top.location = `${resParam.pageurl}#/?page=${windowTabId}`;
                                urlParams = `${resParam.pageurl}#/${urlParams}?`
                            } else {
                                top.location.hash = `#/ifr?page=${windowTabId}`;
                            }
                            setAppUrl(windowTabId, urlParams);
                            // if (type === 'router') {
                            //     //isIE && window.location.reload()
                            //     //top.document.title= appTitleName;
                            // }
                        }
                        // url参数放地址栏
                        else {
                            if (CONFIG.isRemoveWorkbench) {
                                win.location = `${resParam.pageurl}#/?${urlParams}`
                            } else {
                                win.location.hash = `#/ifr?${urlParams}`
                            }
                        }
                        //处理浏览器回退以后，linkTo修改地址后，页面未刷新问题
                        if (type === 'link') {
                            backLink(topUrl, currentUrl, path || resParam.pageurl);
                        }
                    } else {
                        if (CONFIG.isPageModal) {
                            let newPage = `#/ifr?page=${windowTabId}`;
                            if (CONFIG.isRemoveWorkbench) {
                                urlParams = resParam.pageurl + '#/?' + urlParams;
                                newPage = `${resParam.pageurl}#/?page=${windowTabId}`
                            }
                            setAppUrl(windowTabId, urlParams);
                            openWinCallBack(newPage);
                        } else {
                            let newPage = `#/ifr?${urlParams}`;
                            if (CONFIG.isRemoveWorkbench) {
                                newPage = `${resParam.pageurl}#/?${urlParams}`;
                            }
                            openWinCallBack(newPage);
                        }
                    }
                    if (type === 'router' && typeof callback === 'function') {
                        callback();
                    }
                } else {
                    let getJsonCallback = json => {
                        toast({
                            color: 'warning',
                            title: json["api-app-pageTo-0001"],
                            content: json["api-app-pageTo-0002"],
                        });
                    }
                    getMultiLang({ moduleId: "api_app_pageTo", callback: getJsonCallback });
                    //toast({ color: 'warning', title: '请注意', content: '您阻止了打开新页签，请检查设置' });
                }
            }
        }
    }
}

// 处理 linkTo 场景下：用户点了浏览器后退后，再点linkTo跳转详情界面，详情不更新的问题
// 点浏览器后退，只回退了 iframe 未回退主页面的history;
function backLink(topUrl, currentUrl, newPage) {
    if (newPage) {
        if (newPage.includes("#")) newPage = newPage.split("#")[0];
        if (newPage.includes("?")) newPage = newPage.split("?")[0];
    }
    topUrl = decodeURIComponent(decodeURIComponent(topUrl));
    if (topUrl.includes(newPage) && !currentUrl.includes(newPage)) {
        window.top.location.reload();
    }
}

//跳转页面
function linkTo(page, params, hashChangeCallBack) {
    clearToast();
    to(page, params, 'link', hashChangeCallBack);
}

/**
 * 新页签打开节点
 * @param {*string} page 跳转url
 * @param {*object} params 跳转参数
 * @param {*string} parentParam 跳转往window上加参数 ,作为上下文参数，传给ajax系统参数使用，如 共享的 custom
 */
function openTo(page, params, parentParam) {
    to(page, params, 'open', null, false, null, parentParam);
}

function pushTo(page, params, hashChangeCallBack) {
    clearToast();
    ViewModel.setData('nccloud-router-params', params || {});
    if (hashChangeCallBack && typeof hashChangeCallBack === "function") {
        hashChangeCallBack(getHashValue(params))
    }
    to(page, params, 'route');
}

/**
 * 查找resources加载的文件路径里某一层级的文件名
 * n:Number 在resources的第几层
 */
function getUrlPathName(n) {
    //let hash = decodeURIComponent(decodeURIComponent(window.top.location.hash));
    let hash = "";
    if (CONFIG.isPageModal) {
        let pageStore = getAppUrl().pageurl;
        hash = decodeURIComponent(decodeURIComponent(pageStore));
    } else {
        hash = decodeURIComponent(decodeURIComponent(window.top.location.hash));
    }

    let urlPathArr = hash.split("/");
    let name = "";
    name = urlPathArr[n];
    return name;
}

//获取领域名
function getDomainCode() {
    return getUrlPathName(1);
}

//获取模块名
function getModuleCode() {
    return getUrlPathName(2);
}

//返回跳转
function linkBack() {
    history.go(-1);
}

//打开nccloud以外的应用
function openOut(page, params) {
    let callback = json => {
        let newPage = page;
        let getHashVals = '';
        if (params) {
            let pageParms = mergeHashVal(page, params);
            getHashVals = pageParms.hash;
            newPage = pageParms.page;
        }
        let url = newPage + (getHashVals ? '?' + getHashVals : '')
        let winObj = window.open(url, "_blank");

        if (!winObj) {
            toast({
                color: 'warning',
                title: json["api-app-pageTo-0001"],
                content: json["api-app-pageTo-0002"],
            });
        }
    }
    getMultiLang({ moduleId: "api_app_pageTo", callback });
}

//将参数拼接到url上
function getHashValue(params, encode) {
    let hashVal = '';
    if (params && Object.keys(params).length) {
        let paramsArr = Object.keys(params);
        let len = paramsArr.length;
        for (let i = 0; i < len; i++) {
            let pop = paramsArr[i];
            if (params[pop]) {
                if (i < len - 1) {
                    if (encode) {
                        hashVal += `${pop}=${encodeURIComponent(encodeURIComponent(params[pop]))}&`;
                    } else {
                        hashVal += `${pop}=${params[pop]}&`;
                    }
                } else {
                    if (encode) {
                        hashVal += `${pop}=${encodeURIComponent(encodeURIComponent(params[pop]))}`;
                    } else {
                        hashVal += `${pop}=${params[pop]}`;
                    }
                }
            }
        }
    }
    return hashVal;
}

function getPageId(currentPageId){
    if(currentPageId){
        return getAppUrl().pageid;
    }else{
        return randomNumber();
    }
}

function randomNumber() {
    const now = new Date()
    let month = now.getMonth() + 1
    let day = now.getDate()
    let hour = now.getHours()
    let minutes = now.getMinutes()
    let seconds = now.getSeconds()
    return now.getFullYear().toString() + month.toString() + day + hour + minutes + seconds + (Math.round(getSafeRandom() * 23 + 100)).toString()
}

//跳转
/**
 * 
 * @param {*} page //跳转节点
 * @param {*} params //跳转参数
 * @param {*string} type //类型 open-新页签，link-当前页签-非单页应用 router当前页签-单页应用
 * @param {*} hashChangeCallBack //hash参数改变的回调函数--共享服务用
 * @param {*boolean} special//是否占用license 默认是false
 * @param {*function} getWinObj//openTo时获取打开页签的window对象
 * @param {*string} parentParam//打开节点传递的参数,作为上下文参数，传给ajax系统参数使用，如 共享的 custom
 */
function to(page, params = {}, type, hashChangeCallBack, special = false, getWinObj, parentParam) {
    if (page) {
        //
        if (type == 'route') {
            let origin = window.location.origin;
            if (!origin) {
                origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
            }
            let ifr = window.location.href.replace(origin, '');
            //let hashValue = getHashValue(params);
            let hashValue = '';
            if (ifr.includes('#')) {
                ifr = ifr.split('#')[0];
            } else if (ifr.includes('?')) {
                ifr = ifr.split('?')[0];
            }
            if (ifr) {
                if (!ifr.includes("index.html")) {
                    ifr = ifr + 'index.html';
                }
                ifr = ifr + '#' + page;
                let lastpage = ifr;
                let env = 'production';
                if (window.top && window.top.environmentmodel) {
                    env = window.top.environmentmodel;
                }
                let includeFlag = /^(\/nccloud|nccloud)\/resources*/.test(lastpage);
                let path =
                    (
                        (env === 'development' || includeFlag) ?
                            '' :
                            (lastpage[0] == '/' ? '/nccloud/resources' : '/nccloud/resources/')
                    ) + lastpage + (hashValue ? ('?' + hashValue) : "");
                if (params.appcode || params.billtype) {
                    checkNode(path, params, 'router', () => {
                        window.location.hash = page + (hashValue ? ('?' + hashValue) : "");
                    });
                } else {
                    let param = '';
                    if (window.parent === top && CONFIG.isPageModal) {
                        param = getAppUrl().pageurl;
                    }
                    else {
                        param = window.parent.location.hash.split('?')[1];
                    }

                    let Arr = param && param.split('&');
                    if (Arr && Arr instanceof Array && Arr.length > 0) {
                        let newArr = Arr.map(item => {
                            if (item.indexOf('=') != -1 && item.split('=') && item.split('=') instanceof Array) {
                                if (item.split('=')[0] === 'ifr' && path) {
                                    item = `ifr=${encodeURIComponent(encodeURIComponent(path))}`;
                                }
                                if (item.split('=')[0] === 'p' && params.pagecode) {
                                    item = `p=${encodeURIComponent(encodeURIComponent(params.pagecode))}`;
                                }
                                return item;
                            }
                        });

                        let newParams = newArr.join('&');

                        if (CONFIG.isRemoveWorkbench) {//去掉workbench打开       
                            if (window.parent === top && CONFIG.isPageModal) { //放session
                                let windowTabId = randomNumber();
                                window.parent.location.hash = page + `?page=${windowTabId}`;
                                let resPage = path + '?' + (hashValue ? (hashValue + newParams) : newParams);
                                setAppUrl(windowTabId, resPage);
                            } else {//不放session
                                let resHash = page + '?' + (hashValue ? (hashValue + newParams) : newParams);
                                window.location.hash = resHash;
                            }
                        } else { //带着workbench打开
                            if (window.parent === top && CONFIG.isPageModal) {
                                let windowTabId = getPageId(true);
                                window.parent.location.hash = `#/ifr?page=${windowTabId}`;
                                setAppUrl(windowTabId, newParams);
                                // if (type === 'router') {
                                //     //isIE && window.location.reload()
                                // }
                            }
                            else {
                                window.parent.location.hash = `#/ifr?${newParams}`;
                            }
                            window.location.hash = page + (hashValue ? ('?' + hashValue) : "");
                        }

                    }

                }

            }
            return;
        }
        //
        let flag = page[0] === '/';
        let currentUrl = window.location.href;
        let newPageParams = mergeHashVal(page, params);
        let hashVal = newPageParams.hash;
        page = newPageParams.page;

        //判断有无 index.html
        let nextPage = page;
        if (page.includes("#")) {
            if (!(page.split("#")[0].includes("index.html"))) {
                nextPage = page.split("#")[0] + 'index.html' + '#' + (page.split("#")[1] ? page.split("#")[1] : '')
            } else {
                nextPage = page;
            }
        } else {
            if (!page.includes("index.html")) {
                nextPage = page + 'index.html';
            }
        }
        page = nextPage;

        let env = 'production';
        if (window.top && window.top.environmentmodel) {
            env = window.top.environmentmodel;
        }
        let includeFlag = /^(\/nccloud|nccloud)\/resources*/.test(page);
        if (type === 'link' && currentUrl.includes(page) && hashVal) {
            window.location.hash = hashVal;
            if (params.appcode) {
                let path = '';
                if (page.includes("#")) {
                    path = (
                        (env === 'development' || includeFlag) ?
                            '' :
                            (page[0] === '/' ? '/nccloud/resources' : '/nccloud/resources/')
                    ) + page + '?' + hashVal;
                } else {
                    path = (
                        (env === 'development' || includeFlag) ?
                            '' :
                            (page[0] === '/' ? '/nccloud/resources' : '/nccloud/resources/')
                    ) +
                        page + '#/?' + hashVal;
                }

                checkNode(path, params, type, null, special, getWinObj, parentParam);
            } else {
                setParentIfr(page, hashVal);
            }
        } else {
            if (flag) {
                let path = '';
                if (page.includes("#")) {
                    path = (
                        (env === 'development' || includeFlag) ?
                            '' : (page[0] === '/' ? '/nccloud/resources' : '/nccloud/resources/')
                    ) + page + '?' + hashVal;
                } else {
                    path = (
                        (env === 'development' || includeFlag) ?
                            '' : (page[0] === '/' ? '/nccloud/resources' : '/nccloud/resources/')
                    ) + page + '#/?' + hashVal;
                }

                checkNode(path, params, type, null, special, getWinObj, parentParam);
            } else {
                // 相对路径
                //let path = page + (hashVal ? '#' + hashVal : '');
                //------------ # 改 ？
                //let path = page + (hashVal ? '?' + hashVal : '');
                let path = '';
                if (page.includes("#")) {
                    path = page + (hashVal ? '?' + hashVal : '');
                } else {
                    path = page + (hashVal ? '#/?' + hashVal : '');
                }
                checkNode(path, params, type, null, special, getWinObj, parentParam);
            }
        }
        //共享hashChange回调
        if (hashChangeCallBack && typeof hashChangeCallBack == "function") {
            hashChangeCallBack(hashVal)
        }
    } else {
        checkNode(null, params, type, null, special, getWinObj, parentParam);
    }
}

//获取父window参数
function getSearchParam(pop) {
    if (!pop) return;

    let code = '';
    let path = window.parent.location.hash || window.parent.location.search;

    //--------------- 此处为改url参数代码---------------------//
    if (window.parent === top && CONFIG.isPageModal) {
        path = '?' + getAppUrl().pageurl;
    }
    //--------------- 此处为改url参数代码---------------------//

    //否则：按照原来方式走
    let appSearch = '';
    if (path.includes('?')) {
        appSearch = path.split('?')[1];
    } else {
        appSearch = path.substring(1);
    }
    if (appSearch) {
        let appPrams = appSearch.split('&');
        if (appPrams && appPrams instanceof Array) {
            appPrams.forEach(item => {
                if (item.indexOf('=') != -1 && item.split('=') && item.split('=') instanceof Array) {
                    if (item.split('=')[0] === pop) {
                        if (item.split('=')[1]) {
                            code = decodeURIComponent(decodeURIComponent(item.split('=')[1]));
                        }
                    }
                }
            });
        }
    }
    return code;
}

function setPageCode(pagecode) {
    if (!pagecode) return;
    let path = window.parent.location.hash;
    let pageid = '';
    if (window.parent === top && CONFIG.isPageModal) {
        path = getAppUrl().pageurl;
        pageid = getAppUrl().pageid;
    }
    let paramArr = path.split("&");
    let newParam = paramArr.map(e => {
        if (e.includes("=") && e.split("=")[0] === 'c') {
            return `c=${pagecode}`;
        }
        return e;
    })
    let newPath = newParam.join("&");

    if (window.parent === top && CONFIG.isPageModal) {
        setAppUrl(pageid, newPath);
    } else {
        window.parent.location.hash = newPath;
    }

}

function getAppCode() {
    return getSearchParam("c");
}

function getPageCode() {
    return getSearchParam("p");
}

//校验节点权限并修改父窗口url参数
function checkNode(path, params = {}, type, callback, special, getWinObj, parentParam) {
    let tab = '';
    if (type === 'open') {
        tab = 'tab';
    }
    let appcode = params && params.appcode;
    let urlAppcode = getSearchParam('c');
    let pagecode = (params && params.pagecode) || '';
    //去掉 workbench 时使用 topOpenCheck,  否则带着workbench模式使用window.top.openCheck
    //若window.top.openCheck不存在，为了不报错 使用topOpenCheck
    let openCheck = CONFIG.isRemoveWorkbench ? topOpenCheck : window.top.openCheck || topOpenCheck;
    if (special) {
        openCheck = CONFIG.isRemoveWorkbench ? topSpecialOpenCheck : window.top.specialOpenCheck || topSpecialOpenCheck;
    }

    let oldWinHash = '';
    if ((type === 'open' || window.parent === top) && CONFIG.isPageModal) {
        let oldUrl = getAppUrl().pageurl;
        oldWinHash = CONFIG.isRemoveWorkbench ? oldUrl : (oldUrl.includes('?') ? oldUrl : ('?' + oldUrl));
    } else {
        oldWinHash = window.parent.location.hash;
    }

    if (appcode) {
        openCheck(
            appcode,
            pagecode,
            (win, res, openWinCallBack) => {
                checkCallBack(
                    win,
                    res,
                    path,
                    appcode,
                    pagecode,
                    type,
                    oldWinHash,
                    params,
                    callback,
                    parentParam,
                    openWinCallBack,
                );
                //业务监听新页签window
                if (special && typeof getWinObj === 'function') {
                    getWinObj(win);
                }
            },
            tab,
        );
    } else if (params && params.billtype) {
        //通过billtype查appcode
        let sencetype = '1';
        window.actionName = '请求url参数';
        ajax({
            url: '/nccloud/platform/pub/getappurl.do',
            method: 'post',
            data: {
                billpk: null,
                billtypecode: params.billtype,
                pagecode: pagecode || null,
                sence: params.sence || sencetype,
            },
            success: result => {
                //查到以后校验
                if (result.success && result.data && result.data.appPageVO) {
                    let resurl = result.data.appPageVO.pageurl.replace("nccloud/resources/", "");
                    let resPageUrl = getResPageUrl(resurl, params);
                    openCheck(
                        result.data.appcode,
                        pagecode || result.data.appPageVO.pagecode,
                        (win, res, openWinCallBack) => {
                            checkCallBack(
                                win,
                                res,
                                resPageUrl,
                                result.data.appcode,
                                pagecode || result.data.appPageVO.pagecode,
                                type,
                                oldWinHash,
                                params,
                                callback,
                                parentParam,
                                openWinCallBack,
                            );
                            //业务监听新页签window
                            if (special && typeof getWinObj === 'function') {
                                getWinObj(win);
                            }
                        },
                        tab,
                    );
                } else {
                    console.log('appcode请求失败');
                }
            },
        });
    } else if (urlAppcode) {
        openCheck(
            urlAppcode,
            pagecode,
            (win, res, openWinCallBack) => {
                checkCallBack(
                    win,
                    res,
                    path,
                    urlAppcode,
                    pagecode,
                    type,
                    oldWinHash,
                    params,
                    callback,
                    parentParam,
                    openWinCallBack,
                );
                //业务监听新页签window
                if (special && typeof getWinObj === 'function') {
                    getWinObj(win);
                }
            },
            tab,
        );
    } else {
        // 无appcode
        console.log('请传入appcode或billtype');
    }
}

//新参数 覆盖旧参数
function mergeHashVal(pageurl, params = {}) {
    let getHashVals = '';
    let hashArr = [];
    let urlParm = '';
    let newPage = pageurl;
    if (pageurl.includes('?')) {
        urlParm = pageurl.split('?')[1];
        newPage = pageurl.split('?')[0];
    }
    // else if (pageurl.includes('#')) {
    // 	urlParm = pageurl.split('#')[1];
    // 	newPage = pageurl.split('#')[0];
    // }
    let hasItemFlag = {};
    if (urlParm) {
        let paramsVal = urlParm.split('&');
        if (paramsVal && paramsVal instanceof Array && paramsVal.length > 0) {
            hashArr = paramsVal.map(elem => {
                if (elem.indexOf('=') != -1 && elem.split('=') && elem.split('=') instanceof Array) {
                    if (params && params[elem.split('=')[0]]) {
                        hasItemFlag[`${elem.split('=')[0]}`] = true;
                        elem = `${elem.split('=')[0]}=${params[elem.split('=')[0]]}`;
                    }
                }
                return elem;
            });
            for (let pop in params) {
                if (!hasItemFlag[pop]) {
                    hashArr.push(`${pop}=${params[pop]}`);
                }

            }
            getHashVals = hashArr.join('&');
        }
    } else {
        getHashVals = getHashValue(params);
    }
    return {
        hash: getHashVals,
        page: newPage,
    };
}

//分离出url
function getResPageUrl(pageurl, params = {}) {
    let newPath = '';
    if (pageurl) {
        let pageParms = mergeHashVal(pageurl, params);
        let getHashVals = pageParms.hash;
        let newPage = pageParms.page;

        //let env = CookieTools.get('environmentModel')||'development';
        let env = 'production';
        if (window.top && window.top.environmentmodel) {
            env = window.top.environmentmodel;
        }
        let includeFlag = /^(\/nccloud|nccloud)\/resources*/.test(newPage);
        if (newPage.includes("#")) {
            newPath =
                (
                    (env === 'development' || includeFlag) ?
                        '' : (newPage[0] === '/' ? '/nccloud/resources' : '/nccloud/resources/')
                ) + newPage + (getHashVals ? '?' + getHashVals : '');
        } else {
            newPath =
                ((env === 'development' || includeFlag) ? '' : (newPage[0] === '/' ? '/nccloud/resources' : '/nccloud/resources/')) +
                newPage +
                (getHashVals ? '#/?' + getHashVals : '');
        }

    }
    return newPath;
}

function specialOpenTo(page, params, getWinObj, parentParam) {
    //special 为不占用license}
    to(page, params, 'open', null, true, getWinObj, parentParam);
}

function specialLinkTo(page, params, getWinObj, parentParam) {
    //special 为不占用license
    to(page, params, 'link', null, true, getWinObj, parentParam);
}

function specialOpenToOut(page, params, getWinObj, parentParam) {
    let hashVal = '';
    if (params) {
        let pageParms = mergeHashVal(page, params);
        hashVal = pageParms.hash;
        page = pageParms.page;
    }
    let path = page + (hashVal ? '?' + hashVal : '')
    checkNode(path, params, 'open', null, true, getWinObj, parentParam);
}

//获取页面参数
function getUrlParam(pop) {
    if (!pop) return;
    let result;

    let routerParam = ViewModel.getData('nccloud-router-params');
    //若为 pushTo 单页应用，把url存到ViewModal中--------------------新修改
    if (routerParam) {
        return routerParam[pop];
    }

    let queryString = window.location.search || window.location.hash;
    if (queryString.includes('?')) {
        queryString = queryString.split('?')[1];
    } else {
        queryString = queryString.substring(1);
    }
    if (queryString) {
        let paramsArr = queryString.split('&');
        if (paramsArr && paramsArr instanceof Array) {
            paramsArr.forEach(item => {
                if (item.indexOf('=') != -1 && item.split('=') && item.split('=') instanceof Array) {
                    if (item.split('=')[0] === pop) {
                        if (item.split('=')[1]) {
                            result = decodeURIComponent(item.split('=')[1]);
                        }
                    }
                }
            });
        }
    }
    if (!result) {
        if (this.props) {
            const { history } = this.props;
            if (history && history.location && history.location.query) {
                result = history.location.query[pop];
            }
        }
    }
    return result;
}

//设置页面参数
function spaSetUrlParam(params = {}) {
    let origin = window.location.origin;
    if (!origin) {
        origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }
    let path = window.location.href.replace(origin, "")
    let search = '';
    let page = '';
    let hashArr = [];
    let hasId = false;
    let hasItem = {};
    page = path.split("?")[0];
    let queryString = path.split("?")[1];
    if (queryString) {
        let paramsVal = queryString.split('&');
        if (paramsVal && paramsVal instanceof Array && paramsVal.length > 0) {
            let hashObj = {};
            paramsVal.forEach(elem => {
                if (elem.indexOf('=') != -1 && elem.split('=') && elem.split('=') instanceof Array) {
                    hashObj[elem.split('=')[0]] = elem.split('=')[1];
                }
            })
            if (typeof params === 'string') {
                Object.assign(hashObj, { id: params });
            }

            if (typeof params === 'object') {
                Object.assign(hashObj, params);
            }

            for (let pop in hashObj) {
                hashArr.push(`${pop}=${hashObj[pop]}`);
            }

            search = hashArr.join('&');
        }
    }
    if (page.includes('#')) {
        let hash = page.split("#")[1] + "?" + search;
        window.location.hash = hash;
    } else {
        window.location.hash = "/?" + search;
    }
    //修改工作台路径
    let env = 'production';
    if (window.top && window.top.environmentmodel) {
        env = window.top.environmentmodel;
    }
    let includeFlag = /^(\/nccloud|nccloud)\/resources*/.test(page);
    if (page.includes("#")) {
        path = (
            (env === 'development' || includeFlag) ?
                '' : (page[0] == '/' ? '/nccloud/resources' : '/nccloud/resources/')
        ) + page + '?' + search;
    } else {
        path = (
            (env === 'development' || includeFlag) ?
                '' : (page[0] == '/' ? '/nccloud/resources' : '/nccloud/resources/')
        ) + page + '#/?' + search;
    }

    let param = window.parent.location.hash.split('?')[1];
    let pageVal = '';
    if (window.parent === top && CONFIG.isPageModal) {
        let appUrlStore = getAppUrl();
        param = appUrlStore.pageurl;
        pageVal = appUrlStore.pageid;
    }
    let Arr = param && param.split('&');
    if (Arr && Arr instanceof Array && Arr.length > 0) {
        let newArr = Arr.map(item => {
            if (item.indexOf('=') != -1 && item.split('=') && item.split('=') instanceof Array) {
                if (item.split('=')[0] === 'ifr' && path) {
                    item = `ifr=${encodeURIComponent(encodeURIComponent(path))}`;
                }
                return item;
            }
        });
        let newParams = newArr.join('&');

        //--------------- 此处为改url参数代码---------------------//
        if (window.parent === top && CONFIG.isPageModal) {
            setAppUrl(pageVal, newParams);
        } else {
            window.parent.location.hash = `#/ifr?${newParams}`;
        }

    }
}

//重置浏览器id地址
function setUrlParam(params, hashChangeCallBack) {
    // pop是字符串时，设置id属性
    // pop是对象时，设置其他属性
    if (!params) return;

    let origin = window.location.origin;
    if (!origin) {
        origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }
    let path = window.location.href.replace(origin, "");

    let routerParam = ViewModel.getData('nccloud-router-params');
    //若为 pushTo 单页应用，把url存到ViewModal中--------------------新修改
    if (!routerParam && !path.includes('?')) { //刷新后 新增设置
        routerParam = {};
    }
    if (routerParam) {
        if (typeof params === 'string') {
            routerParam['id'] = params;
        }
        if (typeof params === 'object') {
            for (let pop in params) {
                routerParam[pop] = params[pop];
            }
        }
        ViewModel.setData('nccloud-router-params', routerParam);
        if (hashChangeCallBack && typeof hashChangeCallBack === "function") {
            hashChangeCallBack(getHashValue(routerParam))
        }
        return;
    }

    //否则为linkTo或openTo打开得节点
    if (path.includes('?')) {//参数只放在问号后面
        spaSetUrlParam(params);
    } else if (path.includes('#')) {
        let queryString = window.location.hash.substring(1);
        if (queryString.includes('&') || queryString.includes('=')) {
            let map = new Map(queryString.split('&').map(e => e.split('=')));
            typeof pop === 'string' && map.set('id', pop);
            typeof pop === 'object' &&
                Object.keys(pop).forEach(e => {
                    map.set(e, pop[e]);
                });
            let hashVal = [...map.entries()].map(e => e.join('=')).join('&');
            window.location.hash = hashVal;
            //修改工作台路径
            let page = path.split("#")[0];
            setParentIfr(page, hashVal);
        }
    }
}

//新增浏览器参数
function addUrlParam(popObj, hashChangeCallBack) {
    if (!popObj) return;

    let routerParam = ViewModel.getData('nccloud-router-params');
    //若为 pushTo 单页应用，把url存到ViewModal中--------------------新修改
    if (routerParam && typeof popObj === 'object') {
        for (let pop in popObj) {
            routerParam[pop] = popObj[pop];
        }
        ViewModel.setData('nccloud-router-params', routerParam);
        if (hashChangeCallBack && typeof hashChangeCallBack === "function") {
            hashChangeCallBack(getHashValue(routerParam))
        }
        return;
    }

    let origin = window.location.origin;
    if (!origin) {
        origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }
    let page = window.location.href.replace(origin, '');
    let newUrlParam = mergeHashVal(page, popObj);
    let hashVal = newUrlParam.hash;
    page = newUrlParam.page;
    if (page.includes('#')) {
        let hash = page.split("#")[1] + "?" + hashVal;
        window.location.hash = hash;
    } else {
        window.location.hash = "/?" + hashVal;
    }
    //window.location.hash = hashVal;
    //修改工作台路径
    setParentIfr(page, hashVal);
}

//删除url参数
function delUrlParam(str, hashChangeCallBack) {
    if (!str) return;

    let routerParam = ViewModel.getData('nccloud-router-params');
    //若为 pushTo 单页应用，把url存到ViewModal中--------------------新修改
    if (routerParam) {
        if (typeof str === "string") {
            if (routerParam[str]) {
                delete routerParam[str];
            }
        }
        if (Array.isArray(str) && str.length) {
            str.forEach(item => {
                if (routerParam[item]) {
                    delete routerParam[item];
                }
            })
        }
        ViewModel.setData('nccloud-router-params', routerParam);
        if (hashChangeCallBack && typeof hashChangeCallBack === "function") {
            hashChangeCallBack(getHashValue(routerParam))
        }
        return;
    }

    let origin = window.location.origin;
    if (!origin) {
        origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }
    let page = window.location.href.replace(origin, '');
    if (page.includes("?")) {
        let newPage = page.split("?")[0];
        let paramStr = page.split("?")[1];
        let paramArr = paramStr.split("&");
        if (Array.isArray(paramArr) && paramArr.length) {
            let paramObj = {};
            let newParamArr = [];
            paramArr.forEach((item => {
                if (item.includes("=") && item.split("=")[1]) {
                    paramObj[item.split("=")[0]] = item.split("=")[1];
                }
            }))
            if (typeof str === "string") {
                if (paramObj[str]) {
                    delete paramObj[str];
                }
            }

            if (Array.isArray(str) && str.length) {
                str.forEach(item => {
                    if (paramObj[item]) {
                        delete paramObj[item];
                    }
                })
            }

            for (let pop in paramObj) {
                newParamArr.push(`${pop}=${paramObj[pop]}`);
            }
            if (newParamArr.length) {

                let hashVal = newParamArr.join("&");

                if (newPage.includes('#')) {
                    let hash = newPage.split("#")[1] + "?" + hashVal;
                    window.location.hash = hash;
                } else {
                    window.location.hash = "/?" + hashVal;
                }
                setParentIfr(newPage, hashVal);
            }
        }

    }

}

//设置父window的url
function setParentIfr(page, hashVal) {
    let env = 'production';
    if (window.top && window.top.environmentmodel) {
        env = window.top.environmentmodel;
    }
    let includeFlag = /^(\/nccloud|nccloud)\/resources*/.test(page);
    //------------  #  改 ?
    let path = "";
    if (page.includes("#")) {
        path = (
            (env === 'development' || includeFlag) ?
                '' : (page[0] == '/' ? '/nccloud/resources' : '/nccloud/resources/')
        ) + page + '?' + hashVal;
    } else {
        path = (
            (env === 'development' || includeFlag) ?
                '' : (page[0] == '/' ? '/nccloud/resources' : '/nccloud/resources/')
        ) + page + '#/?' + hashVal;
    }

    let params = window.parent.location.hash.split('?')[1];
    let pageVal = '';
    if (window.parent === top && CONFIG.isPageModal) {
        let pageStore = getAppUrl();
        params = getAppUrl().pageurl;
        pageVal = getAppUrl().pageid;
    }
    let Arr = params && params.split('&');
    if (Arr && Arr instanceof Array && Arr.length > 0) {
        let newArr = Arr.map(item => {
            if (item.indexOf('=') != -1 && item.split('=') && item.split('=') instanceof Array) {
                if (item.split('=')[0] === 'ifr' && path) {
                    item = `ifr=${encodeURIComponent(encodeURIComponent(path))}`;
                }
                return item;
            }
        });
        let newParams = newArr.join('&');
        //修改工作台路径
        if (window.parent === top && CONFIG.isPageModal) {
            setAppUrl(pageVal, newParams);
        }
        else {
            window.parent.location.hash = `#/ifr?${newParams}`;
        }

    }
}

/**
 * 给父window窗口设置参数, 作为业务扩展系统参数custom; ajax请求时传给后台
 * @param {*object} params 要设置给父window的url参数
 */
function addParentUrlParam(params) {
    if (params && typeof params === "string") {
        let hash = '', pageVal = '', newHash = "";
        if (window.parent === top && CONFIG.isPageModal) { //修改顶层参数
            let pageStore = getAppUrl();
            pageVal = pageStore.pageid;
            hash = pageStore.pageurl;
            newHash = hash + `&custom=${encodeURIComponent(encodeURIComponent(params))}`;
            setAppUrl(pageVal, newHash);
        } else { // 修改其它层参数
            hash = window.parent.location.hash;
            if (hash.includes("?")) {//有？,向后面加参数
                newHash = hash + `&custom=${encodeURIComponent(encodeURIComponent(params))}`;
            } else if (hash.includes("#")) { // 有#,无？
                newHash = hash + `?custom=${encodeURIComponent(encodeURIComponent(params))}`;
            } else {//无？也无#
                newHash = hash + `#/?custom=${encodeURIComponent(encodeURIComponent(params))}`;
            }
            window.parent.location.hash = newHash;
        }
    }
}

/**
 * 删除父window窗口的系统参数 custom 业务自定义扩展参数 共享的集团信息
 */
function deleteParentUrlParam() {
    let hash = '', pageVal = '';
    if (window.parent === top && CONFIG.isPageModal) { //修改顶层参数
        let pageStore = getAppUrl();
        pageVal = pageStore.pageid;
        hash = pageStore.pageurl;
        //若存在custom属性，则将改属性删除
        hash = deleteHashParam(hash);
        setAppUrl(pageVal, hash);
    } else { // 修改其它层参数
        hash = window.parent.location.hash;
        hash = deleteHashParam(hash);
        window.parent.location.hash = newHash;
    }
}

function deleteHashParam(hash) {
    let params = hash.split('&');
    let index = params.findIndex(item => {
        return item.includes('custom=');
    })
    if (index !== -1) {
        params.splice(index, 1);
        hash = params.join("&");
    }
    return hash;
}

/**
 * option = {
 *    billpk: '',//联查页面的单据pk
 *    billtype: '' //联查页面的单据类型
 *    type: 'link'/'open' 打开页面方式：默认link 从当前页签打开; open是新页签打开
 * }
 */
function openAppByBilltype(option) {
    let reqUrl = '/nccloud/riart/billFlow/billFlowUrlAction.do';
    if (typeof option.pathModule === 'string') {
        reqUrl = '/nccloud/' + option.pathModule + '/riart/billFlow/billFlowUrlAction.do';
    }
    ajax({
        url: reqUrl,
        method: 'post',
        data: {
            billpk: option.billpk,
            billtypecode: option.billtype,
            pagecode: null,
            sence: '4',
        },
        async: false,
        success: result => {
            //查到以后校验
            if (result.success && result.data && result.data.appPageVO) {
                let url = '';
                if (result.data.appPageVO.pageurl) {
                    url = result.data.appPageVO.pageurl.replace('nccloud/resources/', '');
                }
                let newUrl = getResPageUrl(url, {
                    id: option.billpk,
                    status: 'browse',
                    scene: 'linksce',
                });

                let oldWinHash = '';
                if (window.parent === top && CONFIG.isPageModal) {
                    let oldUrl = getAppUrl().pageurl;
                    oldWinHash = CONFIG.isRemoveWorkbench ? oldUrl : (oldUrl.includes('?') ? oldUrl : ('?' + oldUrl));
                } else {
                    oldWinHash = window.parent.location.hash;
                }

                topOpenCheck(
                    result.data.appcode,
                    result.data.appPageVO.pagecode,
                    (win, res, openWinCallBack) => {
                        checkCallBack(
                            win,
                            res,
                            newUrl,
                            result.data.appcode,
                            result.data.appPageVO.pagecode,
                            option.type || 'link',
                            oldWinHash,
                            {
                                id: option.billpk,
                                status: 'browse',
                                scene: 'linksce',
                            },
                            null,
                            null,
                            openWinCallBack,
                        );
                    },
                    (option.type && option.type === 'open') ? 'tab' : '',
                );
            }
        },
    });

}

export {
    linkTo,
    openTo,
    pushTo,
    specialOpenTo,
    specialLinkTo,
    linkBack,
    openOut,
    addUrlParam,
    setUrlParam,
    getUrlParam,
    delUrlParam,
    getSearchParam,
    checkCallBack,
    getResPageUrl,
    getAppCode,
    getPageCode,
    getDomainCode,
    getModuleCode,
    addParentUrlParam,
    openAppByBilltype,
    specialOpenToOut,
    setPageCode,
    deleteParentUrlParam,
};