/*
 * @Author: liyxt
 * @Date: 2020-04-07 11:41:26
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-11 14:23:36
 * @Description: file content
 */ 
import ReactDOM from 'react-dom';
import React from 'react';
import ToastModal from './ToastModal';

if (!window.toastModal) {
    window.toastModal = [];
}
if (!window.toastModalLt) {
    window.toastModalLt = [];
}
const toast = props => {
    props = props || {};
    let app = document.getElementById('app');

    let container = document.body;
    // let isbodyFirstChild = true;
    if (props.container) {
        if (props.container.body) {
            container = props.container.body;
        } else {
            container = props.container;
            // isbodyFirstChild = false;
        }
    }
    let section = document.createElement('section');
    section.classList.add('toast-zijinyun-project');
    // if( isbodyFirstChild === false ){
    // 	section.classList.add('toast-isbodyFirstChild');
    // }
    props.mark && section.setAttribute('data-mark', props.mark);
    container.appendChild(section);
    const toasts = ReactDOM.render(<ToastModal {...props} />, section);
    return toasts;
};

function clearToast(mark) {
    if (mark) {
        let toastArr = document.querySelectorAll(`.toast-zijinyun-project[data-mark="${mark}"]`);
        // TODO  不一定需要
        let topToastArr = top && top.document.querySelectorAll(`.toast-zijinyun-project[data-mark="${mark}"]`);
		
        clearAllToast(toastArr);
		
        let toasts = Object.assign([], window.toastModal),
            n = 0;
        toasts.length && toasts.forEach((val, index) => {
            if (val && val.props && val.props.mark && val.props.mark === mark && window.toastModal) {
                window.toastModal.splice(index - n, 1);
                n++;
            }
        });
		
    } else {
        let toastArr = document.getElementsByClassName('toast-zijinyun-project');
        let toastArr1 = window.top.document.getElementsByClassName('toast-zijinyun-project');
        clearAllToast(toastArr);
        clearAllToast(toastArr1);
        if (window.toastModal) {
            window.toastModal = [];
        }
        if (window.toastModalLt) {
            window.toastModalLt = [];
        }
    }
}

function clearAllToast(Arr) {
    if (Arr) {
        Array.from(Arr).forEach(el => {
            if (el) {
                // ReactDOM.unmountComponentAtNode(el);
                el.parentNode.removeChild(el);
            }
        });
    }
}

function clearOneTypeToast(type) {
    if (type) {
        let toasts = Object.assign([], window.toastModal);
        let n = 0;
        if (toasts.length > 0) {
            toasts.forEach((val, index) => {
                if (val.props.color === type) {
                    let toastChild = ReactDOM.findDOMNode(val);
                    let toastNode = toastChild && toastChild.parentNode;
                    if (toastNode && toastNode.parentNode) {
                        toastNode.parentNode.removeChild(toastNode);
                        if (window.toastModal) {
                            window.toastModal.splice(index - n, 1);
                            n++;
                        }
                    }
                }
            });
        }
    }
}

export { toast, clearToast, clearOneTypeToast };