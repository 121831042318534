/*
 * @Author: liyxt
 * @Date: 2020-04-07 11:41:26
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-01 14:32:38
 * @Description: file content
 */ 
import { getGlobalStorage } from './storage';

export function getGzip() {
    let isZip = getGlobalStorage('localStorage', 'gzip');
    if (isZip) {
        return true;
    } else {
        return false;
    }
}