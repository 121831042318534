import { syncAjax } from './ajaxsync';
import Gzip from './gzip';
import { getAppReqParam } from './appUrlUtils';
import { setGlobalStorage, getGlobalStorage } from './storage';
import { getCookie } from './cookie';

const gziptools = new Gzip();

export function getSysFieldid(fieldid) {
    if (NODE_ENV === 'development') {
        return fieldid;
    }
    // 条件前置 避免多次调用下面的逻辑  否则一个页面调用千次会导致卡顿
    if (window.automatedSwitch === '1') {
        return fieldid;
    }

    if (window.automatedSwitch === '0' || window.automatedSwitch === '3') {
        return null;
    }

    // 此时的cookie是当前界面唯一 如果放到ajax内部  
    // 多个请求的情况下 会导致前面的cookie被覆盖  所有请求用的是第二次的cookie  而加密用的cookie是第一次的
    let cookiets = (top && top.cookiets) || getCookie('cookiets') || Date.now(); //双cookie验证 liuxis
    // 做一次 数据转型
    cookiets = isNaN(cookiets) ? cookiets : Number(cookiets);

    let headers = { 'Content-Type': 'application/json;charset=UTF-8', cookiets };
    let { appcode } = getAppReqParam();
    appcode = appcode || '10228888';

    // 添加一个参数给nginx 转发使用
    headers['transcode'] = appcode;
  
    let gzipFlag = setNCCSwitch(headers);

    let automatedSwitch = getGlobalStorage('localStorage', '_ncc_automated_');

    if (automatedSwitch !== '1' && automatedSwitch !== '0' && automatedSwitch !== '3' && NODE_ENV !== 'development') {
        let reqData = {
            sysParamJson: {
                busiaction: '查询请求自动化测试开关',
                pagecs: cookiets,
            },
        };

        let reqHeaders = headers ? { ...headers } : {};
        reqData = JSON.stringify(addDataSign(reqData, '', { allin: true, headers: reqHeaders }));

        syncAjax({
            url: '/nccloud/platform/query/automated.do',
            data: gzipFlag ? gziptools.zip(reqData) : reqData,
            headers: reqHeaders,
            async: false,
            type: 'post',
            success: res => {
                res = gzipFlag ? gziptools.unzip(res) : res;
                res = typeof res === 'string' ? JSON.parse(res) : res;
                if (res && res.success) {
                    if (res.data) {
                        //开启关闭自动化测试开关
                        setGlobalStorage('localStorage', '_ncc_automated_', '1');
                        // 避免多次加载
                        window.automatedSwitch = '1';
                        return fieldid;
                    } else {
                        //关闭自动化测试开关
                        setGlobalStorage('localStorage', '_ncc_automated_', '0');
                        // 避免多次加载
                        window.automatedSwitch = '0';
                        return null;
                    }
                } else {
                    setGlobalStorage('localStorage', '_ncc_automated_', '3');
                    // 避免多次加载
                    window.automatedSwitch = '3';
                    console.log('自动化测试开关加载错误', res);
                }
            },
            error(res) {
                setGlobalStorage('localStorage', '_ncc_automated_', '3');
                console.log('自动化测试开关加载错误', res);
            },
        });
    } else if (automatedSwitch === '1') {
        // 避免多次加载
        window.automatedSwitch = '1';
        return fieldid;
    } else {
        // 避免多次加载
        window.automatedSwitch = automatedSwitch;
    }

    return null;
}

// 添加一个加签处理方法 -- by bbqin
function addDataSign(data, userCode, {
    allin = false,
    headers = {},
}) {
    // console.log(data, userCode);
    if (
        typeof transSn === 'undefined' ||
        typeof transSign === 'undefined' ||
        // !userCode ||
        (headers && headers['Content-Type'] == 'multipart/form-data') // newData.append(  这种模式 先确定下 需不需要加签操作
    ) {
        return data;
    }
    // 先加签全部数据 或者部分数据

    // 这里会影响原字符串 （编码、位数？）导致后台解析不了字符串
    if (!allin) {
        let strDataSign = data.busiParamJson + '';
        // 加签 -- bbqin
        data.sysParamJson.sn = transSn(userCode);
        data.sysParamJson.signdata = transSign(strDataSign, userCode);
    } else {
        // 加签 -- bbqin
        // data.sysParamJson.sn = transSn(userCode);
        let dataStr = JSON.stringify(data) + '';
        headers['Identifications'] = transSign(dataStr, '');
    }
    // 这里是通过对象方式注入  headers  不符合设计原则 TODO 
    return data;
}

export function setNCCSwitch(headers, cookiets){
    let gzipFlag = false;
    let gzip = getGlobalStorage('localStorage', 'gzip');

    if (gzip == null) {
        let gzipData = {
            sysParamJson: {
                busiaction: '查询请求流量压缩开关',
                pagecs: cookiets,
            },
        };
        // 为了避免headers冲突 这里要修正下 
        let gzipHeaders = headers ? { ...headers } : {};
        gzipData = JSON.stringify(addDataSign(gzipData, '', { allin: true, headers: gzipHeaders }));
        syncAjax({
            type: 'post',
            url: '/nccloud/platform/gzip/switch.do',
            data: gzipData,
            headers: gzipHeaders,
            async: false,
            success: res => {
                res = typeof res === 'string' ? JSON.parse(res) : res;

                if (res.data) {
                    if (res.data.success || res.success) {
                        if (res.data.data) {
                            gzipFlag = true;
                        } else {
                            gzipFlag = false;
                        }
                        // 标识请求过一次，或放入localStorage
                        let storeCipher = localStorage.getItem('storeCipher');
                        //若取不到，还未发过请求
                        if (storeCipher === null) {
                            let reqData = {
                                sysParamJson: {
                                    busiaction: '查询storage加密开关',
                                    pagecs: cookiets,
                                },
                            };
                            let reqHeaders = headers ? { ...headers } : {};
                            reqData = JSON.stringify(addDataSign(reqData, '', { allin: true, headers: reqHeaders }));
                            syncAjax({
                                type: 'post',
                                url: '/nccloud/platform/localstorage/switch.do',
                                data: gzipFlag ? gziptools.zip(reqData) : reqData,
                                async: false,
                                headers: reqHeaders,
                                success: res => {
                                    res = gzipFlag ? gziptools.unzip(res) : res;
                                    res = typeof res === 'string' ? JSON.parse(res) : res;
                                    if (res && res.success) {
                                        if (res.data) {
                                            localStorage.setItem('storeCipher', 1);
                                        } else {
                                            localStorage.setItem('storeCipher', 0);
                                        }
                                        setGlobalStorage('localStorage', 'gzip', gzipFlag ? 1 : 0);
                                    } else {
                                        console.log('本地加密开关加载错误', res);
                                    }
                                },
                                error(res) {
                                    console.log('本地加密开关加载错误', res);
                                },
                            });
                        }
                    }
                } else {
                    console.log('压缩开关加载错误', res);
                }
            },
            error(res) {
                console.log('压缩开关加载错误', res);
            },
        });
    } else {
        // 标识请求过一次，或放入localStorage
        let storeCipher = localStorage.getItem('storeCipher');
        //若取不到，还未发过请求
        if (storeCipher === null) {
            let reqData = {
                sysParamJson: {
                    busiaction: '查询storage加密开关',
                    pagecs: cookiets,
                },
            };
            let reqHeaders = headers ? { ...headers } : {};
            reqData = JSON.stringify(addDataSign(reqData, '', { allin: true, headers: reqHeaders }));
            syncAjax({
                type: 'post',
                url: '/nccloud/platform/localstorage/switch.do',
                data: gzipFlag ? gziptools.zip(reqData) : reqData,
                async: false,
                headers: reqHeaders,
                success: res => {
                    res = gzipFlag ? gziptools.unzip(res) : res;
                    res = typeof res === 'string' ? JSON.parse(res) : res;
                    if (res && res.success) {
                        if (res.data) {
                            localStorage.setItem('storeCipher', 1);
                        } else {
                            localStorage.setItem('storeCipher', 0);
                        }
                        setGlobalStorage('localStorage', 'gzip', gzipFlag ? 1 : 0);
                    } else {
                        console.log('本地加密开关加载错误', res);
                    }
                },
                error(res) {
                    console.log('本地加密开关加载错误', res);
                },
            });
        }
        if (getGlobalStorage('localStorage', 'gzip') == '1') {
            gzipFlag = true;
        } else if (getGlobalStorage('localStorage', 'gzip') == '0') {
            gzipFlag = false;
        }
    }

    return gzipFlag;
}