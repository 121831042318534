export function combineState(setStates, callback) {
	const _this = this;
	if (Array.isArray(setStates)) {
		Promise.all(
			setStates.filter(e => typeof e === 'function').map(setState => {
				return new Promise(resolve => {
					setState(resolve);
				});
			})
		)
			.then(states => {
				let newState = Object.assign({}, ...states);
				_this.setState(newState, () => {
					typeof callback === 'function' && callback();
				});
			})
			.catch(e => {
				throw new Error(e);
			});
	}
}
