/*
 * @Author: liyxt
 * @Date: 2019-12-25 15:00:28
 * @LastEditors: liyxt
 * @LastEditTime: 2019-12-26 15:37:05
 * @Description: file content
 */
import React, { Component } from 'react';

export class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, info) {
		this.setState({ hasError: true });
		console.error(error, info);
	}

	render() {
		if (this.state.hasError) {
			return this.props.fallback || <div>Something went wrong</div>;
		}
		return this.props.children;
	}
}
