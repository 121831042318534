/*
 * @Author: liyxt
 * @Date: 2020-06-01 14:21:48
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-01 14:23:37
 * @Description: file content
 */ 
import { decrypt } from './cipher';

const aeskey = '4fa8959db7b4423a99f056e299914128';

/**
 * 获取解密数据
 * @param {*string} data
 */
export function getDecryptData (data) {
    return localStorage.getItem('storeCipher') != '0' && data !== null ? decrypt(data, aeskey) : data;
}