const scriptMap = {};

export function loadJS(
    url,
    onload,
    onerror = err => {
        throw err;
    }
) {
    let script;

    if (scriptMap[url]) {
        if (scriptMap[url].loaded) {
            onload();
            return;
        } else {
            script = scriptMap[url];
        }
    } else {
        script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        document.body.appendChild(script);
        scriptMap[url] = script;
    }

    script.addEventListener('load', function() {
        onload();
        scriptMap[url].loaded = true;
    });
    script.addEventListener('error', function() {
        onerror();
        scriptMap[url].loaded = true;
    });
}

export function unLoadJS(url) {
    if (scriptMap[url]) {
        document.head.removeChild(scriptMap[url]);
        delete scriptMap[url];
    }
}
