import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';

export const render = function (App, container, callback) {
    ReactDOM.render(<Provider store={store}>{App}</Provider>, container, () => {
        callback && callback();
    });
};
