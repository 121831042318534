/*
 * @Author: liyxt
 * @Date: 2019-10-21 14:15:59
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-02 10:29:44
 * @Description: file content
 */ 
import CONFIG from './config';

/**
 * @desc 寻找当前聚焦的元素属于哪个区域
 * @returns {string}
 */
function findFocusModuleId() {
    let focusModuleId = null;
    let activeEl = document && document.activeElement;
    let orderOfHotKey = this.orderOfHotKey;

    if (satisfyFindModuleId(orderOfHotKey, activeEl)) {
        orderOfHotKey.forEach(item => {
            let prefix = CONFIG && CONFIG.hotKeyModuleIdPrefix;
            let moduleId = `${prefix}-${item}`;
            let container = document.getElementById(moduleId);

            if (container && container.contains(activeEl)) {
                focusModuleId = item;
            }
        });
    }

    return focusModuleId;
}

/**
 * @desc 如果没有业务组没有适配快捷键，或者当前没有聚焦元素就终止函数执行
 * @param orderOfHotKey {Array}
 * @param activeEl {DOM}
 */
function satisfyFindModuleId(orderOfHotKey, activeEl) {
    return orderOfHotKey.length > 0 && activeEl;
}

export {
    findFocusModuleId,
};