export const executeTimesLogger = function(className) {
	return function(target, name, descriptor) {
		let old = descriptor.value;

		descriptor.value = function(...rest) {
			this.executeTimes = this.executeTimes || 1;
			console.log(`${className}'s ${name} was execued ${this.executeTimes++} times`);
			return old.call(this, ...rest);
		};
		return descriptor;
	};
};
