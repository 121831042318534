import { localeLang } from './currentLocale';
import { getMultiLang } from './getMultiLang';

/**
 *  处理消息提示的多语问题，定义的提示多语容器（ES6语法支持面向对象类）
 */
class LangContainer {
    constructor(props) {
        this.lang = null;
        this.inlt = null;
        // 初始化提示多语信息
        getMultiLang({
            moduleId: props.moduleId,
            domainName: props.domainName,
            callback: (lang, status, inlt)=>{
                this.init.call(this, lang, status, inlt)
                props.callback&&props.callback(lang, status, inlt)
            },
            needInlt: true,
        });
    }

    init(lang, status, inlt) {
        if (status) {
            this.lang = lang;
            this.inlt = inlt;
        }
    }

    getLang() {
        return this.lang
    }
    getInlt() {
        return this.inlt
    }
    get(resid, param) {
        let str = resid;
        if (param) {
            str = this.inlt.get(resid, param);
            return str ? str : resid;
        } else {
            // 如果还没有加载回来，则返回空，避免页面显示多语字符串
            if (this.lang) {
                str = this.lang[resid];
                return str ? str : resid;
            } else {
                return resid;
            }
        }
    }
}

class NCCloudIntl {
    constructor() {
        this.options = {
            currentLocale: null,
            locales: {},
            formats: null,
            warningHandler: console.warn,
        };
    }
    getDescendantProp(locale, key) {
        if (locale[key]) {
            return locale[key];
        }
    }
    get(key, variables) {
        let {currentLocale, locales} = this.options
        let str = locales[currentLocale][key]
        if (variables&&Object.keys(variables).length) {
            for (let [item, value] of Object.entries(variables)) {
                let reg = new RegExp(item,"gm")
                str=str.replace(reg,value);
            }
            str=str.replace(/{/g,'');
            str=str.replace(/}/g,'');
            return str
        } else {
            return str
        }

    }
    init(options = {}) {
        let {currentLocale, locales} = options
        this.options.currentLocale = currentLocale
        this.options.locales = locales
    }

}

function inlt(json) {
    let intl = new NCCloudIntl()
    intl.init({
        currentLocale: localeLang, // TODO: determine locale here
        locales: {
            [localeLang]: json,
        },
    })
    return intl
}

export {
    LangContainer,
    inlt,
};