import axios from 'axios';
// import intl from 'react-intl-universal';
import NCCloudIntl from './nccloud-intl-universal';
import ViewModel from './viewmodel'
import { localeLang } from './currentLocale';

function getCatchKey(moduleId, domain) {
    let str = '';
    if (typeof moduleId === 'number' || typeof moduleId === 'string') {
        str = '^' + domain + moduleId + '$';
    } else if (Array.isArray(moduleId)) {
        for (let item of moduleId) {
            str += '^' + domain + item + '$';
        }
    } else {
        for (let [key, value] of Object.entries(moduleId)) {
            if (Array.isArray(value)) {
                for (let item of value) {
                    str += '^' + key + item + '$';
                }
            } else {
                str += '^' + key + value + '$';
            }
        }
    }
    return str;
}

function Public() {
    //存放订阅者信息
    this.subscribers = {};
    //添加订阅者
    this.addSubscriber = function(key, subscriber) {
        if (!this.subscribers[key]) this.subscribers[key] = [];
        this.subscribers[key].push(subscriber);
    };
    this.setSubscriber = function(value) {
        return (this.subscribers = value);
    };
    this.getSubscriber = function() {
        return this.subscribers;
    };
    //发布消息
    this.delivers = function(key, res) {
        // console.log(res, '+++++++++++++++')
        if (this.subscribers[key]) this.subscribers[key].forEach(item => item(res));
        this.subscribers[key] = [];
    };
    this.deliver = function(needInlt, intl) {
        for (let item of this.subscribers) {
            let multiJson = item.multiJson;
            let runCallback = true;
            for (let item of Object.keys(multiJson)) {
                if (!multiJson[item]) {
                    runCallback = false;
                    multiJson[item] = ViewModel.getData(item);
                }
            }
            if (runCallback) {
                let ins = null;
                let json = {};
                for (let value of Object.values(multiJson)) {
                    json = { ...json, ...value };
                }
                if (needInlt) {
                    intl.init({
                        currentLocale: localeLang, // TODO: determine locale here
                        locales: {
                            [localeLang]: json,
                        },
                    });
                    ins = intl;
                }
                item.needLoading = true;
                item.fu(json, true, ins);
            }
        }
        this.subscribers = this.subscribers.filter(item => {
            return !item.needLoading;
        });
    };

    this.multiLangData = {};
    this.setMultiLangData = function(key, value) {
        this.multiLangData[key] = value;
    };
    this.getMultiLangData = function(key) {
        if (key) {
            return this.multiLangData[key];
        } else {
            return this.multiLangData;
        }
    };
}

function getSubscribers() {
    if (!window.Subscribers) window.Subscribers = new Public();
    return window.Subscribers;
}

getSubscribers();

// 通过ajax请求加载多语
export function getMultiLang({ getUrl, moduleId, callback, environment, domainName = 'platform', needInlt = true }) {
    if (getUrl) {
        return axios.get(getUrl).then(
            res => {
                callback && callback(res.data);
            },
            () => {
                callback && callback({});
            },
        );
    }
    let intl = new NCCloudIntl();
    let topKey = getCatchKey(moduleId, domainName);
    let cache = ViewModel.getData(topKey);

    /*
    *
    * locale: 语种； res ： 要传输的多语对象； status： 是否添加监听状态
    * */
    function setIntl({ locale, res, status }) {
        let ins = null;
        if (needInlt) {
            intl.init({
                currentLocale: locale, // TODO: determine locale here
                locales: {
                    [locale]: res,
                },
            });
            ins = intl;
        }
        if (typeof moduleId === 'number' || typeof moduleId === 'string') {
            Subscribers.setMultiLangData(moduleId, {
                json: res,
                intl: ins,
            });
        }
        callback && callback(res, true, ins);
    }

    if (cache) {
        setIntl({ locale: localeLang, res: cache, status: true });
    } else {
        // 环境参数
        let env = 'production';
        if (window.parent && window.top.environmentmodel) {
            env = window.top.environmentmodel;
        }
        // 用来判断角色平台多语请求路径
        let isWorkbenchDev = domainName === 'workbench' && environment === 'development';
        let isWorkbenchPro = domainName === 'workbench' && environment === 'production';

        let domain = isWorkbenchDev ? '' : domainName;
        // 前缀
        let prefix = isWorkbenchDev ? '' : '../../../../';
        if (isWorkbenchPro) {
            prefix = '../../../../';
        }
        // 路径后缀
        let suffix = `/public/lang/standard/${localeLang}/`;
        let urls = {};
        if (!window.multiLangTags) window.multiLangTags = {};
        if (!window.multiLangTags.hasOwnProperty(topKey)) window.multiLangTags[topKey] = [];

        // 生成urls
        if (typeof moduleId === 'number' || typeof moduleId === 'string') {
            urls[domain + moduleId] = prefix + `${domain}` + suffix + moduleId + '.json';
        } else if (Array.isArray(moduleId)) {
            moduleId.forEach(item => {
                urls[domain + item] = prefix + `${domain}` + suffix + item + '.json';
            });
        } else {
            for (let [ke, value] of Object.entries(moduleId)) {
                if (Array.isArray(value)) {
                    value.forEach(item => {
                        urls[ke + item] = prefix + `${ke}` + suffix + item + '.json';
                    });
                } else {
                    urls[ke + value] = prefix + `${ke}` + suffix + value + '.json';
                }
            }
        }
        if (!ViewModel.getData('multiLangData')) ViewModel.setData('multiLangData', {});
        let tasts = Object.entries(urls).map(item => {
            let key = item[0],
                url = item[1];
            let VM = ViewModel.getData('multiLangData');
            return new Promise(function(resolve) {
                if (!VM.hasOwnProperty(key)) {
                    // 没有发过请求
                    VM[key] = null;
                    ViewModel.setData('multiLangData', VM);
                    axios.get(url).then(res => {
                        VM[key] = res;
                        Subscribers.delivers(key, VM[key]);
                        ViewModel.setData('multiLangData', VM);
                        resolve(res);
                    });
                } else if (VM.hasOwnProperty(key) && !VM[key]) {
                    // 还在请求中，pending状态
                    // 需要添加订阅者
                    Subscribers.addSubscriber(key, function(res) {
                        VM[key] = res;
                        ViewModel.setData('multiLangData', VM);
                        resolve(res);
                    });
                } else if (VM.hasOwnProperty(key) && VM[key]) {
                    // 请求已经成功了，pending => resolve
                    Subscribers.delivers(key, VM[key]);
                    resolve(VM[key]);
                }
            });
        });
        Promise.all(tasts).then(function(res) {
            let u = urls,
                V = ViewModel.getData('multiLangData');
            // console.log(u, V)
            let result = res.reduce((total, nowItem) => {
                return (total = { ...total, ...nowItem.data });
            }, {});

            ViewModel.setData(topKey, result);
            setIntl({ locale: localeLang, res: result, status: false });
        });
    }
}

// 通过ajax请求加载多语
export function getMultiLangJ({
    currentLocale,
    getUrl,
    moduleId,
    callback,
    environment,
    domainName = 'platform',
    needInlt = true,
    dataSource,
}) {
    if (getUrl) {
        return axios.get(getUrl).then(res => {
            callback && callback(res.data);
        });
    }
    let intl = new NCCloudIntl();
    let topKey = getCatchKey(moduleId, domainName);
    let cache = ViewModel.getData(topKey);

    /*
    *
    * locale: 语种； res ： 要传输的多语对象； status： 是否添加监听状态
    * */
    function setIntl({ locale, res, status }) {
        let ins = null;
        if (needInlt) {
            intl.init({
                currentLocale: locale, // TODO: determine locale here
                locales: {
                    [locale]: res,
                },
            });
            ins = intl;
        }
        if (typeof moduleId === 'number' || typeof moduleId === 'string') {
            Subscribers.setMultiLangData(moduleId, {
                json: res,
                intl: ins,
            });
        }
        if (status) {
            callback && callback(res, true, ins);
        } else {
            Subscribers.deliver(needInlt, intl);
        }
    }

    if (cache) {
        setIntl({ locale: localeLang, res: cache, status: true });
    } else {
        // 环境参数
        let env = 'production';
        if (window.parent && window.top.environmentmodel) {
            env = window.top.environmentmodel;
        }
        // 用来判断角色平台多语请求路径
        let isWorkbenchDev = domainName === 'workbench' && environment === 'development';
        let isWorkbenchPro = domainName === 'workbench' && environment === 'production';

        let domain = isWorkbenchDev ? '' : domainName;
        // 前缀
        let prefix = isWorkbenchDev ? '' : '../../../../';
        if (isWorkbenchPro) {
            prefix = '../../../../';
        }
        // 路径后缀
        let suffix = `/public/lang/standard/${localeLang}/`;
        let urls = {};
        if (!window.multiLangTags) window.multiLangTags = {};
        if (!window.multiLangTags.hasOwnProperty(topKey)) window.multiLangTags[topKey] = [];

        // 加载任务队列 方便getMultiLang重复调用的时候不重复调用json
        let timestamp = new Date().getTime();
        // 当前函数执行的时候是否所有要请求的json都已经请求过
        let bool = true;
        let cachData = {};
        // 当前多语需要返回的json集合
        let multiJson = {};
        if (typeof moduleId === 'number' || typeof moduleId === 'string') {
            let key = domain + moduleId;
            multiJson[key] = ViewModel.getData(key);
            if (!multiJson[key]) {
                bool = false;
                if (!window.multiLangTags[topKey].includes(domain + moduleId)) {
                    window.multiLangTags[topKey].push(domain + moduleId);
                    urls[domain + moduleId] = prefix + `${domain}` + suffix + moduleId + '.json';
                }
            }
        } else if (Array.isArray(moduleId)) {
            moduleId.forEach(item => {
                let key = domain + item;
                multiJson[key] = ViewModel.getData(key);
                if (!multiJson[key]) {
                    bool = false;
                    if (!window.multiLangTags[topKey].includes(domain + item)) {
                        window.multiLangTags[topKey].push(domain + item);
                        urls[domain + item] = prefix + `${domain}` + suffix + item + '.json';
                    }
                }
            });
        } else {
            for (let [ke, value] of Object.entries(moduleId)) {
                if (Array.isArray(value)) {
                    value.forEach(item => {
                        let key = ke + item;
                        multiJson[key] = ViewModel.getData(key);
                        if (!multiJson[key]) {
                            bool = false;
                            if (!window.multiLangTags[topKey].includes(ke + item)) {
                                window.multiLangTags[topKey].push(ke + item);
                                urls[ke + item] = prefix + `${ke}` + suffix + item + '.json';
                            }
                        }
                    });
                } else {
                    let key = ke + value;
                    multiJson[key] = ViewModel.getData(key);
                    if (!multiJson[key]) {
                        bool = false;
                        if (!window.multiLangTags[topKey].includes(ke + value)) {
                            window.multiLangTags[topKey].push(ke + value);
                            urls[ke + value] = prefix + `${ke}` + suffix + value + '.json';
                        }
                    }
                }
            }
        }

        if (bool) {
            for (let [item, value] of Object.entries(multiJson)) {
                cachData = { ...cachData, ...value };
            }
            setIntl({ locale: localeLang, res: cachData, status: true });
        } else {
            // 添加订阅队列
            Subscribers.addSubscriber({
                key: topKey + '~' + timestamp,
                multiJson,
                needLoading: bool,
                fu: callback,
            });

            let tasks = Object.entries(urls).reduce((total, nowItem) => {
                total.push(axios.get(nowItem[1]));
                return total;
            }, []);
            if (tasks.length) {
                Promise.all(tasks)
                    .then(res => {
                        let data = Object.entries(urls);
                        for (let i = 0; i < data.length; i++) {
                            ViewModel.setData(data[i][0], res[i].data);
                            console.log(data[i][0], res[i].data, res, 'ssss');
                        }
                        let result = res.reduce((total, nowItem) => {
                            return (total = { ...total, ...nowItem.data });
                        }, {});
                        ViewModel.setData(topKey, result);
                        setIntl({ locale: localeLang, res: result, status: false });
                    })
                    .catch(err => {
                        callback({}, false);
                    });
            }
        }
    }
}

export function getLangData(moduleId) {
    return Subscribers.getMultiLangData(moduleId) || {
        json: {},
        intl: null,
    }
}