/**
 * 业务组往缓存中设置自定义数据
 */
function setDefData(key, dataSource, data) {
    ViewModel.setComponentState(key, dataSource, data);
}

/**
 * 业务组在缓存中获取自定义数据
 */
function getDefData(key, dataSource){
    return ViewModel.getComponentState(key, dataSource)
}

export {
    setDefData,
    getDefData,
};