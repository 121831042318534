/*
 * @Descripttion:
 * @version:
 * @Author: bbq
 * @Date: 2021-01-05 10:29:50
 * @LastEditors: bbq
 * @LastEditTime: 2021-01-05 16:23:42
 */

export const KeyCode = {
    backspace: 8,
    tab: 9,
    enter: 13,
    shift: 16,
    ctrl: 17,
    alt: 18,
    capslock: 20,
    esc: 27,
    space: 32,
    pageup: 33,
    pagedown: 34,
    end: 35,
    home: 36,
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    ins: 45,
    del: 46,
    meta: [91, 93, 224],
};

const _KEYCODE_MAP = {
    106: '*',
    107: '+',
    109: '-',
    110: '.',
    111: '/',
    186: ';',
    187: '=',
    188: ',',
    189: '-',
    190: '.',
    191: '/',
    192: '`',
    219: '[',
    220: '\\',
    221: ']',
    222: '\''
};

const _SPECIAL_ALIASES = {
    option: 'alt',
    command: 'meta',
    return: 'enter',
    escape: 'esc',
    plus: '+',
    mod: /Mac|iPod|iPhone|iPad/.test(navigator.platform) ? 'meta' : 'ctrl',
};

/**
* @desc 判断是否按某个键
* @param e {obj} 事件源
* @param keys {array/string} 事件关键字
*/
export function isKeyboard(keys, e={}) {
    keys = Array.isArray(keys) ? keys : [keys];
    const res = keys.some(keyboard => {
        if (Array.isArray(keyboard)) {
            return keyboard.any(key => {
                // 组合键
                return (key === 'ctrl' && e.ctrlKey) ||
                    (key === 'shift' && e.shiftKey) ||
                    (key === 'alt' && e.altKey) ||
                    (key === 'meta' && e.metaKey) ||
                    KeyCode[key] === e.keyCode ||
                    (String.fromCharCode(e.charCode)).toLowerCase() === String(key).toLowerCase();
            })
        } else {
            return (keyboard === 'ctrl' && e.ctrlKey) ||
                (keyboard === 'shift' && e.shiftKey) ||
                (keyboard === 'alt' && e.altKey) ||
                (keyboard === 'meta' && e.metaKey) ||
                KeyCode[keyboard] === e.keyCode ||
                (String.fromCharCode(e.charCode)).toLowerCase() === String(keyboard).toLowerCase();
        }
    })
    return res;
}

/**
* @desc 判断是否按组合键
* @param e {obj} 事件源
* @param keys {array/string} 事件关键字
*/
export function isComboKeyboard(e) {
    let specialKey = [KeyCode.ctrl, KeyCode.alt, KeyCode.shift, ...KeyCode.meta];
    return (e.ctrlKey || e.shiftKey || e.altKey || e.metaKey) && !specialKey.includes(e.keyCode);
}

/**
* @desc 判断是否按下了方向键
* @param e {obj} 事件源
* @param wantedKeys {array} 想要监听的按钮
*/
export function isArrowKeyDown(e, wantedKeys) {
    if (e.ctrlKey || e.shiftKey || e.altKey || e.metaKey) return false;
    let keys = wantedKeys || [KeyCode.left, KeyCode.up, KeyCode.right, KeyCode.down];
    return keys.includes(e.keyCode);
}

/**
 * @desc 判断 control + 上下 方向键
 * @param e {object}
 */
export function ctrlKeyWithUpDown(e) {
    return (e.ctrlKey || e.metaKey) && [KeyCode.up, KeyCode.down].includes(e.keyCode);
}

/**
 * @desc 判断 enter 事件
 * @param e {object} 事件源
 * @return {BooleanConstructor}
 */
export function isEnterKeyDown(e) {
    //中文输入法下按的enter keyCode 都返回 229
    return e.key === 'Enter' && e.keyCode !== 229 && e.ctrlKey === false && e.altKey === false && e.metaKey === false;
}

