export function set(target, key, value) {
	let keys = key.split('.'),
		lastKey = keys.pop(),
		_target = target;
	if (!(target instanceof Object)) {
		throw new Error("the first argument 'target' is not an Object");
	}
	keys.forEach(key => {
		if (!target[key]) {
			target[key] = {};
		}
		target = target[key];
	});
	target[lastKey] = value;
	return _target;
}

export function get(target, key) {
	let keys = key.split('.');
	if (!(target instanceof Object)) {
		return undefined;
	}
	for (let i = 0, l = keys.length; i < l; i++) {
		let key = keys[i];
		if (target[key]) {
			target = target[key];
		} else {
			return undefined;
		}
	}
	return target;
}

export const isObject = target => Object.prototype.toString.call(target) === '[object Object]';
export const isFunction = target => Object.prototype.toString.call(target) === '[object Function]';
export const  getQueryString = (name) => { 
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var reg_rewrite = new RegExp('(^|/)' + name + '/([^/]*)(/|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    var q = window.location.pathname.substr(1).match(reg_rewrite);
    if (r != null) {
        return unescape(r[2]);
    } else if (q != null) {
        return unescape(q[2]);
    }else {
        return null;
    }
}; 