/*
 * @Author: liyxt
 * @Date: 2020-05-29 09:53:24
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-02 14:37:17
 * @Description: file content
 */ 
export function findParentTd(el) {
    while (el?.parentElement) {
        el = el.parentElement;
        if (el.tagName === 'TD') {
            return el;
        }
    }
    return null;
}
