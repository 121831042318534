/*
 * @Author: liyxt
 * @Date: 2020-06-01 14:12:36
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-01 14:14:00
 * @Description: file content
 */ 
export function setCookie (key, value, path, domain, day) {
    value = JSON.stringify(value);
    let expires = '',
        setPath,
        setdomain;
    if (day) {
        let d = new Date();
        d.setDate(d.getDate() + day);
        expires = '; expires=' + d.toUTCString();
    }
    if (path) {
        setPath = `; path=${path}`;
    } else {
        setPath = '; path=/nccloud';
    }
    if (domain) {
        setdomain = `; setdomain=${domain}`;
        // 去除写cookie的操作
        // document.cookie = key + '=' + value + expires + setPath + setdomain;
    } else {
        // 去除写cookie的操作
        // document.cookie = key + '=' + value + expires + setPath;
    }
}

export function getCookie (key) {
    let cookies = document.cookie;
    let allCookies = cookies.split('; ').reduce((o, item) => {
        let firstIndex = item.indexOf('=');
        o[item.substring(0, firstIndex)] = item.substring(firstIndex + 1);
        return o;
    }, {});
    if (key) return allCookies[key];
    return allCookies;
}

export function removeCookie (key) {
    let allCookies = getCookie();
    if (key) {
        setCookie(key, null, null, null, -1);
    } else {
        for (let attr in allCookies) {
            setCookie(attr, null, null, null, -1);
        }
    }
}