/*
 * @Author: liyxt
 * @Date: 2019-07-24 10:47:01
 * @LastEditors  : liyxt
 * @LastEditTime : 2020-02-27 14:45:47
 * @Description: file content
 */
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { initReducer, actions } from './reducer';

// logger的时候把Immutable转JS
const logger = createLogger({
	stateTransformer(state) {
		let JSState = {};
		Object.entries(state).forEach(([key, value]) => {
			JSState[key] = value.toJS();
		});
		return JSState;
	}
});

let middleWares;
if (NODE_ENV === 'development') {
	// eslint-disable-next-line no-unused-vars
	middleWares = composeWithDevTools(
		applyMiddleware(logger, thunk, ({ getState, dispatch }) => dispatch => action => {
			// console.log('自定义MiddleWare');
			return dispatch(action);
		})
	);
} else {
	middleWares = composeWithDevTools(applyMiddleware(thunk));
}

let store = createStore((state = {}) => state, middleWares);

const { registerNameSpace, removeNameSpace } = initReducer(store);

window.store = store;
store.actions = actions;

export default store;
export { actions, registerNameSpace, removeNameSpace };
