export function sum(...rest) {
    var arr = rest.map(e => String(e || 0).replace(/\,/g, ''));
    var total = arr.reduce((a, b) => {
        let int_a = (a.split('.')[0] || '').split('').reverse(), // a的整数部分
            int_b = (b.split('.')[0] || '').split('').reverse(), // b的整数部分
            float_a = (a.split('.')[1] || '').split(''), // a的小数部分
            float_b = (b.split('.')[1] || '').split(''), // a的小数部分
            final_int = [],
            final_float = [],
            flag = 1, //是否两个都是负数
            a_times = 1, // a是不是负数
            b_times = 1; // b是不是负数
        if (int_a[int_a.length - 1] === '-') {
            int_a.pop();
        }
        if (int_b[int_b.length - 1] === '-') {
            int_b.pop();
        }
        let int_l = Math.max(int_a.length, int_b.length), // 整数部分的最大长度
            float_l = Math.max(float_a.length, float_b.length); // 小数部分的最大长度

        if (a[0] === '-' && b[0] === '-') {
            // a和b都是负数
            flag = -1;
            a_times = 1;
            b_times = 1;
        }
        if (a[0] === '-' && b[0] !== '-') {
            // a是负数，b是正数
            if (Math.abs(a) > Math.abs(b)) {
                flag = -1;
                a_times = 1;
                b_times = -1;
            } else if (Math.abs(a) < Math.abs(b)) {
                flag = 1;
                a_times = -1;
                b_times = 1;
            } else {
                flag = 1;
                a_times = 1;
                b_times = -1;
            }
        }
        if (a[0] !== '-' && b[0] === '-') {
            // a是正数，b是负数
            if (Math.abs(a) > Math.abs(b)) {
                flag = 1;
                a_times = 1;
                b_times = -1;
            } else if (Math.abs(a) < Math.abs(b)) {
                flag = -1;
                a_times = -1;
                b_times = 1;
            } else {
                flag = 1;
                a_times = 1;
                b_times = -1;
            }
        }
        // 先算小数
        for (let i = float_l - 1; i >= 0; i--) {
            let f = a_times * Number(float_a[i] || 0) + b_times * Number(float_b[i] || 0);
            if (f >= 10 && i !== 0) {
                // 够10进1
                f -= 10;
                float_a[i - 1] = Number(float_a[i - 1] || 0) + 1;
            } else if (f < 0 && i !== 0) {
                // 不够0减1
                f += 10;
                // 大数 - 1
                if (a_times > 0) {
                    float_a[i - 1] = Number(float_a[i - 1] || 0) - 1;
                } else if (b_times > 0) {
                    float_b[i - 1] = Number(float_b[i - 1] || 0) - 1;
                }
            }
            final_float.unshift(f);
        }
        // 如果小数的最高位 >= 10， 整数最低位进1
        if (final_float[0] >= 10) {
            if (a_times > 0) {
                int_a[0] = Number(int_a[0] || 0) + 1;
            } else if (b_times > 0) {
                int_b[0] = Number(int_b[0] || 0) + 1;
            }
            final_float[0] -= 10;
        } else if (final_float[0] < 0) {
            if (a_times > 0) {
                int_a[0] = Number(int_a[0] || 0) - 1;
            } else if (b_times > 0) {
                int_b[0] = Number(int_b[0] || 0) - 1;
            }
            final_float[0] += 10;
        }
        // 再算整数
        for (let i = 0; i < int_l; i++) {
            let f = a_times * Number(int_a[i] || 0) + b_times * Number(int_b[i] || 0);
            if (f >= 10 && i !== int_l - 1) {
                // 够10进1
                f -= 10;
                int_a[i + 1] = Number(int_a[i + 1] || 0) + 1;
            } else if (f < 0 && i !== int_l - 1) {
                // 不够0减1
                f += 10;
                // 大数 - 1
                if (a_times > 0) {
                    int_a[i + 1] = Number(int_a[i + 1] || 0) - 1;
                } else if (b_times > 0) {
                    int_b[i + 1] = Number(int_b[i + 1] || 0) - 1;
                }
            }
            final_int.unshift(f);
        }
        while (final_int[0] == '0') {
            final_int.shift();
        }
        while (final_float[final_float.length - 1] == '0') {
            final_float.pop();
        }
        let res = (flag === 1 ? '' : '-') + (final_int.join('') || '0');
        if (final_float.length) {
            res += '.' + final_float.join('');
        }
        return res;
    }, '0');
    return toThousandth(total);
}

function toThousandth(intPart) {
    intPart = intPart.split('').reverse();
    let pointIndex = intPart.indexOf('.') > -1 ? intPart.indexOf('.') + 1 : 0;
    for (let i = pointIndex; i < intPart.length; i++) {
        if ((i - pointIndex) % 4 === 3 && intPart[i] !== '-') {
            intPart.splice(i, 0, ',');
        }
    }
    return intPart.reverse().join('');
}
