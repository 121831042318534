/*
 * @Author: liyxt
 * @Date: 2020-02-10 14:40:37
 * @LastEditors  : liyxt
 * @LastEditTime : 2020-02-18 14:28:47
 * @Description: file content
 */
import React, { Component } from 'react';
import OriginLoading from 'bee-loading';
import classNames from 'classnames';

require('bee-loading/build/Loading.css');
require('./loading.less');

class Loading extends Component {
	render() {
		let { wrapperClassName } = this.props;
		return <OriginLoading {...this.props} wrapperClassName={classNames(wrapperClassName, 'nc-loading')} />;
	}
}

export default Loading;
