/*
 * @Author: liyxt
 * @Date: 2020-05-29 09:53:24
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-02 14:34:27
 * @Description: file content
 */

export function getLangCode() {
    return localStorage.getItem('langCode') || 'simpchn';
}

export let localeLang = getLangCode();
