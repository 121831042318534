/**备注：--liuxis
 * 1903版取消title参数，直接显示内容，为了避免业务组大规模改动的不稳定因素，把原先的title迁移到content部分,并且取消默认标题title的值
 * 1903弱提示:显示5秒，从显示开始的第4秒开始，2秒淡出淡出
 * 普通提示：帮助(蓝)、预警(黄)、成功(绿)提示3000ms后自动隐藏，错误(红)需要手动隐藏；错误(红)提示5000ms后向右滑出，并且最小化；
 */

//const dangerDuration = 5; //错误(红)提示5000ms后向右滑出，并且最小化
let dangerDuration = 30; //孟伟强、杨立志协商后改成30s  2004发版之前需要改回3s

import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { getMultiLang } from '../getMultiLang';
import { isFunction } from '../utils';
import { getSysFieldid } from '../getSysFieldid';
import { getGlobalStorage } from '../storage';

require('./ToastModal.less');

export default class ToastModal extends Component {
    static defaultProps = {
        duration: 2.8, //经产品经理(李聪慧)和刘源确认:color为warning/info时，允许业务自定义气泡消失时间
        color: 'success',
        groupOperation: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            whetherShowGroupMsg: false,
            isSlideOut: false, //要移除的时候的标识，添加类去加动画向右滑出
            isMinimize: false, //最小化的标识，5秒后宽度向右缩小最小化,
            json: {}, //多语
        };
        dangerDuration = props.miniTime || 6 //danger类型6s消失
        this.closeTimer = null;
        this.changeSurfaceTimer = null;
        this.slideOutTimer = null;
        this.duration = dangerDuration;
        this.minimizeTimer = null;//最小化倒计时
        this.toMinimizeTimer = null;
        this.toMaximizeTimer = null;
        this.durationTimer = null;//让this.duration保持为5s
        this.ncc_automated_switch = getGlobalStorage('localStorage', '_ncc_automated_');
    }

    componentWillMount() {
        let { color, showGroupOperationMsg } = this.props;
        if (color === 'danger' || showGroupOperationMsg) {
            //只有danger在展示群组操作信息时时默认展开的
            //加了个参数，可以让业务配置showgroupOperationMsg让非danger也展开显示
            this.setState({ whetherShowGroupMsg: true });
        }
        //请求多语
        let callback = (json, bool) => {
            if (bool) {
                this.setState({ json }, () => {
                    //  在这里进行之前组件willMount的操作，比如加载缓存之类的
                });
            }
        };
        getMultiLang({ moduleId: 'container_toast', callback });
    }

    componentDidMount() {
        let {
            color,
            duration,
            mark,
            light = false,
        } = this.props;
        if (light) {
            this.mark = mark;
            window.toastModalLt.push(this);
        } else {
            this.mark = mark;
            window.toastModal.push(this);
        }
        this.showTimer = setTimeout(() => {
            this.resetToastPosition();
        });

        if (light) { //弱提示
            let defaultDangerDuration = 4.8;//移除的时候加类，做右侧滑出效果的延迟0.3秒
            if (this.ncc_automated_switch !== '1' || color !== 'danger') {
                this.closeTimer = setTimeout(() => {
                    this.closeToast();
                }, defaultDangerDuration * 1000);
            }
        } else {
            if (color !== 'danger') {
                let defaultOtherDuration = 2.8;//移除的时候加类，做右侧滑出效果的延迟0.3秒
                if (duration && typeof duration === 'number') {
                    defaultOtherDuration = duration;//经产品经理(李聪慧)和刘源确认:color为warning/info时，允许业务自定义气泡消失时间
                }
                if (defaultOtherDuration === 2.8 && color === 'warning') {
                    defaultOtherDuration = 3.8;
                }
                this.closeTimer = setTimeout(() => {
                    this.closeToast();
                }, defaultOtherDuration * 1000);
            } else {
                // 开启自动化测试时，不需要隐藏错误气泡提示
                if (this.ncc_automated_switch !== '1') {
                    this.toMinimize();
                }

            }
        }

        if (mark) {
            this.haveSomeMarkExist(mark);
        }

        // 绑定快捷键事件  bbqin
        document.addEventListener('keyup', this.KeyboardEvent);
        this.toast.addEventListener('mouseenter', this.MouseenterEvent);
        this.toast.addEventListener('mouseleave', this.MouseenterLeave);

    }

    // 绑定快捷键事件  bbqin
    componentWillUnmount() {
        document.removeEventListener('keyup', this.KeyboardEvent);
        this.toast.removeEventListener('mouseleave', this.MouseenterLeave);
        this.closeTimer && clearTimeout(this.closeTimer);
        this.changeSurfaceTimer && clearTimeout(this.changeSurfaceTimer);
        this.slideOutTimer && clearTimeout(this.slideOutTimer);
        this.toMinimizeTimer && clearTimeout(this.toMinimizeTimer);
        this.toMaximizeTimer && clearTimeout(this.toMaximizeTimer);
        this.minimizeTimer && clearInterval(this.minimizeTimer);
        this.durationTimer && clearInterval(this.durationTimer);
        this.closeTimer = null;
        this.changeSurfaceTimer = null;
        this.toMinimizeTimer = null;
        this.toMaximizeTimer = null;
        this.minimizeTimer = null;
        this.durationTimer = null;
    }

    //鼠标移入提示框 或者 鼠标悬停在提示框上，提示框在从最小化变为最大化 或者保持最大化的状态
    MouseenterEvent = () => {
        this.duration = dangerDuration;
        let { isMinimize } = this.state;
        this.durationTimer = setInterval(() => {
            this.duration = dangerDuration;
        }, dangerDuration * 1000);
        //在鼠标移入之后，立刻清除定时事件
        this.closeTimer && clearTimeout(this.closeTimer);

        if (isMinimize) {
            this.setState({ isMinimize: false }, () => {
                this.toMaximizeTimer = setTimeout(() => {
                    this.resetToastPosition();
                }, 100);
            });
        }
    }

    //鼠标从提示框移开 倒计时5s后最小化
    MouseenterLeave = () => {
        let { isMinimize } = this.state;
        let {
            color,
            duration,
            light = false,
        } = this.props;
        this.durationTimer && clearInterval(this.durationTimer);
        if (!isMinimize) { //不是最小化的时候
            if (!this.minimizeTimer) { //没有在最小化倒计时
                this.toMinimize();
            } else { //在最小化倒计时
                this.duration = dangerDuration;
            }
        }
        if (light) { //弱提示
            let defaultDangerDuration = 4.8;//移除的时候加类，做右侧滑出效果的延迟0.3秒
            if (this.ncc_automated_switch !== '1' || color !== 'danger') {
                this.closeTimer = setTimeout(() => {
                    this.closeToast();
                }, defaultDangerDuration * 1000);
            }
        } else {
            if (color !== 'danger') {
                let defaultOtherDuration = 2.8;//移除的时候加类，做右侧滑出效果的延迟0.3秒
                if (duration && typeof duration === 'number') {
                    defaultOtherDuration = duration;//经产品经理(李聪慧)和刘源确认:color为warning/info时，允许业务自定义气泡消失时间
                }
                if (defaultOtherDuration === 2.8 && color === 'warning') {
                    defaultOtherDuration = 3.8;
                }
                this.closeTimer = setTimeout(() => {
                    this.closeToast();
                }, defaultOtherDuration * 1000);
            } //修复NCC-99331
        }
        // 解决鼠标二次移入不展开问题
        // this.toast.removeEventListener('mouseenter', this.MouseenterEvent);
    }

    // 绑定快捷键事件-esc关闭提示框  space最小化danger错误提示  bbqin
    KeyboardEvent = ev => {
        if (ev.keyCode === 27) {
            this.closeToast();
        } else if (ev.keyCode === 32) {
            this.toMinimize(true);
        }
    };

    //计算toast排列的位置 
    resetToastPosition = () => {
        let { light } = this.props;
        let ttttoast,
            top,
            spaceBettween;
        if (light) {
            ttttoast = window.toastModalLt.map(el => {
                if (ReactDOM.findDOMNode(el)) {
                    return ReactDOM.findDOMNode(el).firstElementChild;
                }
            });
            top = 65;
            spaceBettween = 5;
        } else {
            ttttoast = window.toastModal.map(el => {
                if (ReactDOM.findDOMNode(el)) {
                    return ReactDOM.findDOMNode(el).firstElementChild;
                }
            });
            top = 113;
            spaceBettween = 10;
        }
        if (ttttoast) {
            let toastArr = Array.from(ttttoast).reverse();
            let mutativeHArr = [];
            let arr = [];
            toastArr.forEach(el => {
                let mutativeHeight = el.offsetHeight;
                mutativeHArr.push(mutativeHeight);
                let h = mutativeHArr.reduce((a, b) => {
                    return a + b;
                }, 0);
                arr.push(h);
            });
            toastArr.forEach((el, i) => {
                el.style.zIndex = 502 + i;
                if (i === 0) {
                    toastArr[i].style.top = top + 'px';
                    toastArr[i].style.transition = '0.2s';
                } else {
                    toastArr[i].style.top = arr[i - 1] + spaceBettween * i + top + 'px';
                    toastArr[i].style.transition = '0.2s';
                }
            });
        }
    };

    //danger类型的提示最小化
    toMinimize = useKeyboard => {
        if (useKeyboard) { //按空格立刻最小化
            clearInterval(this.minimizeTimer);
            this.minimizeTimer = null;
            this.KeyboardMinimizeTimer = setTimeout(() => {
                this.setState({ isMinimize: true }, () => {
                    this.toMinimizeTimer = setTimeout(() => {
                        this.resetToastPosition();
                    }, 100);
                });
            });
        } else if (this.duration === dangerDuration && this.state.isMinimize === false) { //没有用按空格键时五秒自动最小化
            this.minimizeTimer = setInterval(() => {
                if (this.duration > 0) {
                    this.duration--;
                } else {
                    clearInterval(this.minimizeTimer);
                    this.minimizeTimer = null;
                    this.setState({ isMinimize: true }, () => {
                        this.toMinimizeTimer = setTimeout(() => {
                            this.resetToastPosition();
                        }, 100);
                    });
                }
            }, 1000);
        }
    }

    //删除toast弹框
    closeToast = () => {
        let { light } = this.props;
        let index,
            alltoasts;
        if (light) {
            index = window.toastModalLt.findIndex(el => el === this);
            alltoasts = window.toastModalLt.concat().filter((el, i) => i !== index);
            window.toastModalLt = alltoasts.concat();
        } else {
            index = window.toastModal.findIndex(el => el === this);
            alltoasts = window.toastModal.concat().filter((el, i) => i !== index);
            window.toastModal = alltoasts.concat();
        }
        if (index !== -1) {
            this.setState({ isSlideOut: true }, () => {
                this.slideOutTimer = setTimeout(() => {
                    let element = ReactDOM.findDOMNode(this);
                    ReactDOM.unmountComponentAtNode(element);
                    clearTimeout(this.closeTimer);
                    this.closeTimer = null;
                    let parentElement = element.parentNode;
                    parentElement && parentElement.parentNode && parentElement.parentNode.removeChild(parentElement);
                    this.resetToastPosition();
                }, 200);
            });
        }
    };

    //切换多条内容显示的展开收起
    ToShowGroupOperationMsg = () => {
        let { whetherShowGroupMsg } = this.state;
        this.setState({ whetherShowGroupMsg: !whetherShowGroupMsg });
        this.changeSurfaceTimer = setTimeout(() => {
            this.resetToastPosition();
        }, 100);
    };

    //同样一个toast提示多次调用只显示一个的支持 mark为String类型，是唯一标识
    haveSomeMarkExist = mark => {
        if (mark && window.toastModal.length > 1) {
            let ele = null;
            window.toastModal.forEach((val, i) => {
                window.toastModal.forEach((el, j) => {
                    if (i !== j) {
                        if (val.mark && el.mark && val.mark === el.mark) {
                            if (i > j) { //i最新的  
                                window.toastModal.splice(j, 1);
                                ele = el;
                            } else if (i < j) { //j最新的
                                window.toastModal.splice(i, 1);
                                ele = val;
                            }
                        }
                    }
                });
            });
            if (ele) {
                let element = ReactDOM.findDOMNode(ele);
                let parentElement = element.parentNode;
                ReactDOM.unmountComponentAtNode(parentElement);
                parentElement && parentElement.parentNode && parentElement.parentNode.removeChild(parentElement);

            }
            this.resetToastPosition();
        }
    };

    /**
     * toast的内容部分
     * content：content参数的值  String
     * title：title参数的值  String 
     * light：弱提示的标志 Boolean
     * whetherShowGroupMsg：是否显示群组操作提示信息 Boolean
     * groupOperationMsg：群组操作提示信息 Array
      */
    showContent = (color, content, title, light, whetherShowGroupMsg, groupOperationMsg) => {
        let c,
            currentC,
            sign = '。';
        if (color === 'danger' || color === 'success') {
            sign = '!';
        }

        if (content) {
            c = content;
            //字符串
            if (typeof c === 'string') {
                //c = content.split('\\n').join('<br/>');
                c = c.split('"').join('');
                //c = c.split('\\r').join('<br/>');
                let cRows = c.split(/\\r|\\n/);
                let len = cRows.length;
                if (len > 1) {
                    c = cRows.map((e, index) => {
                        if (index === len - 1) {
                            return e;
                        } else {
                            return <Fragment>{e}<br /></Fragment>;
                        }
                    })
                }
            }
            if (title) {
                //title或者content传入JSX时
                currentC = <Fragment>
                    <span style={{ fontWeight: 'bold' }}> {title} </span> {sign} {c}
                </Fragment>;
            } else {
                currentC = <span>{c}</span>;
            }
        } else {
            if (title) {
                currentC = title;
            }
        }

        if (light) {
            return currentC;
        } else {
            return (
                <div className='contentWrap'>
                    {
                        <div
                            className='toast-content'
                        // dangerouslySetInnerHTML={{ __html: currentC }}
                        >
                            {currentC}
                        </div>
                    }
                    {whetherShowGroupMsg &&
                        groupOperationMsg &&
                        Array.isArray(groupOperationMsg) && (
                            <ul className="groupOperationMsg">
                                {groupOperationMsg.map((el, i) => {
                                    return <li key={i}>{el}</li>;
                                })}
                            </ul>
                        )}
                </div>

            );
        }
    };

    /**
     * 2个文字按钮
     * closeText:关闭消息提示按钮的文字 String
     * onClose：关闭回调 Function
     * okText:另外一个按钮的文字  String
     * onExpand：'展开'/'收起' 按钮的回调 Function
     * TextArr：按钮名字的数组 Array
     */
    expandTBtn = (okText, closeText, onClose, onExpand, TextArr) => {
        let { customBtn } = this.props;
        let { whetherShowGroupMsg } = this.state;
        return (
            <div className="expandTBtn">
                <span
                    className="closePrompt"
                    onClick={() => {
                        this.closeToast();
                        if (onClose) onClose();
                    }}
                >
                    {closeText}
                </span>
                <span
                    className="showResult"
                    onClick={() => {
                        TextArr.length === 3 ? this.ToShowGroupOperationMsg() : () => {
                            this.setState({ isMinimize: true });
                        };
                        if (onExpand) onExpand();
                    }}
                >
                    {okText}
                </span>
                {whetherShowGroupMsg && customBtn ? customBtn : null}
            </div>
        );
    };

    /**
     * 关闭文字按钮
     * light：是否是弱提示 Boolean
     * onClose：关闭回调 Function
     * haveExpandTBtn:.类名  String
     */
    closeIcon = (light, haveExpandTBtn, color, onClose) => {
        let { json } = this.state;
        let {
            groupOperation,
            customBtnBeforeClose,
        } = this.props;
        if (light === false) {
            if (haveExpandTBtn === 'toast-modal-noExpandBtn' && color === 'danger') {
                return (
                    <React.Fragment>
                        <i className="before-close-btn">
                            {groupOperation && customBtnBeforeClose}
                        </i>
                        <i
                            className='close-icon'
                            onClick={() => {
                                this.closeToast();
                                if (onClose) onClose();
                            }}
                        >
                            {json['0008']}
                        </i>
                    </React.Fragment>
                );
            }
        }
    };

    /**
     * toast左边的图标
     * color:提示框的四种类型  String
     */
    toastIcon = color => {
        let iconName = 'icon-wancheng';
        if (color === 'danger') {
            iconName = 'icon-shibai';
        } else if (color === 'warning') {
            iconName = 'icon-zhuyi1';
        } else if (color === 'info') {
            iconName = 'icon-tixing';
        }
        let name = `toast-icon iconfont ${iconName} ${color}`;
        return (
            <i
                className={name}
                onClick={() => {
                    if (color === 'danger' && this.state.isMinimize) {
                        console.log('图标toastIcon');
                        this.setState({ isMinimize: false }, () => {
                            setTimeout(() => {
                                this.resetToastPosition();
                            });
                        });
                    }
                }}
            />
        );
    };

    render() {
        let {
            color,
            content,
            title,
            groupOperationMsg,
            TextArr,
            onExpand,
            onClose,
            mark,
            lightClick,
            className: userClassName,
            light = false, //弱提示
        } = this.props;
        let { whetherShowGroupMsg, isSlideOut, isMinimize, json } = this.state;
        let expandTBtnArg1,
            expandTBtnArg2;
        let haveExpandTBtn = 'toast-modal-noExpandBtn';
        if (TextArr && TextArr.length > 1) {
            if (TextArr.length === 2) {
                expandTBtnArg1 = TextArr[0];
                expandTBtnArg2 = TextArr[1];
                haveExpandTBtn = 'toast-modal-haveExpandBtn';
            } else if (TextArr.length === 3 && groupOperationMsg && groupOperationMsg.length > 0) {
                expandTBtnArg1 = whetherShowGroupMsg ? TextArr[1] : TextArr[0];
                expandTBtnArg2 = TextArr[2];
                haveExpandTBtn = 'toast-modal-haveExpandBtn';
            } else {
                haveExpandTBtn = 'toast-modal-noExpandBtn';
            }
        }
        if (!title && !content) {
            if (color === 'success') {
                title = json['0001'];
            } else if (color === 'info') {
                title = json['0002'];
            } else if (color === 'warning') {
                title = json['0003'];
            } else if (color === 'danger') {
                title = json['0004'];
            }
        }

        let className;
        if (light) {
            let toastCon = 'toast-modal-light';
            className = `toast-modal ${toastCon} ${color}`;
        } else {
            className = `toast-modal slideIn ${isMinimize ? 'minimize' : ''} ${color} ${haveExpandTBtn} ${userClassName}`;
        }
        return (
            <div
                fieldid={getSysFieldid('tips-window')}
                className={`toast-mask-modal demo--demo ${light ? 'toast-mask-modal-light' : 'toast-mask-modal-normal'} ${isSlideOut ? 'slideOut' : ''}`}
                data-mark={mark}
            >
                <div
                    className={className}
                    ref={dom => {
                        this.toast = dom;
                    }}
                    onClick={() => {
                        if (light && isFunction(lightClick)) lightClick();
                    }}
                >
                    {this.toastIcon(color)}
                    {this.showContent(color, content, title, light, whetherShowGroupMsg, groupOperationMsg)}
                    {TextArr &&
                        TextArr.length > 1 &&
                        (expandTBtnArg1 && expandTBtnArg2) ? this.expandTBtn(expandTBtnArg1, expandTBtnArg2, onClose, onExpand, TextArr) : this.closeIcon(light, haveExpandTBtn, color, onClose)}
                </div>
            </div>
        );
    }
}
