(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@platform/api/index"), require("react-dom"), require("axios"), require("react-router-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@platform/api/index", "react-dom", "axios", "react-router-dom"], factory);
	else if(typeof exports === 'object')
		exports["@platform/api/index"] = factory(require("react"), require("@platform/api/index"), require("react-dom"), require("axios"), require("react-router-dom"));
	else
		root["@platform/api/index"] = factory(root["React"], root["@platform/api/index"], root["ReactDOM"], root["axios"], root["ReactRouterDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__44__, __WEBPACK_EXTERNAL_MODULE__377__) {
return 