
import ViewModel from './viewmodel'
import { getRandom } from './utils';

const maxCacheLen = 500;

// 列表缓存 500 条
// 卡片也缓存 500 条
// 列表和卡片缓存数据跟用户操作有关系
// 列表下有顺序
// 卡片缓存数据没有顺序
// cardCachePks: 记录卡片缓存的pk;

//获取删除后下一条列表当前页页面数据的id
function getNextId(pkvalue, dataSource, isUseDel=true) {
    if (pkvalue && dataSource) {
        let listData = ViewModel.getData(dataSource);
        let currentpks = [];
        if (listData && listData.simpleTable) {
            currentpks = listData.simpleTable.currentpks;
        }
        if (currentpks && currentpks.length > 1) {
            let index = currentpks.indexOf(pkvalue);
            if (index == currentpks.length - 1) { 
                if(!isUseDel){ //非删除场景 是最后一条 返回null
                    return null;
                }
                //删除场景:是最后一条,返回上一条
                listData.currentId = currentpks[currentpks.length - 2];
                ViewModel.setData(dataSource, listData);
                return currentpks[currentpks.length - 2];
            } else { //不是最后一条,返回下一条
                listData.currentId = currentpks[index + 1];
                ViewModel.setData(dataSource, listData);
                return currentpks[index + 1];
            }
            
        } else {
            if (listData) {
                listData.currentId = null;
            }
            return null;
        }
    }
}

// 根据pk, 差异化更新 原列表、卡片缓存的表头数据
function updateHeadCache(dataSource, pkvalue, headAreacode, updateValues) {
    let mcData = ViewModel.getData(dataSource);
    if (!mcData) return;

    let simpleTableValues = null;
    //根据pk 找到列表对应数据
    if (mcData.simpleTable && mcData.simpleTable.currentpks) {
        //更新列表当前页数据
        let index = mcData.simpleTable.currentpks.indexOf(pkvalue);
        if (index !== -1) {
            simpleTableValues = mcData.simpleTable.rows[index].values;
        }
    }
    if (mcData.card && mcData.card[pkvalue]) {
        let head = mcData.card[pkvalue].head;
        let headValues = head && head[headAreacode] && head[headAreacode].rows[0].values;
        headValues && Object.keys(updateValues).forEach((pop, index) => {
            //更新卡片表头数据
            if (headValues[pop]) {
                headValues[pop].value = updateValues[pop];
            }

            //更新对应的列表当前页数据
            if (simpleTableValues && simpleTableValues[pop]) {
                simpleTableValues[pop].value = updateValues[pop];
            }
        })
        ViewModel.setData(dataSource, mcData);
    }
}

/**
 * 根据pk, 差异化更新 原列表、卡片缓存的表头数据
 * @param {*命名空间} dataSource 
 * @param {*主键名} pkname 
 * @param {*表头区域编码} headAreacode 
 * @param {*更新数据数组} updateRows 
 */
function newUpdateHeadCache(dataSource, pkname, headAreacode, updateRows) {
    let mcData = ViewModel.getData(dataSource);
    if (!mcData) return;
    //逐条更新缓存的卡片表头、表格当前页数据
    updateRows.forEach((updateValues, index) => {
        let pkvalue = updateValues[pkname];
        let simpleTableValues = null;
        //根据pk 找到列表对应数据
        if (mcData.simpleTable && mcData.simpleTable.currentpks) {
            //更新列表当前页数据
            let index = mcData.simpleTable.currentpks.indexOf(pkvalue);
            if (index !== -1) {
                simpleTableValues = mcData.simpleTable.rows[index].values;
            }
        }
        if (mcData.card && mcData.card[pkvalue]) {
            let head = mcData.card[pkvalue].head;
            let headValues = head && head[headAreacode] && head[headAreacode].rows[0].values;

            Object.keys(updateValues).forEach((pop, index) => {
                //更新卡片表头数据
                if (headValues[pop]) {
                    headValues[pop].value = updateValues[pop];
                }
                //更新对应的列表当前页数据
                if (simpleTableValues && simpleTableValues[pop]) {
                    simpleTableValues[pop].value = updateValues[pop];
                }
            })
			
        }
    })
    ViewModel.setData(dataSource, mcData);
}

// 替换缓存中原有 pk 数据； 变更场景下 原单据的 pk 和 数据 都改了
function updatePkCache(dataSource, newPkvalue, oldPkvalue, headAreacode, newData) {
    let mcData = ViewModel.getData(dataSource);
    if (!mcData) return;

    //替换原卡片数据
    if (mcData.card && mcData.card[oldPkvalue]) {
        mcData.card[newPkvalue] = newData;
        delete mcData.card[oldPkvalue];

        let index = mcData.cardCachePks.indexOf(oldPkvalue);
        if (index !== -1) {
            mcData.cardCachePks[index] = newPkvalue;
        }
        //更新当前pk
        mcData.currentId = newPkvalue;
    }

    //替换原列表数据
    if (mcData.simpleTable && mcData.simpleTable.currentpks) {
        //更新列表当前页数据
        let index = mcData.simpleTable.currentpks.indexOf(oldPkvalue);
        if (index !== -1) {
            mcData.simpleTable.currentpks[index] = newPkvalue;
            if (mcData.simpleTable.rows[index]) {
                mcData.simpleTable.rows[index].values = newData.head[headAreacode].rows[0].values;
            }
        }
        //更新列表allpk数据
        let indexAll = mcData.simpleTable.allpks.indexOf(oldPkvalue);
        if (indexAll !== -1) mcData.simpleTable.allpks[indexAll] = newPkvalue;
    }
    ViewModel.setData(dataSource, mcData);
}

/**
 * 查询或修改保存--------卡片更新列表
 * headAreacode: 卡片下 表头的区域编码
 */
function updateCache(pkname, pkvalue, newData, headAreacode, dataSource, headData) {

    // pkname  pkvalue 是数组
    // 根据多个字段的值找到列表的数据
    //判断是修改还是新增
    if (dataSource) {
        if (newData) {
            newData = JSON.parse(JSON.stringify(newData));
        }

        if (headData) {
            headData = JSON.parse(JSON.stringify(headData));
        }

        let tableData = {};
        if (headData) {
            tableData = headData;
        } else if (newData && newData.head && newData.head[headAreacode] && newData.head[headAreacode].rows) {
            tableData = newData.head[headAreacode].rows[0].values;
        }

        let mcData = ViewModel.getData(dataSource);
        if (mcData) {

            if (mcData.card) {
                if (!mcData.card[pkvalue]) { //表示新增数据
                    mcData.cardCachePks.push(pkvalue);
                    //判断缓存中数据是否达到最大缓存条数
                    if (mcData.cardCachePks.length === maxCacheLen) {
                        //若是，将第一条卡片缓存删除
                        let firstPkvalue = mcData.cardCachePks[0];
                        if (firstPkvalue && mcData.card[firstPkvalue]) delete mcData.card[firstPkvalue];
                        if (firstPkvalue) mcData.cardCachePks.shift();
                    }
                }
                //新增或修改一条缓存
                mcData.card[pkvalue] = newData;
            } else {
                //创建卡片缓存
                mcData.cardCachePks = [pkvalue];
                mcData.card = { [pkvalue]: newData }
            }
            mcData.currentId = pkvalue;

            if (mcData.simpleTable) {
                let simpleTable = mcData.simpleTable;
                if (simpleTable.rows) {
                    let flag = false;
                    if (Array.isArray(simpleTable.rows) && simpleTable.rows.length) {
                        if (Array.isArray(pkname) && Array.isArray(pkvalue) && pkname.length == 2 && pkvalue.length == 2) {
                            simpleTable.rows = simpleTable.rows.map(item => {
                                if (item.values &&
									item.values[pkname[0]] &&
									item.values[pkname[0]].value === pkvalue[0] &&
									item.values[pkname[1]] &&
									item.values[pkname[1]].value === pkvalue[1]
                                ) {
                                    flag = true;
                                    //已含有该数据，对数据更新
                                    if (headData) {
                                        for (let pop in headData) {
                                            //if (item.values[pop]) {
                                            item.values[pop] = headData[pop];
                                            //}
                                        }
                                    } else {
                                        if (newData && newData.head && newData.head[headAreacode] && newData.head[headAreacode].rows) {
                                            for (let pop in newData.head[headAreacode].rows[0].values) {
                                                //if (item.values[pop]) {
                                                item.values[pop] = newData.head[headAreacode].rows[0].values[pop];
                                                //}
                                            }
                                        }
                                    }
                                }
                                return item;
                            })
                        } else {
                            simpleTable.rows = simpleTable.rows.map(item => {
                                if (item.values && item.values[pkname] && item.values[pkname].value === pkvalue) {
                                    flag = true;
                                    //已含有该数据，对数据更新
                                    if (headData) {
                                        for (let pop in headData) {
                                            //if (item.values[pop]) {
                                            item.values[pop] = headData[pop];
                                            //}
                                        }
                                    } else {
                                        if (newData && newData.head && newData.head[headAreacode] && newData.head[headAreacode].rows) {
                                            for (let pop in newData.head[headAreacode].rows[0].values) {
                                                //if (item.values[pop]) {
                                                item.values[pop] = newData.head[headAreacode].rows[0].values[pop];
                                                //}
                                            }
                                        }
                                    }
                                }
                                return item;
                            })
                        }

                        if (!flag) { //表格没有该id对应的值,则新增数据

                            //判断该id是否是当前页的pk,如是则往列表中加数据,否则，则不处理列表
                            if (simpleTable.currentpks.includes(pkvalue) && newData && newData.head && newData.head[headAreacode] && newData.head[headAreacode].rows) {
                                simpleTable.rows.push({
                                    rowId: getRandom(),
                                    values: newData.head[headAreacode].rows[0].values,
                                });
                            }
                        }

                    } else if (Array.isArray(simpleTable.rows) && simpleTable.rows.length === 0) {
                        simpleTable.rows = [
                            {
                                rowId: getRandom(),
                                values: tableData,
                            },
                        ];
                        simpleTable.currentpks = [pkvalue];
                        simpleTable.allpks = [pkvalue];

                    }
                   
                } else {
                    simpleTable = {
                        rows: [
                            {
                                rowId: getRandom(),
                                values: tableData,
                            },
                        ],
                        currentpks: [pkvalue],
                        allpks: [pkvalue],
                        pageInfo: {
                            pageSize: 10,
                            pageIndex: 1,
                        },
                    }
                }
                //更新列表数据
                mcData.simpleTable = simpleTable;
            } else {
                mcData.simpleTable = {
                    rows: [
                        {
                            rowId: getRandom(),
                            values: tableData,
                        },
                    ],
                    currentpks: [pkvalue],
                    allpks: [pkvalue],
                    pageInfo: {
                        pageSize: 10,
                        pageIndex: 1,
                    },
                }
            }

        } else {
            //创建一条缓存数据
            mcData = {
                card: { [pkvalue]: newData },
                cardCachePks: [pkvalue],
                currentId: pkvalue,
                simpleTable: {
                    rows: [
                        {
                            rowId: getRandom(),
                            values: tableData,
                        },
                    ],
                    currentpks: [pkvalue],
                    allpks: [pkvalue],
                    pageInfo: {
                        pageSize: 10,
                        pageIndex: 1,
                    },
                },
            }

        }

        ViewModel.setData(dataSource, mcData);

    }
}

/*
 * 新增保存 --------卡片更新列表
 */
function addCache(pkvalue, newData, headAreacode, dataSource, headData) {
    //新增
    if (dataSource) {
        if (newData) {
            newData = JSON.parse(JSON.stringify(newData));
        }

        //业务传了 headData 
        if (headData) {
            headData = JSON.parse(JSON.stringify(headData));
        }

        let mcData = ViewModel.getData(dataSource);
        // 有缓存
        if (mcData) {
            // 有卡片缓存
            if (mcData.card) {
                if (!mcData.card[pkvalue]) { //表示新增数据
                    mcData.cardCachePks.push(pkvalue);
                    //判断缓存中数据是否达到最大缓存条数
                    if (mcData.cardCachePks.length === maxCacheLen) {
                        //若是，将第一条卡片缓存删除
                        let firstPkvalue = mcData.cardCachePks[0];
                        if (firstPkvalue && mcData.card[firstPkvalue]) delete mcData.card[firstPkvalue];
                        if (firstPkvalue) mcData.cardCachePks.shift();
                    }
                }
                mcData.card[pkvalue] = newData;
                // 无卡片缓存
            } else {
                mcData.card = { [pkvalue]: newData };
                mcData.cardCachePks = [pkvalue];
            }
            mcData.currentId = pkvalue;

            if (mcData.simpleTable) {
                let simpleTable = mcData.simpleTable
                if (simpleTable && simpleTable.rows) { //表格中已有数据
                    let len = simpleTable.rows.length;

                    //将数据放入列表最后一条
                    if (headData) {
                        simpleTable.rows.push({
                            rowId: getRandom(),
                            values: headData,
                        });
                    } else if (newData && newData.head && newData.head[headAreacode] && newData.head[headAreacode].rows) {
                        simpleTable.rows.push({
                            rowId: getRandom(),
                            values: newData.head[headAreacode].rows[0].values,
                        });
                    }

                    //将当前新增的 id 插入到allpks中,插入到当前页最后一条
                    let { pageIndex, pageSize } = simpleTable.pageInfo;
                    let index = ((Number(pageIndex) - 1) * Number(pageSize)) + Number(len);

                    if (Array.isArray(simpleTable.allpks)) {
                        simpleTable.allpks.splice(index, 0, pkvalue);
                    }

                    //将当前新增id插入到currentpks中
                    if (Array.isArray(simpleTable.currentpks)) {
                        simpleTable.currentpks.push(pkvalue);
                    }

                    //判断列表缓存数据是否到最大限制
                    if (simpleTable.currentpks.length === maxCacheLen) {
                        //删除列表中第一条
                        simpleTable.rows.shift();
                        simpleTable.currentpks.shift();
                    }

                    //更新列表数据
                    mcData.simpleTable = simpleTable;
                }
            }
            // 无缓存
        } else {
            //将数据插入列表
            let tableData = {};
            if (headData) {
                tableData = headData;
            } else if (newData && newData.head && newData.head[headAreacode] && newData.head[headAreacode].rows) {
                tableData = newData.head[headAreacode].rows[0].values;
            }
            mcData = {
                card: { [pkvalue]: newData },
                cardCachePks: [pkvalue],
                currentId: pkvalue,
                simpleTable: {
                    rows: [
                        {
                            rowId: getRandom(),
                            values: tableData,
                        },
                    ],
                    currentpks: [pkvalue],
                    allpks: [pkvalue],
                    pageInfo: {
                        pageSize: 10,
                        pageIndex: 1,
                    },
                },
            }

        }
        ViewModel.setData(dataSource, mcData);
    }
}

/**
 * 判断是否有改卡片数据的缓存
 */
function getCacheById(pkvalue, dataSource) {
    if (dataSource) {
        let mcData = ViewModel.getData(dataSource);
        if (mcData && mcData.card && mcData.card[pkvalue]) {
            mcData.currentId = pkvalue;
            ViewModel.setData(dataSource, mcData);
            return JSON.parse(JSON.stringify(mcData.card[pkvalue]));
        }
    }
    return null
}

/**
 * 删除数据--------卡片更新列表
 */
function deleteCacheById(pkname, pkvalue, dataSource) {
    if (dataSource) {
        let mcData = ViewModel.getData(dataSource);

        //删除该条卡片数据
        if (mcData) {
            if (mcData.card) {
                let card = mcData.card;
                if (card && card[pkvalue]) {
                    delete card[pkvalue];
                    //删除cardCachePks里对应的id 
                    mcData.cardCachePks = mcData.cardCachePks.filter(item => {
                        return item != pkvalue;
                    })
                }
                mcData.card = card;
            }

            //删除该条列表数据
            if (mcData.simpleTable) {
                let simpleTable = mcData.simpleTable;
                if (simpleTable) {
                    //删除对应数据
                    if (Array.isArray(simpleTable.rows) && simpleTable.rows.length) {
                        simpleTable.rows = simpleTable.rows.filter(item => {
                            return item.values && item.values[pkname] && item.values[pkname].value != pkvalue;
                        })
                    }
                    //删除allpks里对应的id 
                    simpleTable.allpks = simpleTable.allpks.filter(item => {
                        return item != pkvalue;
                    })

                    //若该pk在currentpks中，删除掉
                    simpleTable.currentpks = simpleTable.currentpks.filter(item => {
                        return item != pkvalue;
                    })

                    //更新列表数据
                    mcData.simpleTable = simpleTable;
                }
            }

            ViewModel.setData(dataSource, mcData);
        }
    }

}

function getCurrentLastId(dataSource) {
    if (dataSource) {
        let mcData = ViewModel.getData(dataSource);
        if (mcData && mcData.simpleTable) {
            let currentpks = mcData.simpleTable.currentpks;
            if (Array.isArray(currentpks) && currentpks.length) {
                mcData.currentId = currentpks[currentpks.length - 1];
                ViewModel.setData(dataSource, mcData);
                return currentpks[currentpks.length - 1];
            }
        }
    }
    return null;
}

//获取当前id
function getCurrentId(dataSource) {
    if (!dataSource) return;
    let mcData = ViewModel.getData(dataSource);
    if (mcData && mcData.currentId) {
        return mcData.currentId
    }
    return null
}

/**
 * 业务组往缓存中设置自定义数据
 */
function setDefData(key, dataSource, data) {
    ViewModel.setComponentState(key, dataSource, data);
}

/**
 * 业务组在缓存中获取自定义数据
 */
function getDefData(key, dataSource) {
    return ViewModel.getComponentState(key, dataSource)
}

export {
    getNextId,
    updateCache,
    addCache,
    getCacheById,
    deleteCacheById,
    getCurrentLastId,
    getCurrentId,
    setDefData,
    getDefData,
    updatePkCache,
    updateHeadCache,
    newUpdateHeadCache,
};