/*
 * @Author: liyxt
 * @Date: 2020-04-07 11:41:26
 * @LastEditors: liyxt
 * @LastEditTime: 2020-06-01 14:32:24
 * @Description: file content
 */ 
/**
* 为全局添加业务信息
* 如： 业务日期 业务集团信息 用户id
* businessDate 业务日期
* userId 用户id
* groupId 集团id
* @param {Object} config 
*/
export function getBusinessInfo(config) {
    if (window.top && typeof window.top.GETBUSINESSINFO === 'function') {
        return window.top.GETBUSINESSINFO(config);
    }
    
}