/*
 * @Author: summer
 
 * @Date: 2019-08-12 09:48:47
 * @LastEditTime: 2021-02-26 13:42:57
 * @LastEditors: bbq
 */
import axios from 'axios';
import ReactDOM from 'react-dom';
import Loading from '../base/Loading';
import { toast } from './toast';
import Gzip from './gzip';
import { getBusinessInfo } from './getBusinessInfo';
import { getParamByLocation } from './utils';
import { setCookie, getCookie } from './cookie';
import { setGlobalStorage, getGlobalStorage } from './storage';
import handelExitPage from './exit.js';
import Cipher, { opaqueDecrypt } from './cipher';
import  { syncAjax } from './ajaxsync';
import * as broadcastChannel from './broadcastChannel';
import { getAppReqParam } from './appUrlUtils';
import forwardUrl from './forwardUrl';
import { localeLang } from './currentLocale';

const serviceTip = localeLang === 'simpchn' ? '服务不可用' : 'Service Unavailable';
const unexpectedTip = localeLang === 'simpchn' ? '数据不能解析（解压或解密）' : 'Unexpected Compressed encrypted Data';

const switchURL = {
    '/nccloud/platform/aes/switch.do': true,
    '/nccloud/platform/mark/switch.do': true,
    '/nccloud/platform/gzip/switch.do': true,
};

const errorMessageCatchHandle = function (props) {
    let message = props.message;
    if (message.indexOf('Unexpected') != -1 && message.indexOf('token') != -1 && message.indexOf('JSON') != -1) {
        return unexpectedTip;
    }
    if (message.indexOf('Malformed') != -1 && message.indexOf('UTF-8') != -1 && message.indexOf('data') != -1) {
        return unexpectedTip;
    }
    return props.message;
};

/**
 * 目前主要处理 500
 * @param {*} response 
 * @param {*} tipCallBack 
 * @param {*} url 
 */
const errorStatusCatchHandle = function (response = {}, tipCallBack, url, headers = {}) {
    let data = { type: 'danger', message: serviceTip },
        status = response.status || (response.response ? response.response.status : 200);
    // if (Object.prototype.toString.call(response) === '[object String]' && response.indexOf('<html>') !== -1) {
    //     status = response.indexOf('502') !== -1 ? 502 : '';
    //     status = response.indexOf('503') !== -1 ? 503 : status;
    //     status = response.indexOf('500') !== -1 ? 500 : status;
    // }
    if (status === 500 || status === 501 || status === 503 || status === 502) {
        data.message = `"${headers['nccloud-service'] || ''}" ${serviceTip}; url:${url},status: ${status}`;
        tipCallBack && tipCallBack(data, url);
        return null;
    }
    if (status === 504) {
        data.message = `"${headers['nccloud-service'] || ''}" ${localeLang === 'simpchn' ? '网关超时' : 'Gateway Timeout'}; url:${url},status: ${status}`;
        tipCallBack && tipCallBack(data, url);
        return null;
    }
    return response;
};

// 缓存一些常量用来做多页签（tab）通信
const sscopenkey = getParamByLocation(window.location.hash, 'sscopenkey'); // 共享服务可能不再使用
const urlDataC = getParamByLocation(window.location.hash, 'c') || getAppReqParam().appcode;
const urlDataP = getParamByLocation(window.location.hash, 'p') || getAppReqParam().pagecode;

const DEFAULT_DELAY_TIME = 1000;

//把请求成功的业务数据放在ViewModel的getRenderPageData上，执行获取业务数据之后的二开js
function dealSecDevData(params) {
    let data = ViewModel.dataAlreadyLoaded && ViewModel.dataAlreadyLoaded(params);
    return data || params.res;
}

//把请求成功的业务数据放在ViewModel的getRenderPageData上，执行获取业务数据之后的二开js
function beforeAjaxSend(params) {
    let data = ViewModel.beforeAjaxSend && ViewModel.beforeAjaxSend(params);
    return data || params;
}

// 是否第一次加签
let isNccsignSwitch = false; //原来是 false解决登录报非法攻击
let isNccsign = false;

// 添加一个加签处理方法 -- by bbqin
function addDataSign(data, userCode, {
    allin = false,
    headers = {},
}) {
    // console.log(data, userCode);
    if (
        typeof transSn === 'undefined' ||
        typeof transSign === 'undefined' ||
        // !userCode ||
        (headers && headers['Content-Type'] == 'multipart/form-data') // newData.append(  这种模式 先确定下 需不需要加签操作
    ) {
        return data;
    }
    // 先加签全部数据 或者部分数据

    // 这里会影响原字符串 （编码、位数？）导致后台解析不了字符串
    if (!allin) {
        let strDataSign = data.busiParamJson + '';
        // 加签 -- bbqin
        data.sysParamJson.sn = transSn(userCode);
        data.sysParamJson.signdata = transSign(strDataSign, userCode);
    } else {
        // 加签 -- bbqin
        // data.sysParamJson.sn = transSn(userCode);
        let dataStr = JSON.stringify(data) + '';
        headers['Identifications'] = transSign(dataStr, '');
    }
    // 这里是通过对象方式注入  headers  不符合设计原则 TODO 
    return data;
}

// 此时的cookie是当前界面唯一 如果放到ajax内部  
// 多个请求的情况下 会导致前面的cookie被覆盖  所有请求用的是第二次的cookie  而加密用的cookie是第一次的
let cookiets = (top && top.cookiets) || getCookie('cookiets') || Date.now(); //双cookie验证 liuxis
// 做一次 数据转型
cookiets = isNaN(cookiets) ? cookiets : Number(cookiets);
setCookie('cookiets', cookiets);
top && (top.cookiets = cookiets);

export default function ajax({
    url = '/',
    method = 'post',
    data = {},
    async = true, //默认异步，同步false
    loading = true,
    print = false, // 针对打印接口
    windowContainer = window,
    loadingContainer,
    toastContainer,
    isDecrypt = true, // 一些特殊接口  不需要解密  比如注销
    isUpload = false, //附件专用
    success = function (res) {
        console.log(res);
    },
    // 附件请求base64
    uploadResponse = function (res) {
        console.log(res);
    },
    error = function (res, url) {
        if (!res) return;
        console.error(res.type, res, url);
        let msgContent = JSON.stringify(res.message);
        const colors = ['danger', 'warning', 'info', 'success'];
        let color = colors.indexOf(res.type) != -1 ? res.type : 'danger';
        toast({ color, content: errorMessageCatchHandle({ message: msgContent }), container: toastContainer, mark: msgContent + url });
    },
    getCompleteErrorInfo = function (res) {
        //获取完整堆栈信息
        console.log(res);
    },
    mode = '',
    params = {},
    from = '',
    headers = { 'Content-Type': 'application/json;charset=UTF-8' },
    delayTime,
    sysParameter,
    requestJSON = false,
    sysAppcode, //云原生小部件传appcode,后台做服务分发
}) {
    let stack = [];
    let div;
    // modify by wangyang
    div = windowContainer.document.createElement('div');
    windowContainer.document.body.appendChild(div);
    div.className = 'nc-loading-hidden';

    //console.error(url,"ajax请求开始");
    typeof window.top.startAjax === 'function' && window.top.startAjax(url);

    let gziptools = new Gzip();
    // 解决 接口报错  清理本地的cookie的情况 报错 by bbqin
    //双cookie验证 --liuxis
    cookiets = headers['cookiets'] = String(headers['cookiets'] || ((top && top.cookiets) || getCookie('cookiets') || Date.now()));
    setCookie('cookiets', cookiets);

    function showLoading(container) {
        if (loading) {
            if (stack.length === 0) {
                setTimeout(() => {
                    container.className = 'nc-loading-show';
                }, delayTime || DEFAULT_DELAY_TIME);
                // modify by wangyang
                loadingContainer
                    ? ReactDOM.render(<Loading show={true} container={loadingContainer} />, container)
                    : ReactDOM.render(<Loading show={true} fullScreen container={container} />, container);
            }
            stack.push(url);
        }
    }

    function hideLoading(container) {
        stack.pop();
        if (container && stack.length === 0) {
            container.className = '';
            ReactDOM.unmountComponentAtNode(container);
            // 页面有太多多余的div 做一个div的清除操作 bbqin
            setTimeout(() => {
                // 这里加setTimeout  是为了保证 showLoading中setTimeout和这里的顺序
                container.parentElement && container.parentElement.removeChild(container);
            }, delayTime || DEFAULT_DELAY_TIME);
        }
    }

    //window.actionName

    let { app, appcode, custom } = getAppReqParam();

    appcode = sysAppcode || appcode || '10228888';

    let tabid = top.WebSocketTabid || '';

    let busiaction = app;
    if (window.actionName) {
        busiaction = busiaction + '-' + window.actionName;
    }
    // 此处 yanggqm修改 formData上传。
    let sysParamJson = {},
        newData;
    if (sysParameter) {
        sysParamJson = sysParameter;
    } else {
        sysParamJson = {
            busiaction,
            appcode,
            tabid,
            ts: new Date().getTime(),
            from,
            pagecs: cookiets,
        };
        if (custom) {
            sysParamJson.custom = custom;
        }
    }

    // 添加一个参数给nginx 转发使用
    headers['transcode'] = headers['transcode'] || appcode;
    // 添加一个参数给nginx 转发使用
    let crux = headers['crux'] = String(headers['crux'] || (new Date().getTime()));

    // 用户信息获取 --bbqin
    let userInfos = getBusinessInfo();
    let userCode = userInfos && userInfos.userCode ? userInfos.userCode : null;

    // contentType 
    let headersContentType = 'default';
    if (headers['Content-Type'] == 'multipart/form-data') {
        headersContentType = 'multipart/form-data';
        newData = data;

        let sysParamData = {};

        if (sysParameter) {
            sysParamData = sysParameter;
        } else {
            sysParamData = {
                sys_busiaction: busiaction,
                sys_appcode: appcode,
                sys_tabid: top.WebSocketTabid || '',
                sys_ts: new Date().getTime(),
                sys_custom: custom || '',
            };
        }
        for (let key in sysParamData) {
            newData.append(key, sysParamData[key]);
        }
    } else {
        let strData = typeof data === 'string' ? data : JSON.stringify(data);
        newData = {
            busiParamJson: strData,
            sysParamJson,
        };
    }
    // 修改结束  如要修改，请打招呼。

    //第一次请求缓存是否加密的开关
    getStoreCipherFlag(cookiets);

    /**
     * 开启报错提示
     */
    let flag = true;
    let gzipFlag = false;
    let gzip = getGlobalStorage('localStorage', 'gzip');

    // 加密 bbqin
    let cipherFlag = getGlobalStorage('localStorage', 'rockin');
    let cruxId = String(crux);
    let aeskey = opaqueDecrypt(getGlobalStorage('localStorage', 'cowboy'));
    aeskey = cruxId + aeskey.substring(0, aeskey.length - cruxId.length);

    // 将是否加密缓存到组件单例上
    Cipher.CipherFlag = cipherFlag === 'true';

    let extendParams = beforeAjaxSend({ data: newData, ajaxUrl: url, method, params });
    newData = extendParams.data;

    //异步请求
    function asyncAxios() {
        axios({
            method,
            params,
            headers,
            url,
            data: newData,
            withCredentials: true,
            transformRequest: [
                function (data) {
                    showLoading(div);
                    // 如果 form-data 方式提交  不进行加密压缩
                    if (headersContentType === 'multipart/form-data') {
                        return data;
                    }
                    let gData = '';
                    if (gzipFlag) {
                        gData = gziptools.zip(JSON.stringify(data));
                    } else {
                        gData = JSON.stringify(data);
                    }
                    return Cipher.encrypt(gData, aeskey);
                },
            ],
            transformResponse: [
                function (data, headers) {
                    //处理401
                    hideLoading(div);
                    flag = handelExitPage(headers, flag);
                    if (flag) {
                        try {
                            // 先进行 解密处理 bbqin
                            data = (typeof data === 'string' && !print && isDecrypt) ? Cipher.decrypt(data, aeskey) : data;
                            if (gzipFlag) {
                                let resData = print ? data : gziptools.unzip(data);
                                return resData;
                            } else {
                                return isJSON(data) ? JSON.parse(data) : data;
                            }
                        } catch (e) {
                            return null
                        }
                    } else {
                        return null;
                    }
                },
            ],
        })
            .then(res => {
                if (res) {
                    typeof window.top.endAjax === 'function' && window.top.endAjax(url);
                    if (mode === 'normal') {
                        res = dealSecDevData({
                            res,
                            ajaxUrl: url,
                            ts: new Date().getTime(),
                            c: urlDataC,
                            p: urlDataP,
                        });

                        success(res);
                        // 添加storage事件触发 by bbqin
                        broadcastChannel && broadcastChannel.broadcast(sscopenkey || 'TabBroadcast', {
                            ajaxUrl: url,
                            sscopenkey,
                            ts: new Date().getTime(),
                            c: urlDataC,
                            p: urlDataP,
                        });
                    } else {
                        if (res.data.success || res.success) {
                            res.data = dealSecDevData({
                                res: res.data,
                                ajaxUrl: url,
                                ts: new Date().getTime(),
                                c: urlDataC,
                                p: urlDataP,
                            });

                            success(res.data);
                            // 添加storage事件触发 by bbqin
                            broadcastChannel && broadcastChannel.broadcast(sscopenkey || 'TabBroadcast', {
                                ajaxUrl: url,
                                sscopenkey,
                                ts: new Date().getTime(),
                                c: urlDataC,
                                p: urlDataP,
                            });
                        } else {
                            getCompleteErrorInfo(res);
                            errorStatusCatchHandle(res, error, url, res.headers);
                            //throw new Error(res.data.error.message);
                            // data结构 res = { data: null,error:{type,message} }
                            error(res.data && res.data.error, url);
                        }
                    }
                }
            })
            .catch(res => {
                hideLoading(div);
                if (flag) {
                    let data = res;
                    if (!errorStatusCatchHandle(data, error, url, res.response ? res.response.headers : {})) {
                        return null;
                    }
                    data = typeof data === 'string' && isDecrypt ? Cipher.decrypt(data, aeskey) : data;
                    if (print) { // 这里就是为了捕捉 print情况下压缩了
                        data = gziptools.unzip(data);
                    }
                    // 添加 特殊接口响应状态的判断 如400
                    if (data.response && data.response.data) {
                        data = data.response.data;
                        data = data.error || data;
                    }

                    error(data, url);
                } else {
                    // 添加 特殊接口响应状态的判断 如400
                    // if (res.response && res.response.data) {
                    // 	res = res.response.data;
                    // }
                    // error(res, url);
                }
            });
    }

    //同步请求
    function syncAxios() {
        //同步
        let requestData = JSON.stringify(newData);

        if (gzipFlag) {
            requestData = gziptools.zip(JSON.stringify(newData));
        }
        syncAjax({
            type: method,
            url, //添加自己的接口链接
            data: Cipher.encrypt(requestData, aeskey),
            async,
            headers,
            before() {
                showLoading(div);
            },
            success(str, xhr) {
                hideLoading(div);
                // 附件请求base64
                if (isUpload) {
                    uploadResponse(str);
                } else {
                    try {
                        typeof window.top.endAjax === 'function' && window.top.endAjax(url);
                        let res = null;
                        str = typeof str === 'string' && isDecrypt ? Cipher.decrypt(str, aeskey) : str;
                        if (gzipFlag) {
                            res = gziptools.unzip(str);
                        } else {
                            res = JSON.parse(str);
                        }
                        if (res.success) {
                            res = dealSecDevData({
                                res,
                                ajaxUrl: url,
                                ts: new Date().getTime(),
                                c: urlDataC,
                                p: urlDataP,
                            });

                            success(res);
                            // 添加storage事件触发 by bbqin
                            broadcastChannel && broadcastChannel.broadcast(sscopenkey || 'TabBroadcast', {
                                ajaxUrl: url,
                                sscopenkey,
                                ts: new Date().getTime(),
                                c: urlDataC,
                                p: urlDataP,
                            });

                        } else {
                            getCompleteErrorInfo(res);
                            if (!errorStatusCatchHandle(res, error, url, xhr ? { 'nccloud-service': xhr.getResponseHeader('Nccloud-Service') || '' } : {})) {
                                return null;
                            }
                            //throw new Error(res.error.message);
                            error(res.error, url);
                        }
                    } catch (e) {
                        error(e, url);
                    }
                }
            },
            error(status, statusText) {
                hideLoading(div);
                error(statusText, url);
            }
        });
    }

    //请求本地缓存数据是否加密
    function getStoreCipherFlag(cookiets) {
        // 标识请求过一次，或放入localStorage
        let storeCipher = localStorage.getItem('storeCipher');
        //若取不到，还未发过请求
        if (storeCipher === null) {
            let gziptools = new Gzip();
            let gzipData = {
                sysParamJson: {
                    busiaction: '查询请求流量压缩开关',
                    pagecs: cookiets,
                },
            };
            // add by bbqin  特殊加签
            // 为了避免headers冲突 这里要修正下 
            let gzipHeaders = headers ? { ...headers } : {};
            gzipData = JSON.stringify(addDataSign(gzipData, '', { allin: true, headers: gzipHeaders }));
            syncAjax({
                type: 'post',
                url: '/nccloud/platform/gzip/switch.do',
                data: gzipData,
                headers: gzipHeaders,
                async: false,
                success: res => {
                    let gzipSwitch = false;
                    res = typeof res === 'string' ? JSON.parse(res) : res;
                    if (res && res.success) {
                        let reqData = {
                            sysParamJson: {
                                busiaction: '查询storage加密开关',
                                pagecs: cookiets,
                            },
                        };
                        if (res.data) {
                            gzipSwitch = true;
                        }
                        let reqHeaders = headers ? { ...headers } : {};
                        reqData = JSON.stringify(addDataSign(reqData, '', { allin: true, headers: reqHeaders }));
                        syncAjax({
                            type: 'post',
                            url: '/nccloud/platform/localstorage/switch.do',
                            data: gzipSwitch ? gziptools.zip(reqData) : reqData,
                            async: false,
                            headers: reqHeaders,
                            success: res => {
                                res = gzipSwitch ? gziptools.unzip(res) : res;
                                res = typeof res === 'string' ? JSON.parse(res) : res;
                                if (res && res.success) {
                                    if (res.data) {
                                        localStorage.setItem('storeCipher', 1);
                                    } else {
                                        localStorage.setItem('storeCipher', 0);
                                    }
                                    let gzipFlag = gzipSwitch ? 1 : 0;
                                    setGlobalStorage('localStorage', 'gzip', gzipFlag);
                                }
                            },
                        });
                    }
                },
            });
        }
    }

    //取不到加密开关时，请求aes开关
    function reqAES() {
        if (getGlobalStorage('localStorage', 'rockin') === null) {
            let gzipSwitch = getGlobalStorage('localStorage', 'gzip');
            let reqData = {
                sysParamJson: {
                    busiaction: '查询请求aes加密开关', /* 国际化处理： 查询请求aes加密开关*/
                    pagecs: cookiets,
                },
            };
            // add by bbqin  特殊加签
            // 为了避免headers冲突 这里要修正下 
            let aesHeaders = headers ? { ...headers } : {};
            reqData = addDataSign(reqData, '', { allin: true, headers: aesHeaders });

            reqData = gzipSwitch == '1' ? gziptools.zip(JSON.stringify(reqData)) : JSON.stringify(reqData);
            syncAjax({
                type: 'post',
                url: '/nccloud/platform/aes/switch.do',
                data: reqData,
                async: false,
                headers: aesHeaders,
                success: asd => { // aesSwitchData
                    asd = typeof asd === 'string' ? JSON.parse(asd) : asd;
                    asd = gzipSwitch == '1' ? gziptools.unzip(asd) : JSON.parse(asd);
                    if (asd.data && asd.success) {
                        setGlobalStorage('localStorage', 'rockin', asd.data);
                        Cipher.CipherFlag = (asd.data === 'true' || asd.data === true);
                    }
                },
            });
        }
    }

    if (gzip === null) {
        let gzipData = {
            sysParamJson: {
                busiaction: '查询请求流量压缩开关',
                pagecs: cookiets,
            },
        };
        // add by bbqin  特殊加签
        // 为了避免headers冲突 这里要修正下 
        let gzipHeaders = headers ? { ...headers } : {};
        gzipData = addDataSign(gzipData, '', { allin: true, headers: gzipHeaders });
        axios({
            method: 'post',
            url: '/nccloud/platform/gzip/switch.do',
            data: gzipData,
            headers: gzipHeaders,
            withCredentials: true,
        })
            .then(res => {
                hideLoading(div);

                if (res.data) {
                    if (res.data.success || res.success) {
                        if (res.data.data) {
                            setGlobalStorage('localStorage', 'gzip', 1);
                            gzipFlag = true;
                        } else {
                            setGlobalStorage('localStorage', 'gzip', 0);
                            gzipFlag = false;
                        }

                        // 加签开关获取  -- bbqin
                        let securityData = {
                            sysParamJson: {
                                busiaction: '查询请求安全加签开关',
                                pagecs: cookiets,
                            },
                        };
                        // add by bbqin  特殊加签
                        // 为了避免headers冲突 这里要修正下 
                        let markHeaders = headers ? { ...headers } : {};
                        securityData = JSON.stringify(addDataSign(securityData, '', { allin: true, headers: markHeaders }));
                        // 如果没有标识才去请求标识  
                        !isNccsignSwitch && syncAjax({
                            type: 'post',
                            url: '/nccloud/platform/mark/switch.do',
                            data: gzipFlag ? gziptools.zip(securityData) : securityData,
                            async: false,
                            withCredentials: true,
                            headers: markHeaders,
                            success(text) {
                                let handleData = gzipFlag ? gziptools.unzip(text) : text;
                                handleData = typeof handleData === 'string' ? JSON.parse(handleData) : handleData;
                                if (handleData) {
                                    isNccsignSwitch = true;
                                    if (handleData.success) {
                                        if (handleData.data) {
                                            isNccsign = true;
                                        } else {
                                            isNccsign = false;
                                        }
                                    }
                                }
                            },
                        });

                        // 请求加密开关
                        //登陆页请求不加密
                        //reqAES();

                        // add by bbqin
                        newData = (isNccsign || switchURL[url]) ? addDataSign(newData, userCode, { allin: true, headers }) : newData;

                        //根据前端分流 修改url
                        forwardUrl({
                            url,
                            busiaction,
                            userId: userInfos && userInfos.userId,
                            gzipFlag,
                            pagecs: cookiets,
                            userCode: userInfos && userInfos.userCode,
                            cipherFlag: cipherFlag === "true",
                            aeskey,
                            crux,
                            cookiets,
                        }, newUrl => { //修改url后发请求
                            url = newUrl;

                            //执行ajax请求
                            if (async) {
                                //异步
                                asyncAxios();
                            } else {
                                //同步
                                syncAxios();
                            }

                        });

                    } else {
                        getCompleteErrorInfo(res);
                        if (!errorStatusCatchHandle(res, error, url, res.headers)) {
                            return null;
                        }
                        //throw new Error(res.data.error);
                        error(res.data.error, url);
                    }
                }
            })
            .catch(res => {
                hideLoading(div);
                // 添加 特殊接口响应状态的判断 如400
                if (res.response && res.response.data) {
                    res = res.response.data;
                    res = res.error || res;
                }
                if (!errorStatusCatchHandle(res, error, '/nccloud/platform/gzip/switch.do'), res.response ? res.response.headers : {}) {
                    return null;
                }
                error(res, url);
            });
    } else {
        if (getGlobalStorage('localStorage', 'gzip') == '1') {
            gzipFlag = true;
        } else if (getGlobalStorage('localStorage', 'gzip') == '0') {
            gzipFlag = false;
        }

        // 加签开关获取  -- bbqin
        let securityData = {
            sysParamJson: {
                busiaction: '查询请求安全加签开关',
                pagecs: cookiets,
            },
        };
        // 为了避免headers冲突 这里要修正下 
        let markHeaders = headers ? { ...headers } : {};
        securityData = JSON.stringify(addDataSign(securityData, '', { allin: true, headers: markHeaders }));
        // 如果没有标识才去请求标识  
        !isNccsignSwitch && syncAjax({
            type: 'post',
            url: '/nccloud/platform/mark/switch.do',
            data: gzipFlag ? gziptools.zip(securityData) : securityData,
            async: false,
            withCredentials: true,
            headers: markHeaders,
            success(text) {
                let handleData = gzipFlag ? gziptools.unzip(text) : text;
                handleData = typeof handleData === 'string' ? JSON.parse(handleData) : handleData;
                if (handleData) {
                    isNccsignSwitch = true;
                    if (handleData.success) {
                        if (handleData.data) {
                            isNccsign = true;
                        } else {
                            isNccsign = false;
                        }
                    }
                }
            },
        });

        // 请求加密开关
        //reqAES();

        // add by bbqin
        newData = (isNccsign || switchURL[url]) ? addDataSign(newData, userCode, { allin: true, headers }) : newData;

        //根据前端分流 修改url
        forwardUrl({
            url,
            busiaction,
            userId: userInfos && userInfos.userId,
            gzipFlag,
            pagecs: cookiets,
            userCode: userInfos && userInfos.userCode,
            cipherFlag: cipherFlag === "true",
            aeskey,
            crux,
            cookiets,
        }, newUrl => { //修改url后发请求
            url = newUrl;

            //执行ajax请求
            if (async) {
                //异步
                asyncAxios();
            } else {
                //同步
                syncAxios();
            }
        })
    }
}

export { ajax };

function isJSON(str) {
    if (typeof str == 'string') {
        try {
            var obj = JSON.parse(str);
            if (typeof obj == 'object' && obj) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.log('error：' + str + '!!!' + e);
            return false;
        }
    }
    console.log('It is not a string!');
}

